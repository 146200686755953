$carousel-content-item-width: rem-calc(270) !default;
$carousel-content-small-item-width: rem-calc(168) !default;

@mixin lora-components-carousel-content {
    .c-carousel.m-style-content {
        .c-carousel__inner {
            @include breakpoint(medium down) {
                overflow: visible;
            }
        }

        &:not(.m-vertical),
        &.m-horizontal {
            .c-carousel__item {
                height: auto;
                min-height: 100%;
            }
        }

        .c-carousel__item {
            @include breakpoint(medium down) {
                width: $carousel-content-item-width;
            }
        }
    }

    .c-carousel.m-style-content-small {
        .c-carousel__inner {
            @include breakpoint(medium down) {
                overflow: visible;
            }
        }

        .c-carousel__item {
            @include breakpoint(medium down) {
                width: $carousel-content-small-item-width;
            }
        }
    }
}
