$modal-wrapper-background: color(light) !default;
$modal-wrapper-border: null !default;
$modal-wrapper-width: 100vw !default;
$modal-wrapper-border--medium: null !default;
$modal-wrapper-border--large: null !default;
$modal-wrapper-width--medium: rem-calc(728) !default;
$modal-wrapper-width--large: rem-calc(770) !default;
$modal-wrapper-height--medium: auto !default;
$modal-wrapper-padding: rem-calc(50 15 25) !default;
$modal-wrapper-padding--medium: rem-calc(50 15 25) !default;
$modal-wrapper-padding--large: rem-calc(40 70 70) !default;
$modal-wrapper-center-padding: rem-calc(50 15) !default;
$modal-wrapper-center-padding--large: rem-calc(40 70) !default;
$modal-overlay-background-color: rgba(0, 0, 0, 0.5) !default;
$modal-body-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$modal-body-text-transform: null !default;

// Small and medium width
$modal-small-wrapper--medium: rem-calc(415) !default;
$modal-medium-wrapper--medium: rem-calc(570) !default;
$modal-large-wrapper--medium: rem-calc(970) !default;
$modal-xlarge-wrapper--large: rem-calc(1170) !default;

// Close icon
$modal-close-position: rem-calc(15) !default;
$modal-close-position--medium: rem-calc(16) !default;
$modal-close-icon-size: rem-calc(20) !default;
$modal-close-icon-size--large: null !default;
$modal-close-icon: cross !default;
$modal-close-icon-color: color(primary) !default;
$modal-close-icon-usesvg: true !default;
// Title
$modal-title-color: color(text) !default;
$modal-title-color--large: null !default;
$modal-title-margin: rem-calc(0 0 10) !default;
$modal-title-margin--large: rem-calc(0 0 30) !default;
$modal-title-font: 500 #{rem-calc(18)} / 1.25 $font-primary !default;
$modal-title-font--large: normal #{rem-calc(30)} / 1.25 $font-primary !default;
$modal-title-text-transform: uppercase !default;
$modal-title-text-transform--large: null !default;
$modal-title-text-align: null !default;
$modal-title-text-align--large: null !default;
// Subtitle
$modal-subtitle-color: color(text) !default;
$modal-subtitle-color--large: null !default;
$modal-subtitle-margin: rem-calc(0 0 8) !default;
$modal-subtitle-margin--large: null !default;
$modal-subtitle-font: #{rem-calc(14)} / 1.25 $font-primary !default;
$modal-subtitle-font--large: #{rem-calc(18)} / 1.25 $font-primary !default;
$modal-subtitle-text-transform: none !default;
$modal-subtitle-text-transform--large: null !default;
// Message
$modal-message-font-size: rem-calc(14) !default;
$modal-message-font-weight: null !default;
$modal-message-font: $modal-message-font-weight #{$modal-message-font-size} $font-primary !default;
$modal-message-text-align: center !default;
$modal-message-margin: rem-calc(0 0 20) !default;
$modal-message-color: null !default;
// Text
$modal-text-margin: rem-calc(0 0 25) !default;
$modal-text-font-size: null !default;
$modal-text-line-height: 1.6 !default;
$modal-text-font-weight: $global-weight-normal !default;
$modal-text-text-align: null !default;
$modal-text-color: null !default;
$modal-text-text-transform: null !default;
$modal-text-letter-spacing: null !default;
$modal-text-large-font-size: rem-calc(18) !default;
$modal-text-large-font-weight: $global-weight-normal !default;
$modal-text-large-line-height: $modal-text-line-height !default;
$modal-text-large-font: $modal-text-large-font-weight #{$modal-text-large-font-size} / $modal-text-large-line-height $font-primary !default;
// Header
$modal-header-font: 500 #{rem-calc(30)} / 1.25 $font-primary !default;
$modal-header-font--large: null !default;
$modal-header-color: color(text) !default;
$modal-header-text-transform: uppercase !default;
$modal-header-text-transform--large: null !default;
$modal-header-text-align: null !default;
$modal-header-text-align--large: null !default;
// Section
$modal-section-margin: rem-calc(0 0 25) !default;
$modal-section-secondary-background: transparent !default;
$modal-section-secondary-background--large: color(global-background) !default;
$modal-section-secondary-margin: rem-calc(0 0 15) !default;
$modal-section-secondary-margin--large: rem-calc(0 0 25) !default;
$modal-section-secondary-padding: rem-calc(0) !default;
$modal-section-secondary-padding--large: rem-calc(35 45) !default;
// Actions
$modal-actions-margin: rem-calc(20 0 0 0) !default;
$modal-actions-margin--medium: rem-calc(25 0 0 0) !default;
$modal-actions-button-offset: rem-calc(20) !default;
$modal-actions-button-offset--medium: rem-calc(30) !default;
/// Action sticky
$modal-actions-sticky-padding: rem-calc(10 15) !default;
$modal-actions-sticky-padding--large: null !default;
$modal-actions-sticky-margin: 0 !default;
$modal-actions-sticky-margin--large: null !default;
$modal-actions-sticky-box-shadow: rem-calc(0 -2 4) color(element-background) !default;
$modal-actions-sticky-box-shadow--large: null !default;
$modal-actions-sticky-background: color(light) !default;
$modal-actions-sticky-background--large: null !default;
// Modal with image
$modal-image-width--large: rem-calc(400) !default;
$modal-image-padding: rem-calc(50 16 25) !default;
$modal-image-padding--large: 0 !default;
$modal-image-max-width--large: rem-calc(970) !default;
$modal-image-image-side-margin--large: rem-calc(100) !default;
$modal-image-content-padding: rem-calc(25 0 0) !default;
$modal-image-content-padding--large: rem-calc(50 70) !default;
$modal-image-content-width--large: rem-calc(570) !default;
$modal-image-content-text-align: null !default;
// Modal loading
$modal-wrapper-loading-min-width: 100vw !default;
$modal-wrapper-loading-min-width--medium: rem-calc(570) !default;
$modal-wrapper-loading-height: vh(100) !default;
$modal-wrapper-loading-height-fallback: 100vh !default;
$modal-wrapper-loading-height--medium: vh(50) !default;
$modal-wrapper-loading-height-fallback--medium: 50vh !default;
$modal-wrapper-loading-background-color: color(light) !default;
// Modal with secondary header (separated with border)
/// Title
$modal-header-secondary-title-padding: rem-calc(15 50) !default;
$modal-header-secondary-title-border-bottom: 1px solid color(border) !default;
$modal-header-secondary-title-background: null !default;
$modal-header-secondary-title-color: null !default;
$modal-header-secondary-title-font: null !default;
$modal-header-secondary-title-margin: null !default;
$modal-header-secondary-title-text-align: center !default;
$modal-header-secondary-title-text-transform: null !default;
// Modal slide
/// Close button
$modal-slide-close-padding: rem-calc(20) !default;
$modal-slide-close-padding--large: rem-calc(28 20) !default;
/// Close icon
$modal-slide-left-close-icon: chevron2-left !default;
$modal-slide-right-close-icon: chevron2-right !default;
$modal-slide-close-icon-size: rem-calc(12) !default;
$modal-slide-close-icon-size--large: rem-calc(18) !default;
$modal-slide-close-icon-color: color(primary) !default;
$modal-slide-left-close-icon-usesvg: true !default;

@mixin lora-modal-size-medium {
    .c-modal__wrapper {
        @include breakpoint(medium) {
            width: $modal-medium-wrapper--medium;
        }
    }
}

@mixin lora-modal-size-small {
    .c-modal__wrapper {
        @include breakpoint(medium) {
            width: $modal-small-wrapper--medium;
        }
    }
}

@mixin lora-modal-size-large {
    .c-modal__wrapper {
        @include breakpoint(medium) {
            width: $modal-large-wrapper--medium;
        }
    }
}

@mixin lora-modal-size-xlarge {
    .c-modal__wrapper {
        @include breakpoint(large) {
            width: $modal-xlarge-wrapper--large;
        }
    }
}

@mixin lora-modal-size-fullscreen {
    .c-modal__wrapper {
        width: 100vw;
        height: vh(100);
        padding-left: 0;
        padding-right: 0;
    }

    .c-modal__header,
    .c-modal__body,
    .c-modal__footer {
        @include layout;
    }
}

@mixin lora-modal-borderless {
    .c-modal__wrapper {
        padding: 0;
    }
}

@mixin lora-modal-autosize {
    .c-modal__wrapper {
        width: auto;
    }
}

@mixin lora-modal-image {
    .c-modal__wrapper {
        @include breakpoint(large) {
            max-width: $modal-image-max-width--large;
            flex: 1 1 100%;
        }
    }

    .c-modal__body {
        display: flex;
        flex-flow: column;
        padding: $modal-image-padding;

        @include breakpoint(large) {
            position: relative;
            flex-flow: row;
            padding: $modal-image-padding--large;
        }

        .c-button {
            @include breakpoint(medium down) {
                width: 100%;
            }
        }
    }

    .c-modal__image {
        align-self: center;
        flex-shrink: 0;

        @include breakpoint(large) {
            width: $modal-image-width--large;
            position: relative;
            align-self: auto;
            overflow: hidden;
        }

        img {
            @include breakpoint(large) {
                @include absolute-center;

                min-width: 100%;
                min-height: 100%;
                max-width: none;
                width: auto;
            }
        }
    }

    .c-modal__content {
        flex-grow: 1;
        padding: $modal-image-content-padding;
        text-align: $modal-image-content-text-align;

        @include breakpoint(large) {
            width: $modal-image-content-width--large; // Fix issue with carousel inside modal
            flex-basis: 0;
            padding: $modal-image-content-padding--large;
        }
    }
}

@mixin lora-components-modal {
    .c-modal__body {
        font: $modal-body-font;
        text-transform: $modal-body-text-transform;
    }

    .c-modal__wrapper {
        background: $modal-wrapper-background;
        border: $modal-wrapper-border;
        height: vh(100);
        max-width: 100vw;
        overflow: hidden auto;
        overscroll-behavior: contain; //stylelint-disable-line
        padding: $modal-wrapper-padding;
        pointer-events: all;
        position: relative;
        width: $modal-wrapper-width;

        @include breakpoint(medium) {
            border: $modal-wrapper-border--medium;
            height: $modal-wrapper-height--medium;
            max-height: vh(100);
            padding: $modal-wrapper-padding--medium;
            width: $modal-wrapper-width--medium;
        }

        @include breakpoint(large) {
            border: $modal-wrapper-border--large;
            padding: $modal-wrapper-padding--large;
            width: $modal-wrapper-width--large;
        }
    }

    .c-modal {
        display: none;
        height: vh(100);
        #{$global-left}: 0;
        pointer-events: none;
        position: fixed !important; /* stylelint-disable-line */
        top: 0;
        width: 100vw;
        z-index: z(modal);

        &.m-opened {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }

    .c-modal__close {
        cursor: pointer;
        font-size: 0; /* stylelint-disable-line */
        position: absolute;
        #{$global-right}: get-side($modal-close-position, right);
        top: get-side($modal-close-position, top);
        z-index: z(modal, close);

        @include breakpoint(medium) {
            #{$global-right}: get-side($modal-close-position--medium, right);
            top: get-side($modal-close-position--medium, top);
        }

        &::before {
            @include svg-icon($modal-close-icon, $modal-close-icon-color, 100%, left, no-repeat, $usesvg: $modal-close-icon-usesvg);

            content: '';
            display: block;
            height: $modal-close-icon-size;
            width: $modal-close-icon-size;

            @include breakpoint(large) {
                height: $modal-close-icon-size--large;
                width: $modal-close-icon-size--large;
            }
        }
    }

    .c-modal__title {
        @include text-style(
            $color: (
                small: $modal-title-color,
                large: $modal-title-color--large
            ),
            $font: (
                small: $modal-title-font,
                large: $modal-title-font--large
            ),
            $margin: (
                small: $modal-title-margin,
                large: $modal-title-margin--large
            ),
            $text-align: (
                small: $modal-title-text-align,
                large: $modal-title-text-align--large
            ),
            $text-transform: (
                small: $modal-title-text-transform,
                large: $modal-title-text-transform--large
            )
        );
    }

    .c-modal__subtitle {
        @include text-style(
            $color: (
                small: $modal-subtitle-color,
                large: $modal-subtitle-color--large
            ),
            $font: (
                small: $modal-subtitle-font,
                large: $modal-subtitle-font--large
            ),
            $margin: (
                small: $modal-subtitle-margin,
                large: $modal-subtitle-margin--large
            ),
            $text-transform: (
                small: $modal-subtitle-text-transform,
                large: $modal-subtitle-text-transform--large
            )
        );
    }

    .c-modal__message {
        font: $modal-message-font;
        text-align: $modal-message-text-align;
        margin: $modal-message-margin;
        color: $modal-message-color;
    }

    .c-modal__text {
        margin: $modal-text-margin;
        font-size: $modal-text-font-size;
        line-height: $modal-text-line-height;
        font-weight: $modal-text-font-weight;
        text-align: $modal-text-text-align;
        text-transform: $modal-text-text-transform;
        letter-spacing: $modal-text-letter-spacing;
        color: $modal-text-color;

        &.m-large {
            font: $modal-text-large-font;
        }
    }

    .c-modal__header {
        @include text-style(
            $font: (
                small: $modal-header-font,
                large: $modal-header-font--large
            ),
            $text-align: (
                small: $modal-header-text-align,
                large: $modal-header-text-align--large
            ),
            $text-transform: (
                small: $modal-header-text-transform,
                large: $modal-header-text-transform--large
            ),
            $color: (
                small: $modal-header-color
            )
        );
    }

    .c-modal__section {
        margin: $modal-section-margin;

        &.m-secondary {
            background: $modal-section-secondary-background;
            margin: $modal-section-secondary-margin;
            padding: $modal-section-secondary-padding;

            @include breakpoint(large) {
                background: $modal-section-secondary-background--large;
                margin: $modal-section-secondary-margin--large;
                padding: $modal-section-secondary-padding--large;
            }
        }
    }

    .c-modal__actions {
        margin: $modal-actions-margin;

        @include breakpoint(medium) {
            margin: $modal-actions-margin--medium;
        }

        .c-button {
            transform: translateZ(0); // fix for painting issue in IOS 16.4

            & + .c-button {
                margin-#{$global-left}: $modal-actions-button-offset;

                @include breakpoint(medium) {
                    margin-#{$global-left}: $modal-actions-button-offset--medium;
                }
            }
        }

        &.m-sticky {
            position: fixed;
            bottom: 0;
            #{$global-left}: 0;
            width: 100%;
            padding: $modal-actions-sticky-padding;
            margin: $modal-actions-sticky-margin;
            background: $modal-actions-sticky-background;
            box-shadow: $modal-actions-sticky-box-shadow;
            z-index: z(modal, modalActionsSticky);

            @include breakpoint(large) {
                padding: $modal-actions-sticky-padding--large;
                margin: $modal-actions-sticky-margin--large;
                background: $modal-actions-sticky-background--large;
                box-shadow: $modal-actions-sticky-box-shadow--large;
            }
        }
    }

    // Should be above size modifiers to not overload modal size settings
    .c-modal.m-loading .c-modal__wrapper {
        z-index: z(modal, modalWrapperLoading); // should have a smaller z-index then the loader's overlay
        background: $modal-wrapper-loading-background-color url('#{$global-image-path}/#{$loader-image}') 50% 50% no-repeat;

        @include reduced-motion {
            background: $modal-wrapper-loading-background-color url('#{$global-image-path}/#{$loader-image--reduced-motion}') 50% 50% no-repeat;
        }
    }

    .c-modal.m-loading .c-modal__wrapper,
    .c-modal.m-motion .c-modal__wrapper {
        min-width: $modal-wrapper-loading-min-width;
        min-height: vh(100);
        overflow: hidden;

        @include breakpoint(medium) {
            min-width: $modal-wrapper-loading-min-width--medium;
            min-height: vh(50);
        }
    }

    // Modal Modifiers
    .c-modal.m-xlarge {
        @include lora-modal-size-xlarge;
    }

    .c-modal.m-large {
        @include lora-modal-size-large;
    }

    .c-modal.m-medium {
        @include lora-modal-size-medium;
    }

    .c-modal.m-small {
        @include lora-modal-size-small;
    }

    .c-modal.m-fullscreen {
        @include lora-modal-size-fullscreen;
    }

    .c-modal.m-autosize {
        @include lora-modal-autosize;
    }

    @each $size in $breakpoint-classes {
        .c-modal.m-autosize-#{$size} {
            @include breakpoint ($size) {
                @include lora-modal-autosize;
            }
        }
    }

    .c-modal.m-center .c-modal__wrapper {
        display: flex;
        flex-flow: column;
        text-align: center;
        padding: $modal-wrapper-center-padding;

        @include breakpoint(large) {
            padding: $modal-wrapper-center-padding--large;
        }
    }

    .c-modal.m-center:not(.m-loading) .c-modal__wrapper {
        // centered modal content vertically: auto margin solution to be OK for overflow case
        .c-modal__header {
            margin-top: auto;
        }

        .c-modal__footer {
            margin-bottom: auto;
        }
    }

    .c-modal.m-image {
        @include lora-modal-image;
    }

    .c-modal.m-borderless {
        @include lora-modal-borderless;
    }

    .c-modal.m-header-secondary {
        .c-modal__title {
            padding: $modal-header-secondary-title-padding;
            margin: $modal-header-secondary-title-margin;
            border-bottom: $modal-header-secondary-title-border-bottom;
            background: $modal-header-secondary-title-background;
            color: $modal-header-secondary-title-color;
            font: $modal-header-secondary-title-font;
            text-align: $modal-header-secondary-title-text-align;
            text-transform: $modal-header-secondary-title-text-transform;
        }

        .c-modal__wrapper {
            padding-top: 0;
        }
    }

    .c-modal[class*="h-left-slide"],
    .c-modal[class*="h-right-slide"] {
        .c-modal__close {
            padding: $modal-slide-close-padding;
            top: 0;

            @include breakpoint(large) {
                padding: $modal-slide-close-padding--large;
            }

            &::before {
                @include svg-icon($modal-slide-left-close-icon, $modal-slide-close-icon-color, auto 100%, left, no-repeat, $usesvg: $modal-slide-left-close-icon-usesvg);

                content: '';
                display: block;
                height: $modal-slide-close-icon-size;
                width: $modal-slide-close-icon-size;

                @include breakpoint(large) {
                    height: $modal-slide-close-icon-size--large;
                    width: $modal-slide-close-icon-size--large;
                }
            }
        }
    }

    .c-modal[class*="h-left-slide"] {
        .c-modal__close {
            #{$global-left}: 0;
            #{$global-right}: auto;
        }
    }

    .c-modal[class*="h-right-slide"] {
        .c-modal__close {
            #{$global-right}: 0;
            #{$global-left}: auto;
            transform: rotate(180deg);
        }
    }
}
