
                        $is-app_personalization-enabled: true;
                        // Engraving
$personalization-engraving-message-font: #{rem-calc(12)} / 1.25 "Arial", sans-serif !default;
$personalization-engraving-message-opacity: null !default;
$personalization-engraving-message-text-transform: null !default;
// font-size in "em" is needed to take the font size relative to the parent
$personalization-engraving-message-monogram-letter-font-size: 1.5em !default;
$personalization-engraving-message-monogram-letter-font: $personalization-engraving-message-monogram-letter-font-size / 1.25 "Times New Roman", serif !default;

@mixin lora-components-personalization-engraving-message {
    .c-personalization__engraving-message {
        text-transform: $personalization-engraving-message-text-transform;
        font: $personalization-engraving-message-font;
        opacity: $personalization-engraving-message-opacity;

        span {
            font: $personalization-engraving-message-monogram-letter-font;
        }
    }
}
                    