/** Generate easing effects
 * The default easing ('ease') is set during the initialization
 * https://github.com/michalsnik/aos#1-initialize-aos
 * If you want to override the effect via data attributes you can use commented required easing effects or create a custom easing effect
 * Example: data-aos-easing="ease-in-out"
 */

$aos-easing: (
    // linear: cubic-bezier(0.25, 0.25, 0.75, 0.75),
    ease: cubic-bezier(0.25, 0.1, 0.25, 1),
    // ease-in: cubic-bezier(0.42, 0, 1, 1),
    // ease-out: cubic-bezier(0, 0, 0.58, 1),
    // ease-in-out: cubic-bezier(0.42, 0, 0.58, 1),
    // ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045),
    // ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275),
    // ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55),
    // ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715),
    // ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1),
    // ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95),
    // ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53),
    // ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
    // ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955),
    // ease-in-cubic: cubic-bezier(0.55, 0.085, 0.68, 0.53),
    // ease-out-cubic: cubic-bezier(0.25, 0.46, 0.45, 0.94),
    // ease-in-out-cubic: cubic-bezier(0.455, 0.03, 0.515, 0.955),
    // ease-in-quart: cubic-bezier(0.55, 0.085, 0.68, 0.53),
    // ease-out-quart: cubic-bezier(0.25, 0.46, 0.45, 0.94),
    // ease-in-out-quart: cubic-bezier(0.455, 0.03, 0.515, 0.955),
    ease-in-out-zoom: cubic-bezier(0.08, 0.82, 0.17, 1),
) !default;

// Easings implementations
// Default timing function: 'ease'
@mixin lora-aos-easing() {
    [data-aos] {
        @each $key, $val in $aos-easing {
            body[data-aos-easing="#{$key}"] &,
            &[data-aos][data-aos-easing="#{$key}"] {
                transition-timing-function: $val;
            }
        }
    }
}