// Animations variables
$aos-distance: 50px !default; // Animation distance

/**
 * Fade animations: enable, which are required on a brand
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
$aos-animation-fade-up-enabled: true !default;
$aos-animation-fade-down-enabled: false !default;
$aos-animation-fade-right-enabled: false !default;
$aos-animation-fade-left-enabled: false !default;
$aos-animation-fade-up-right-enabled: false !default;
$aos-animation-fade-up-left-enabled: false !default;
$aos-animation-fade-down-right-enabled: false !default;
$aos-animation-fade-down-left-enabled: false !default;

/**
 * Zoom animations: enable, which are required on a brand
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
$aos-animation-zoom-in-enabled: false !default;
$aos-animation-zoom-in-up-enabled: false !default;
$aos-animation-zoom-in-down-enabled: false !default;
$aos-animation-zoom-in-right-enabled: false !default;
$aos-animation-zoom-in-left-enabled: false !default;
$aos-animation-zoom-out-enabled: true !default;
$aos-animation-zoom-out-up-enabled: false !default;
$aos-animation-zoom-out-down-enabled: false !default;
$aos-animation-zoom-out-right-enabled: false !default;
$aos-animation-zoom-out-left-enabled: false !default;

/**
 * Slide animations: enable, which are required on a brand
 * slide-up, slide-down, slide-right, slide-left
 */
$aos-animation-slide-up-enabled: false !default;
$aos-animation-slide-down-enabled: false !default;
$aos-animation-slide-right-enabled: false !default;
$aos-animation-slide-left-enabled: false !default;

/** Flip animations: enable, which are required on a brand
 * flip-left, flip-right, flip-up, flip-down
 */
$aos-animation-flip-left-enabled: false !default;
$aos-animation-flip-right-enabled: false !default;
$aos-animation-flip-up-enabled: false !default;
$aos-animation-flip-down-enabled: false !default;

@mixin lora-aos-animations() {

    // Fade animations

    [data-aos^="fade"][data-aos^="fade"] {
        opacity: 0;
        transition-property: opacity, transform;

        &.aos-animate {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @if ($aos-animation-fade-up-enabled) {
        [data-aos="fade-up"] {
            transform: translate3d(0, $aos-distance, 0);

            @include reduced-motion {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    @if ($aos-animation-fade-down-enabled) {
        [data-aos="fade-down"] {
            transform: translate3d(0, -$aos-distance, 0);
        }
    }

    @if ($aos-animation-fade-right-enabled) {
        [data-aos="fade-right"] {
            transform: translate3d(-$aos-distance, 0, 0);
        }
    }

    @if ($aos-animation-fade-left-enabled) {
        [data-aos="fade-left"] {
            transform: translate3d($aos-distance, 0, 0);
        }
    }

    @if ($aos-animation-fade-up-right-enabled) {
        [data-aos="fade-up-right"] {
            transform: translate3d(-$aos-distance, $aos-distance, 0);
        }
    }

    @if ($aos-animation-fade-up-left-enabled) {
        [data-aos="fade-up-left"] {
            transform: translate3d($aos-distance, $aos-distance, 0);
        }
    }

    @if ($aos-animation-fade-down-right-enabled) {
        [data-aos="fade-down-right"] {
            transform: translate3d(-$aos-distance, -$aos-distance, 0);
        }
    }

    @if ($aos-animation-fade-down-left-enabled) {
        [data-aos="fade-down-left"] {
            transform: translate3d($aos-distance, -$aos-distance, 0);
        }
    }

    // Zoom animations

    [data-aos^="zoom"][data-aos^="zoom"] {
        transition-property: transform;

        &.aos-animate {
            transform: translate3d(0, 0, 0) scale(1);
        }
    }

    @if ($aos-animation-zoom-in-enabled) {
        [data-aos="zoom-in"] {
            transform: scale(0.6);
        }
    }

    @if ($aos-animation-zoom-in-up-enabled) {
        [data-aos="zoom-in-up"] {
            transform: translate3d(0, $aos-distance, 0) scale(0.6);
        }
    }

    @if ($aos-animation-zoom-in-down-enabled) {
        [data-aos="zoom-in-down"] {
            transform: translate3d(0, -$aos-distance, 0) scale(0.6);
        }
    }

    @if ($aos-animation-zoom-in-right-enabled) {
        [data-aos="zoom-in-right"] {
            transform: translate3d(-$aos-distance, 0, 0) scale(0.6);
        }
    }

    @if ($aos-animation-zoom-in-left-enabled) {
        [data-aos="zoom-in-left"] {
            transform: translate3d($aos-distance, 0, 0) scale(0.6);
        }
    }

    @if ($aos-animation-zoom-out-enabled) {
        [data-aos="zoom-out"] {
            transform: scale(1.5);

            @include breakpoint(large) {
                transform: scale(1.2);
            }
            
            @include reduced-motion {
                transform: translate3d(0, 0, 0) scale(1);
            }
        }
    }

    @if ($aos-animation-zoom-out-up-enabled) {
        [data-aos="zoom-out-up"] {
            transform: translate3d(0, $aos-distance, 0) scale(1.2);
        }
    }

    @if ($aos-animation-zoom-out-down-enabled) {
        [data-aos="zoom-out-down"] {
            transform: translate3d(0, -$aos-distance, 0) scale(1.2);
        }
    }

    @if ($aos-animation-zoom-out-right-enabled) {
        [data-aos="zoom-out-right"] {
            transform: translate3d(-$aos-distance, 0, 0) scale(1.2);
        }
    }

    @if ($aos-animation-zoom-out-left-enabled) {
        [data-aos="zoom-out-left"] {
            transform: translate3d($aos-distance, 0, 0) scale(1.2);
        }
    }

    // Slide animations

    [data-aos^="slide"][data-aos^="slide"] {
        transition-property: transform;

        &.aos-animate {
            transform: translate3d(0, 0, 0);
        }
    }

    @if ($aos-animation-slide-up-enabled) {
        [data-aos="slide-up"] {
            transform: translate3d(0, 100%, 0);
        }
    }

    @if ($aos-animation-slide-down-enabled) {
        [data-aos="slide-down"] {
            transform: translate3d(0, -100%, 0);
        }
    }

    @if ($aos-animation-slide-right-enabled) {
        [data-aos="slide-right"] {
            transform: translate3d(-100%, 0, 0);
        }
    }

    @if ($aos-animation-slide-left-enabled) {
        [data-aos="slide-left"] {
            transform: translate3d(100%, 0, 0);
        }
    }

    // Flip animations

    [data-aos^="flip"][data-aos^="flip"] {
        backface-visibility: hidden;
        transition-property: transform;
    }

    @if ($aos-animation-flip-left-enabled) {
        [data-aos="flip-left"] {
            transform: perspective(2500px) rotateY(-100deg);

            &.aos-animate {
                transform: perspective(2500px) rotateY(0);
            }
        }
    }

    @if ($aos-animation-flip-right-enabled) {
        [data-aos="flip-right"] {
            transform: perspective(2500px) rotateY(100deg);

            &.aos-animate {
                transform: perspective(2500px) rotateY(0);
            }
        }
    }

    @if ($aos-animation-flip-up-enabled) {
        [data-aos="flip-up"] {
            transform: perspective(2500px) rotateX(-100deg);

            &.aos-animate {
                transform: perspective(2500px) rotateX(0);
            }
        }
    }

    @if ($aos-animation-flip-down-enabled) {
        [data-aos="flip-down"] {
            transform: perspective(2500px) rotateX(100deg);

            &.aos-animate {
                transform: perspective(2500px) rotateX(0);
            }
        }
    }
}
