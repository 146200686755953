@mixin pseudo-off-the-isle($color: color(primary)) {
    position: relative;

    &::before,
    &::after {
        background: $color;
        bottom: 0;
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &::before {
        #{$global-right}: 100%;
    }

    &::after {
        #{$global-left}: 100%;
    }
}
