/* stylelint-disable declaration-no-important */

/// Makes an element visually hidden, but still accessible to keyboards and assistive devices.
/// @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility Hiding Content for Accessibility
/// @link http://hugogiraudel.com/2016/10/13/css-hide-and-seek/
@mixin element-invisible {
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
}

/// Reverses the CSS output created by the `element-invisible()` mixin.
@mixin element-invisible-off {
    clip: auto;
    clip-path: none;
    height: auto;
    overflow: visible;
    position: static !important;
    white-space: normal;
    width: auto;
}
