$signin-secondary-margin--large: rem-calc(0 60 0 0) !default;
$signin-forgotpassword-margin: rem-calc(0 0 0) auto !default;
$signin-forgotpassword-font-size: rem-calc(14) !default;
$signin-forgotpassword-line-height: null !default;
$signin-forgotpassword-white-space: null !default;
$signin-forgotpassword-font-weight: null !default;
$signin-forgotpassword-text-transform: null !default;
$signin-forgotpassword-letter-spacing: null !default;
$signin-forgotpassword-font-family: null !default;

@mixin lora-layout-signin {
    .l-signin {
        &.m-secondary {
            @include breakpoint(large) {
                margin: $signin-secondary-margin--large;
            }
        }
    }

    .l-signin__forgotpassword {
        margin: $signin-forgotpassword-margin;
        font-size: $signin-forgotpassword-font-size;
        line-height: $signin-forgotpassword-line-height;
        white-space: $signin-forgotpassword-white-space;
        font-weight: $signin-forgotpassword-font-weight;
        text-transform: $signin-forgotpassword-text-transform;
        letter-spacing: $signin-forgotpassword-letter-spacing;
        font-family: $signin-forgotpassword-font-family;
    }

    .l-signin__link {
        @include hide-for(large);
    }
}