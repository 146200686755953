$footer-contacts-text-align--medium-down: left;
$footer-contacts-title-font: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$footer-contacts-title-font--large: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$footer-contacts-title-text-transform--large: uppercase;
$footer-contacts-title-margin--large: rem-calc(0 0 30);
$footer-contacts-link-background: color(light);
$footer-contacts-link-radius: 0;
$footer-contacts-link-border: 0;
$footer-contacts-link-color: color(dark);
$footer-contacts-link-font: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$footer-contacts-link-height: rem-calc(50);
$footer-contacts-link-justify-content: space-between;
$footer-contacts-link-padding: rem-calc(5 25);
$footer-contacts-link-text-transform: uppercase;
$footer-contacts-link-flex-basis--medium-down: 100%;
$footer-contacts-link-first-child-margin: rem-calc(0 0 10);
$footer-contacts-link-last-child-margin: 0;
$footer-contacts-description-flex-direction--medium-down: column;
$footer-contacts-border-bottom: 0;
$footer-contacts-link-store-icon-height: rem-calc(35);
$footer-contacts-link-store-icon-width: rem-calc(35);
$footer-contacts-link-phone-icon-height: rem-calc(35);
$footer-contacts-link-phone-icon-width: rem-calc(35);
$footer-contacts-link-hover-color: color(light);
$footer-contacts-description-font: normal #{rem-calc(12)} / 1.5 $font-primary;
$footer-contacts-description-link-color: color(primary);
$footer-contacts-description-link-hover-color: color(light);

@import "@lora/05-components/footer/footer-contacts";