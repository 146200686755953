// Webkit styles (Chrome, Safari)
$scrollbar-webkit-width: rem-calc(6) !default;
$scrollbar-webkit-border-radius: rem-calc(10) !default;
$scrollbar-webkit-button-display: none !default;
$scrollbar-webkit-thumb-background: color(primary) !default;
$scrollbar-webkit-track-background: transparent !default;
$scrollbar-webkit-thumb-background-dark: color(text-secondary) !default;
$scrollbar-horizontal-webkit-height: $scrollbar-webkit-width !default;
// Firefox styles
$scrollbar-width: thin !default;
$scrollbar-color: $scrollbar-webkit-thumb-background $scrollbar-webkit-track-background !default;
$scrollbar-color-dark: $scrollbar-webkit-thumb-background-dark $scrollbar-webkit-track-background !default;

@mixin lora-helper-scrollbar-vertical {
    overflow-y: scroll;
    /* stylelint-disable property-no-unknown */
    @-moz-document url-prefix('') {
        scrollbar-color: $scrollbar-color;
        scrollbar-width: $scrollbar-width;
    }
    /* stylelint-enable */

    &::-webkit-scrollbar {
        width: $scrollbar-webkit-width;
    }

    &::-webkit-scrollbar-button {
        display: $scrollbar-webkit-button-display;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track-piece {
        background: $scrollbar-webkit-track-background;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $scrollbar-webkit-border-radius;
        background: $scrollbar-webkit-thumb-background;
    }
}

@mixin lora-helper-scrollbar-vertical-dark-mode {
    @-moz-document "url-prefix"('') {
        scrollbar-color: $scrollbar-color-dark;
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-webkit-thumb-background-dark;
    }
}

@mixin lora-helper-scrollbar-horizontal {
    overflow-x: scroll;

    &::-webkit-scrollbar {
        height: $scrollbar-horizontal-webkit-height;
    }
}

@mixin lora-components-scrollbar {
    .c-scrollbar {
        @include lora-helper-scrollbar-vertical;

        &.m-horizontal {
            @include lora-helper-scrollbar-horizontal;
        }
    }
}
