/// CSS gradients necessary to provide a progressive transition between two or more colors

/// Background gradient helper mixin
/// @param {Color} $start-color - Start color
/// @param {Color} $end-color - End color
/// @param {String} $orientation - Type of gradient, either `vertical`, `horizontal` or `radial`
@mixin gradient($start-color, $end-color, $orientation) {
    background-color: $start-color;

    // Vertical Gradient
    @if $orientation == 'vertical' {
        background: -webkit-linear-gradient(top, $start-color, $end-color);
        background: linear-gradient(to bottom, $start-color, $end-color);
    }

    // Horizontal Gradient
    @else if $orientation == 'horizontal' {
        background: -webkit-linear-gradient(left, $start-color, $end-color);
        background: linear-gradient(to right, $start-color, $end-color);
    }

    // Radial Gradient
    @else if $orientation == 'radial' {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
        background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}

/// Block with gradient background mixin
/// @param {Number} $height - Height for the gradient block.
/// @param {Color} $start-color - Start color
/// @param {Color} $end-color - End color
/// @param {String} $orientation - Type of gradient, either `vertical`, `horizontal` or `radial`
@mixin gradient-block($start-color, $end-color, $orientation, $height) {
    @include gradient($start-color, $end-color, $orientation);

    position: absolute;
    height: $height;
    width: 100%;
    top: unset;
    z-index: inherit;
    bottom: 0;
}
