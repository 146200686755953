/*------------------------------------*\
        #MAIN
\*------------------------------------*/
@mixin lora-helper-layout-main {
    position: relative;
    z-index: z(main);
}

@mixin lora-layout-main {
    .layout,
    .l-layout {
        @include layout;
    }

    .l-grid {
        @include grid;
    }

    .l-grid--large {
        @include breakpoint('large') {
            @include grid;
        }
    }

    .l-grid__section {
        @include cell;

        display: flex;
        flex-direction: column;

        &.m-cell-2 {
            @include breakpoint('large') {
                @include cell(1 of 2);
            }
        }
    }

    .l-main {
        @include lora-helper-layout-main;
    }
}
