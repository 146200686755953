/* stylelint-disable all */
// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group xy-grid
////

// Margin Grid classes
@mixin xy-base-grid-classes {
    // Base grid styles
    .l-row {
        @include xy-grid;
    }

    .l-column {
        @include xy-cell-base();
        @include xy-cell-static($grid-columns, false, $gutter-type: padding);

        &.m-auto {
            @include xy-cell-base(auto);
        }

        &.m-shrink {
            @include xy-cell-base(shrink);
        }

        &.m-grow {
            @include xy-cell-base(grow);
        }
    }

    .l-row {
        > .m-auto {
            @include xy-cell-static(auto, false);
        }

        > .m-shrink {
            @include xy-cell-static(shrink, false);
        }
    }

    // Auto width
    @include -zf-each-breakpoint() {
        // This is a bit of a hack/workaround, see these issues & PRs for the backstory:
        // https://github.com/zurb/foundation-sites/issues/10244
        // https://github.com/zurb/foundation-sites/pull/10222 and
        // https://github.com/zurb/foundation-sites/pull/10164
        .l-row {
            $str: "> .m-#{$-zf-size}-shrink, > .m-#{$-zf-size}-full";
            @for $i from 1 through $grid-columns {
                $str: $str + ", > .#{$-zf-size}-#{$i}";
            }

            #{$str} {
                flex-basis: auto;
            }
        }
    }

    @include -zf-each-breakpoint() {
        @if not($-zf-size == small) {
            .l-row > .m-#{$-zf-size}-auto {
                @include xy-cell-base(auto);
                @include xy-cell-static(auto, false);
            }

            .l-row > .m-#{$-zf-size}-shrink {
                @include xy-cell-base(shrink);
                @include xy-cell-static(shrink, false);
            }
        }

        @for $i from 1 through $grid-columns {
            // Sizing (percentage)
            .l-row > .m-#{$-zf-size}-#{$i} {
                @include xy-cell-static($i, false, $gutter-type: padding);
            }
        }
    }

    // Reset width when using `.l-row-margin` not on `.l-row`
    .l-row-margin:not(.l-row) > .l-column {
        width: auto;
    }

    // Reset height when using `.l-row-vertical-margin` not on `.l-row-vertical`
    .l-row-vertical-margin:not(.l-row-vertical) > .l-column {
        height: auto;
    }
}

@mixin -xy-breakpoint-cell-classes($class-breakpoint, $gutter-breakpoint, $vertical) {
    $prefix: if($class-breakpoint == $-zf-zero-breakpoint, '', '#{$class-breakpoint}-');

    > .m-#{$prefix}auto {
        @include xy-cell-static(auto, false, $breakpoint: $gutter-breakpoint, $vertical: $vertical);
    }

    > .m-#{$prefix}shrink {
        @include xy-cell-static(shrink, false, $breakpoint: $gutter-breakpoint, $vertical: $vertical);
    }

    @for $i from 1 through $grid-columns {
        // Sizing (percentage)
        $classname: if($vertical, '.#{$class-breakpoint}-#{$i}', '.#{$class-breakpoint}-#{$i}');

        > #{$classname} {
            @include xy-cell-static($i, false, $breakpoint: $gutter-breakpoint, $vertical: $vertical);
        }
    }
}

// Margin Grid classes
@mixin xy-margin-grid-classes(
    $gutter-position: left right,
    $vertical: false,
    $wrapping-selector: '.l-row-margin'
) {
    #{$wrapping-selector} {
        @include xy-gutters($negative: true, $gutter-position: $gutter-position);

        // Base cell styles
        > .l-column {
            @include xy-cell-static($vertical: $vertical);
        }

        // base styles need to all be before the auto and shrink styles
        @include -zf-each-breakpoint() {
            @if (type-of($grid-margin-gutters) == 'map' and map-has-key($grid-margin-gutters, $-zf-size) and $-zf-size != $-zf-zero-breakpoint) {
                > .l-column {
                    @include xy-cell-static($breakpoint: $-zf-size, $vertical: $vertical);
                }
            }
        }

        @include -zf-each-breakpoint() {

            // This is purely for responsive gutters - the margin grid has to go back and adjust widths (or heights)
            // for prior breakpoints based on the responsive gutter.
            @if (type-of($grid-margin-gutters) == 'map' and map-has-key($grid-margin-gutters, $-zf-size)) {
                @each $bp in -zf-breakpoints-less-than($-zf-size) {
                    @if (map-has-key($grid-margin-gutters, $bp)) {
                        @include -xy-breakpoint-cell-classes($bp, $-zf-size, $vertical);
                    }
                }
            }

            @include -xy-breakpoint-cell-classes($-zf-size, $-zf-size, $vertical);
        }
    }
}

// Padding Grid classes
@mixin xy-padding-grid-classes {
    .l-row-padding {
        @include xy-gutters($negative: true, $gutters: $grid-padding-gutters);

        // Base cell styles
        > .l-column {
            @include xy-gutters($gutters: $grid-padding-gutters, $gutter-type: padding);
        }

        // Grid gutters
        @include -zf-each-breakpoint() {
            @each $gutter, $size in $grid-gutters {
                @if (map-deep-get($size, $-zf-size)) {
                    &.m-gutter-#{$gutter}-for-#{$-zf-size} {
                        @include xy-gutters($gutters: map-deep-get($size, $-zf-size), $negative: true);

                        & > .l-column {
                            @include xy-gutters($gutters: map-deep-get($size, $-zf-size), $gutter-type: padding);
                        }
                    }
                }
            }

            &.m-no-gutter-for-#{$-zf-size} {
                @include xy-gutters($gutters: 0);
                
                & > .l-column {
                    @include xy-gutters($gutters: 0, $gutter-type: padding);
                }
            }
        }
    }
}

// Block Grid classes
@mixin xy-block-grid-classes($margin-grid: true, $padding-grid: true) {
    @if $padding-grid {
        @include -zf-each-breakpoint {
            @for $i from 1 through $xy-block-grid-max {
                .l-row-padding.m-#{$-zf-size}-up-#{$i} {
                    @include xy-grid-layout($i, '.l-column', false, $gutter-type: padding);
                }
            }
        }
    }

    @if $margin-grid {
        @include -zf-each-breakpoint {
            @for $i from 1 through $xy-block-grid-max {
                // This is purely for responsive gutters - the margin grid has to go back and adjust widths (or heights)
                // for prior breakpoints based on the responsive gutter.
                @if (type-of($grid-margin-gutters) == 'map' and map-has-key($grid-margin-gutters, $-zf-size)) {
                    @each $bp in -zf-breakpoints-less-than($-zf-size) {
                        @if (map-has-key($grid-margin-gutters, $bp)) {
                            .l-row-margin.m-#{$bp}-up-#{$i} {
                                @include xy-grid-layout($i, '.l-column', false, $gutter-type: margin, $breakpoint: $bp);
                            }
                        }
                    }
                }
            }
            @for $i from 1 through $xy-block-grid-max {
                .l-row-margin.m-#{$-zf-size}-up-#{$i} {
                    @include xy-grid-layout($i, '.l-column', false, $gutter-type: margin, $breakpoint: $-zf-size);
                }
            }
        }
    }
}

// Collapse classes
@mixin xy-collapse-grid-classes($margin-grid: true, $padding-grid: true) {
    @each $bp in $breakpoint-classes {
        @if $margin-grid {
            .l-row-#{$bp}-margin-collapse {
                @include xy-grid-collapse($gutter-type: margin, $min-breakpoint: $bp);
            }
        }

        @if $padding-grid {
            .l-row-#{$bp}-padding-collapse {
                @include xy-grid-collapse($gutter-type: padding, $min-breakpoint: $bp);
            }
        }
    }
}

// Offset classes
@mixin xy-offset-cell-classes {
    @include -zf-each-breakpoint {
        @for $i from 1 through $grid-columns {
            // Offsets
            $o: $i - 1;

            .#{$-zf-size}-offset-#{$o} {
                @include xy-cell-offset($o, $gutters: $grid-padding-gutters, $gutter-type: padding, $breakpoint: $-zf-size);
            }

            .l-row-margin > .#{$-zf-size}-offset-#{$o} {
                @include xy-cell-offset($o, $breakpoint: $-zf-size);
            }
        }
    }
}

// Vertical Grid classes
@mixin xy-vertical-grid-classes(
    $margin-grid: true,
    $padding-grid: true
) {

    @include -zf-each-breakpoint() {
        @if not($-zf-size == small) {
        }
    }

    .l-row-vertical {
        @include xy-grid(vertical, false);

        > .l-column {
            @include xy-cell-reset();
        }

        > .m-auto {
            @include xy-cell-static(auto, false, $breakpoint: $-zf-size, $vertical: true);
        }

        > .m-shrink {
            @include xy-cell-static(shrink, false, $breakpoint: $-zf-size, $vertical: true);
        }

        @include -zf-each-breakpoint() {
            // This is a bit of a hack/workaround, see these issues and PRs for the backstory:
            // https://github.com/zurb/foundation-sites/issues/10244
            // https://github.com/zurb/foundation-sites/pull/10222 and
            // https://github.com/zurb/foundation-sites/pull/10164
            $str: "> .m-#{$-zf-size}-shrink, > .m-#{$-zf-size}-full";
            @for $i from 1 through $grid-columns {
                $str: $str + ", > .#{$-zf-size}-#{$i}";
            }

            #{$str} {
                flex-basis: auto;
            }
        }

        @include -zf-each-breakpoint() {
            @if not($-zf-size == small) {
                > .m-#{$-zf-size}-auto {
                    @include xy-cell-base(auto);
                    @include xy-cell-static(auto, false, $breakpoint: $-zf-size, $vertical: true);
                }

                > .m-#{$-zf-size}-shrink {
                    @include xy-cell-static(shrink, false, $breakpoint: $-zf-size, $vertical: true);
                }
            }

            @for $i from 1 through $grid-columns {
                // Sizing (percentage)
                > .m-#{$-zf-size}-#{$i} {
                    @include xy-cell-static($i, false, $vertical: true, $gutter-type: padding);
                }
            }
        }
    }

    @if $padding-grid {
        .l-row-vertical-padding {
            // Negative margin for nested grids
            .l-row-vertical-padding {
                @include xy-gutters($negative: true, $gutter-position: top bottom);
            }

            // Base cell styles
            > .l-column {
                @include xy-gutters($gutters: $grid-padding-gutters, $gutter-type: padding, $gutter-position: top bottom);
            }
        }
    }

    @if $margin-grid {
        @include xy-margin-grid-classes(top bottom, true, '.l-row-vertical-margin');
    }
}

@mixin xy-frame-grid-classes($vertical-grid: true, $margin-grid: true) {
    // Framed grid styles
    .l-grid-frame {
        @include xy-grid-frame;
    }

    .l-column .l-grid-frame {
        width: 100%; // Same as include with $nested, but with less css
    }

    .l-column-block {
        @include xy-cell-block();
    }

    .l-column-block-y {
        @include xy-cell-block(true);
    }

    .l-column-block-container {
        @include xy-cell-block-container();
    }

    @include -zf-each-breakpoint(false) {
        .l-#{$-zf-size}-grid-frame {
            @include xy-grid-frame;
        }

        .l-column .l-#{$-zf-size}-grid-frame {
            width: 100%; // Same as include with $nested, but with less css
        }

        .l-#{$-zf-size}-column-block {
            @include xy-cell-block();
        }

        .l-#{$-zf-size}-column-block-container {
            @include xy-cell-block-container();
        }

        .l-#{$-zf-size}-column-block-y {
            @include xy-cell-block(true);
        }
    }

    @if $vertical-grid {
        .l-row-vertical {
            &.l-grid-frame {
                width: auto;
                @include xy-grid-frame(true);
            }

            @include -zf-each-breakpoint(false) {
                &.l-#{$-zf-size}-grid-frame {
                    width: auto;
                    @include xy-grid-frame(true);
                }
            }
        }

        .l-column {
            .l-row-vertical.l-grid-frame {
                height: 100%; // Same as include with $nested, but with less css
            }
            @include -zf-each-breakpoint(false) {
                .l-row-vertical.l-#{$-zf-size}-grid-frame {
                    height: 100%; // Same as include with $nested, but with less css
                }
            }
        }
    }
    @if $margin-grid {
        @include xy-margin-grid-classes(top bottom, true, '.l-row-vertical-margin');

        .l-grid-frame.l-row-vertical-margin {
            @include xy-grid-frame(true, false, $grid-margin-gutters, $include-base: false);
        }
        @include -zf-each-breakpoint(false) {
            .l-row-vertical-margin.l-#{$-zf-size}-grid-frame {
                @include xy-grid-frame(true, false, $grid-margin-gutters, $-zf-size, false);
            }
        }
    }
}

// Final classes
@mixin foundation-xy-grid-classes(
    $base-grid: true,
    $margin-grid: true,
    $padding-grid: true,
    $block-grid: true,
    $collapse: true,
    $offset: true,
    $vertical-grid: true,
    $frame-grid: true
) {

    // Base grid styles
    @if ($base-grid) {
        @include xy-base-grid-classes();
    }

    // Margin grid
    @if ($margin-grid) {
        @include xy-margin-grid-classes();
    }

    // Padding grid
    @if ($padding-grid) {
        @include xy-padding-grid-classes();
    }

    // Block grid
    @if ($block-grid) {
        @include xy-block-grid-classes($margin-grid, $padding-grid);
    }

    // Collapse gutters
    @if ($collapse) {
        @include xy-collapse-grid-classes($margin-grid, $padding-grid);
    }

    // Offset gutters
    @if ($offset) {
        @include xy-offset-cell-classes();
    }

    // Vertical grid
    @if ($vertical-grid) {
        @include xy-vertical-grid-classes($margin-grid, $padding-grid);
    }

    @if ($frame-grid) {
        @include xy-frame-grid-classes($vertical-grid, $margin-grid);
    }
}