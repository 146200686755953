$form-text-field-border: 0;
$form-text-field-border-focus: 0;
$form-text-field-border-hover: 0;
$form-text-field-box-shadow: 0 2px 0 color(organism-background);
$form-text-field-box-shadow-hover: 0 2px 0 color(organism-background);
$form-text-field-box-shadow-focus: 0 2px 0 color(organism-background);
$form-text-field-radius: 0;
$form-text-field-padding: rem-calc(20px 10px 14px 0);
$form-text-field-height: rem-calc(49);
$form-text-field-background: color(light);
$form-text-field-color: inherit;
$form-text-field-color-hover: inherit;
$form-text-field-font-size: rem-calc(14);
$form-text-field-font-weight: 500;
$form-text-field-font-weight-hover: $form-text-field-font-weight;
$form-text-field-counter-font-size: rem-calc(14);
$form-text-field-password-button-icon-width: rem-calc(23);
$form-text-field-search-button-icon-width: rem-calc(24);
$form-text-field-textarea-min-height: auto;
$form-text-field-font-family: $font-secondary;

@import "@lora/05-components/form/text-field";