@import "../accordion";

$product-bundle-margin--large: null !default;

// Product bundle title
$product-bundle-title-font: #{rem-calc(18)} / 1.5 $font-primary !default;
$product-bundle-title-font--large: null !default;
$product-bundle-title-qty-font: 700 #{rem-calc(14)} / 1 $font-primary !default;
$product-bundle-title-qty-font--large: null !default;
$product-bundle-title-qty-margin: null !default;
$product-bundle-title-qty-margin--large: null !default;
$product-bundle-title-qty-color: null !default;
$product-bundle-title-display: flex !default;
$product-bundle-title-align-items: center !default;
$product-bundle-title-justify-content: null !default;
$product-bundle-title-text-transform: none !default;
$product-bundle-title-text-transform--large: null !default;
$product-bundle-title-padding: rem-calc(20 30 20 0) !default;
$product-bundle-title-after-transform: rotateZ(0) !default;
$product-bundle-title-after-margin: rem-calc(-5 0 0) !default;
$product-bundle-title-border-bottom: $global-border !default;
$product-bundle-last-item-border-bottom: $global-border !default;
$product-bundle-title-color: color(text) !default;
$product-bundle-title-name-font--medium-down: 500 #{rem-calc(18)} / 1 $font-primary !default;

// Expanded bundle
$product-bundle-title-after-expanded-transform: translateY(-50%) rotate(180deg) !default;
$product-bundle-title-after-expanded-margin: 0 !default;

// Product bundle list and item
$product-bundle-list-style-type: none !default;
$product-bundle-list-margin: 0 !default;
$product-bundle-item-padding: rem-calc(15 0) !default;
$product-bundle-item-padding--large: null !default;
$product-bundle-item-border-bottom: $global-border !default;

// Plus icon
$product-bundle-icon-margin: rem-calc(25 0 0) !default;
$product-bundle-icon-margin--large: null !default;
$product-bundle-icon-size: rem-calc(14) !default;
$product-bundle-icon-size--large: rem-calc(18) !default;
$product-bundle-icon: plus !default;
$product-bundle-icon-color: color(primary) !default;
$product-bundle-icon-usesvg: true !default;

@mixin lora-components-product-bundle {
    .c-product-bundle {
        &.m-expanded {
            > .c-product-bundle__title {
                @include lora-helper-accordion-control-expanded;

                & > .c-accordion__icon::after {
                    transform: $product-bundle-title-after-expanded-transform;
                    margin: $product-bundle-title-after-expanded-margin;
                    content: "";
                }
            }

            > .c-product-bundle__content {
                @include lora-animation-expanded;
            }
        }

        .c-product-variation__name {
            display: none;
        }

        .c-swatch {
            @include lora-helper-swatch-size(medium);
            @include lora-helper-swatch-selected(none);
        }

        @include breakpoint(large) {
            margin: $product-bundle-margin--large;
        }
    }

    .c-product-bundle__content {
        @include lora-animation-collapsed;
    }

    .c-product-bundle__title {
        @include lora-helper-accordion-control-collapsed;

        display: $product-bundle-title-display;
        align-items: $product-bundle-title-align-items;
        justify-content: $product-bundle-title-justify-content;
        text-transform: $product-bundle-title-text-transform;
        padding: $product-bundle-title-padding;
        font: $product-bundle-title-font;
        border-bottom: $product-bundle-title-border-bottom;
        color: $product-bundle-title-color;

        @include breakpoint(large) {
            font: $product-bundle-title-font--large;
            text-transform: $product-bundle-title-text-transform--large;
        }

        & > .c-accordion__icon::after {
            @include select-arrow;

            content: '';
            transform: $product-bundle-title-after-transform;
            margin: $product-bundle-title-after-margin;
        }
    }

    .c-product-bundle__title-name {
        @include breakpoint(medium down) {
            font: $product-bundle-title-name-font--medium-down;
        }
    }

    .c-product-bundle__title-qty {
        font: $product-bundle-title-qty-font;
        color: $product-bundle-title-qty-color;
        margin: $product-bundle-title-qty-margin;

        @include breakpoint(large) {
            font: $product-bundle-title-qty-font--large;
            margin: $product-bundle-title-qty-margin--large;
        }
    }

    .c-product-bundle__list {
        list-style-type: $product-bundle-list-style-type;
        margin: $product-bundle-list-margin;
    }

    .c-product-bundle__item {
        padding: $product-bundle-item-padding;
        border-bottom: $product-bundle-item-border-bottom;

        @include breakpoint(large) {
            padding: $product-bundle-item-padding--large;
        }

        &:last-child {
            border-bottom: $product-bundle-last-item-border-bottom;
        }
    }

    .c-product-bundle__icon {
        display: block;
        margin: $product-bundle-icon-margin;

        @include breakpoint(large) {
            margin: $product-bundle-icon-margin--large;
        }

        &::before {
            @include svg-icon($product-bundle-icon, $product-bundle-icon-color, 100%, left, no-repeat, $usesvg: $product-bundle-icon-usesvg);

            content: '';
            display: block;
            height: $product-bundle-icon-size;
            width: $product-bundle-icon-size;

            @include breakpoint(large) {
                height: $product-bundle-icon-size--large;
                width: $product-bundle-icon-size--large;
            }
        }
    }
}
