// Sizes
$form-select-size: (
    "small": $global-input-primary-height-small,
    "normal": $global-input-primary-height,
    "large": $global-input-primary-height-large
);

$form-select-rows-2-size: (
    "small": rem-calc(35),
    "normal": rem-calc(50),
    "large": rem-calc(56)
) !default;

$form-select-rows-2-line-height: (
    "small": rem-calc(10),
    "normal": rem-calc(14),
    "large": rem-calc(16)
) !default;

$form-select-description-rows-2-font-sizes: (
    "small": rem-calc(10),
    "normal": rem-calc(12),
    "large": rem-calc(12)
) !default;

$form-select-font-sizes: (
    "small": rem-calc(11),
    "normal": rem-calc(14),
    "large": rem-calc(14)
) !default;

// Default select
$form-select-color: color(dark) !default;
$form-select-option-color: #000 !default; // Windows fix for select options inheriting styles
$form-select-background: color(light) !default;
$form-select-border-color: color(dark) !default;
$form-select-border-width: 1px !default;
$form-select-padding: rem-calc(0 30 0 20) !default;
$form-select-height: map-get($form-select-size, 'normal') !default;
$form-select-font-size: rem-calc(14) !default;
$form-select-font-family: $font-primary !default;
$form-select-border-radius: $form-radius !default;
$form-select-alt-padding: rem-calc(0 30 0 10) !default;
$form-select-transition: box-shadow 0.2s, border-color 0.2s ease-in-out !default;
$form-select-text-transform: none !default;
$form-select-font-weight: null !default;
$form-select-font: $form-select-font-weight #{$form-select-font-size} / $form-select-height $form-select-font-family !default;
$form-select-label-font: null !default;
$form-select-label-margin: null !default;
$form-select-label-float-margin: 0 !default;
// Description wrapper
$form-select-description-wrapper-flex: 1 1 0 !default;
/// Select Arrow
$form-select-arrow: "chevron-down" !default;
$form-select-arrow-opened-transform: rotate(180deg) translateY(50%) !default;
$form-select-arrow-size: rem-calc(10) !default;
$form-select-arrow-background-size: 100% !default;
$form-select-arrow-color: darken($form-select-border-color, 20%) !default;
$form-select-arrow-right: rem-calc(15) !default;
$form-select-arrow-usesvg: true !default;
/// Select Icon
$form-select-icon-margin: rem-calc(0 10 0 0) !default;
$form-select-icon-size: rem-calc(20) !default;
$form-select-icon-border-radius: 50% !default;
$form-select-icon-align-self: center !default;
// Hover state
$form-select-border-color-hover: color(dark) !default;
$form-select-color-hover: color(primary-active) !default;
$form-select-box-shadow-hover: inset rem-calc(0 0 0 1) color(dark) !default;
$form-select-font-weight-hover: $global-weight-normal !default;
// Active state
$form-select-border-color-active: color(dark) !default;
// Disabled state
$form-select-border-color-disabled: color(disabled) !default;
$form-select-label-color-disabled: color(disabled) !default;
$form-select-background-disabled: color(light) !default;
$form-select-arrow-color-disabled: color(disabled) !default;

// Panel
$form-select-panel-item-padding: rem-calc(10) !default;
$form-select-panel-item-color: inherit !default;
$form-select-panel-item-background-hover: color(element-background) !default;
$form-select-panel-item-box-shadow-hover: 0 0 0 1px color(dark) !default;
$form-select-panel-item-background-selected: color(element-background) !default;
$form-select-panel-item-box-shadow-selected: 0 0 0 1px color(dark) !default;
$form-select-panel-item-color-hover: $form-select-panel-item-color !default;
$form-select-panel-item-color-selected: $form-select-panel-item-color !default;
$form-select-panel-item-font: inherit !default;
$form-select-panel-item-font-hover: null !default;
$form-select-panel-item-font-selected: null !default;
$form-select-panel-item-text-transform: null !default;
$form-select-panel-item-letter-spacing: null !default;
$form-select-panel-background: color(light) !default;
$form-select-panel-padding: 0 !default;
$form-select-panel-min-width: null !default;
$form-select-panel-border-color: color(dark) !default;

// Panel list
$form-select-panel-list-max-height: rem-calc(200) !default;
$form-select-panel-list-padding: 0 !default;

// Disabled item
$form-select-custom-disabled-icon: cross !default;
$form-select-custom-disabled-icon-color: color(light) !default;
$form-select-custom-disabled-icon-usesvg: true !default;

//Secondary
$form-select-secondary-border: 0 !default;
$form-select-secondary-background: transparent !default;
$form-select-secondary-font-weight: normal !default;
$form-select-secondary-font-size: rem-calc(14) !default;
$form-select-secondary-padding: rem-calc(0 15 0 0) !default;
$form-select-secondary-arrow-right: 0 !default;
$form-select-secondary-arrow-color: color(primary) !default;
$form-select-secondary-box-shadow-hover: none !default;

//Third
$form-select-third-background: color(primary) !default;
$form-select-third-border-color: $form-select-third-background !default;
$form-select-third-color: color(light) !default;
$form-select-third-radius: $form-radius !default;
$form-select-third-arrow-color: color(light) !default;
$form-select-third-float-label-color: color(light) !default;
$form-select-third-float-label-background-color: color(primary) !default;
$form-select-third-option-color: color(light) !default;
$form-select-third-text-transform: $form-select-text-transform !default;
$form-select-third-description-color: color(light) !default;
$form-select-third-item-background-hover: color(primary-active) !default;
$form-select-third-item-color-hover: color(light) !default;
$form-select-third-item-box-shadow-hover: 0 0 0 1px color(light) !default;
$form-select-third-item-background-selected: color(primary-active) !default;
$form-select-third-item-color-selected: color(light) !default;
$form-select-third-item-box-shadow-selected: 0 0 0 1px color(light) !default;

// Single value
$form-select-background-single-value: transparent !default;
$form-select-border-color-single-value: transparent !default;
$form-select-padding-single-value: 0 !default;
$form-select-single-value-label-float-left: 0 !default;
$form-select-single-value-label-float-padding: 0 !default;
$form-select-single-value-label-float-text-transform: null !default;

//Float
//needs to hide default option not to overlap the float label
$form-select-float-empty-color: rgba(255, 255, 255, 0);

// Filter
$form-select-filter-padding: rem-calc(10) !default;
$form-select-filter-background: color(global-background) !default;
/// Filter field
$form-select-filter-field-padding: rem-calc(0 40 0 20) !default;
$form-select-filter-field-font: null !default;
$form-select-filter-field-background: null !default;

/// Filter result
$form-select-filter-result-padding: rem-calc(10) !default;
$form-select-filter-result-font: #{rem-calc(14)} / 1.2 $font-primary !default;
$form-select-filter-result-color: color(text-secondary) !default;
/// Filter button
$form-select-filter-button-padding: rem-calc(10) !default;
$form-select-filter-button-right: rem-calc(10) !default;

// Filter Button icon
$form-select-filter-button-icon: "search" !default;
$form-select-filter-button-icon-size: 15px !default; // IE fix with image crop
$form-select-filter-button-icon-color: color(text) !default;
$form-select-filter-button-icon-usesvg: true !default;

// Filter Button active icon
$form-select-filter-button-active-icon: "cross" !default;
$form-select-filter-button-active-icon-color: color(text) !default;
$form-select-filter-button-active-icon-usesvg: true !default;

// Loading state
$form-select-loading-overlay-background: color(light) !default;
$form-select-loading-overlay-opacity: 0.7 !default;
$form-select-loading-icon: "reload" !default;
$form-select-loading-icon-size: rem-calc(14) !default;
$form-select-loading-icon-color: color(dark) !default;
$form-select-loading-icon-animation: loader-spin 1s infinite !default;
$form-select-loading-icon-usesvg: true !default;

//Select color description
$form-select-description-color: color(text-secondary) !default;

// Select badge
$form-select-badge-background: color(primary) !default;
$form-select-badge-shadow: null !default;
$form-select-badge-border: null !default;
$form-select-badge-border-radius: rem-calc(4) !default;
$form-select-badge-color: color(light) !default;
$form-select-badge-font: #{rem-calc(11)} / 1.2 $font-primary !default;
$form-select-badge-padding: rem-calc(4 8) !default;
$form-select-badge-text-transform: null !default;
/// Badge image
$form-select-badge-image-size: rem-calc(30) !default;

// Default select style
@mixin select-arrow(
    $arrow-size: $form-select-arrow-size,
    $arrow-color: $form-select-arrow-color
) {
    @include svg-icon($form-select-arrow, $arrow-color, $form-select-arrow-background-size, left, no-repeat, $usesvg: $form-select-arrow-usesvg);

    width: $arrow-size;
    height: $arrow-size;
}

@mixin select-element {
    position: relative;
    display: block;
    flex-grow: 1;

    &::after {
        @include select-arrow;
        @include vertical-center;

        content: '';
        pointer-events: none;
        #{$global-right}: $form-select-arrow-right;
        z-index: 1; // stylelint-disable-line
    }
}

@mixin select-control($padding: $form-select-padding) {
    @include text-truncate;

    direction: $global-text-direction;
    appearance: none;
    background: $form-select-background;
    border-radius: $form-select-border-radius;
    border: $form-select-border-width solid $form-select-border-color;
    color: $form-select-color;
    text-transform: $form-select-text-transform;
    cursor: pointer;
    display: block;
    height: $form-select-height;
    font: $form-select-font;
    margin: 0;
    outline-width: 0;
    padding: $padding;
    text-align: #{$global-left};
    user-select: none;
    width: 100%;

    @if has-value($form-select-transition) {
        transition: $form-select-transition;
    }

    &:-moz-focusring {
        color: color(transparent);
        text-shadow: 0 0 0 $form-select-color;
    }

    &:hover {
        border-color: $form-select-border-color-hover;
        box-shadow: $form-select-box-shadow-hover;
        color: $form-select-color-hover;
        font-weight: $form-select-font-weight-hover;
    }

    &:disabled {
        background: $form-select-background-disabled;
        border-color: $form-select-border-color-disabled;
        color: $form-select-label-color-disabled;
        cursor: default;
        pointer-events: none;
    }

    option {
        color: $form-select-option-color;
    }
}

// Secondary select style
@mixin select-secondary-control {
    background-color: $form-select-secondary-background;
    border: $form-select-secondary-border;
    font-size: $form-select-secondary-font-size;
    padding: $form-select-secondary-padding;

    &:hover,
    &:focus {
        box-shadow: $form-select-secondary-box-shadow-hover;
    }

    &:focus {
        outline-width: 1px;
    }
}

// Third select style
@mixin select-third-control {
    background: $form-select-third-background;
    border-color: $form-select-third-border-color;
    border-radius: $form-select-third-radius;
    color: $form-select-third-color;
    text-transform: $form-select-third-text-transform;

    .c-select__panel-item {
        &:hover {
            background: $form-select-third-item-background-hover;
            color: $form-select-third-item-color-hover;
            box-shadow: $form-select-third-item-box-shadow-hover;
        }

        &.m-selected {
            background: $form-select-third-item-background-selected;
            color: $form-select-third-item-color-selected;
            box-shadow: $form-select-third-item-box-shadow-selected;
        }
    }

    .c-select__description {
        color: $form-select-third-description-color;
    }

    option {
        color: $form-select-third-option-color;
    }
}

// Single value select style
@mixin select-single-value {
    background: $form-select-background-single-value;
    border-color: $form-select-border-color-single-value;
    padding: $form-select-padding-single-value;
    cursor: default;
    pointer-events: none;
    text-align: inherit;
}

// Disabled select state
@mixin select-custom-disabled {
    &::after {
        @include svg-icon($form-select-custom-disabled-icon, $form-select-custom-disabled-icon-color, 100%, left, no-repeat, $usesvg: $form-select-custom-disabled-icon-usesvg);
        @include absolute-center;

        content: '';
        // Formula the square inradius in terms of the circumradius r = R/√2
        // R - icon-size, 100% of width
        // √2 = 1.414
        width: calc(100% / 1.414);
        height: calc(100% / 1.414);
    }
}

@mixin lora-form-select {
    .c-select {
        position: relative;

        &.m-hover {
            .c-select__placeholder {
                border-color: $form-select-border-color-hover;
                box-shadow: $form-select-box-shadow-hover;
                color: $form-select-color-hover;
                font-weight: $form-select-font-weight-hover;
            }
        }

        &.m-opened {
            .c-select__container::after,
            .c-select__placeholder::after {
                transform: $form-select-arrow-opened-transform;
            }

            .c-select__placeholder {
                border-color: $form-select-border-color-active;
            }
        }

        &.m-secondary {
            .c-select__field,
            .c-select__placeholder {
                @include select-secondary-control;
            }

            .c-select__container::after,
            .c-select__placeholder::after {
                #{$global-right}: $form-select-secondary-arrow-right;
                @include select-arrow($arrow-color: $form-select-secondary-arrow-color);
            }
        }

        &.m-third {
            .c-select__field,
            .c-select__placeholder,
            .c-select__panel {
                @include select-third-control;
            }

            &.m-active .c-select__label.m-float,
            &.m-focus .c-select__label.m-float {
                color: $form-select-third-float-label-color;

                &::before {
                    background-color: $form-select-third-float-label-background-color;
                }
            }

            .c-select__container,
            .c-select__placeholder {
                &::after {
                    @include select-arrow($arrow-color: $form-select-third-arrow-color);
                }
            }
        }

        &.m-float {
            .c-select__field {
                color: $form-select-float-empty-color;

                option {
                    color: $form-select-color;
                }
            }

            &.m-active,
            &.m-focus {
                .c-select__field {
                    color: $form-select-color;
                }
            }
        }

        &.m-width-auto {
            .c-select__container,
            .c-select__placeholder {
                flex-grow: 0;
            }
        }

        // stylelint-disable max-nesting-depth
        @each $size-name, $size-value in $form-select-size {
            @if ($size-name != 'normal') {
                &.m-#{$size-name} {
                    .c-select__field,
                    .c-select__placeholder {
                        height: #{$size-value};
                        line-height: #{$size-value};
                        font-size: map-get($form-select-font-sizes, $size-name);
                    }

                    .c-select__label.m-float {
                        height: #{$size-value};
                        line-height: #{$size-value};
                    }
                }
            }
        }

        &.m-rows-2 {
            .c-select__field,
            .c-select__placeholder {
                height: map-get($form-select-rows-2-size, normal);
                line-height: map-get($form-select-rows-2-line-height, normal);
            }

            .c-select__description {
                font-size: map-get($form-select-description-rows-2-font-sizes, normal);
            }

            @each $size-name, $size-value in $form-select-rows-2-size {
                @if ($size-name != 'normal') {
                    &.m-#{$size-name} {
                        .c-select__field,
                        .c-select__placeholder {
                            height: #{$size-value};
                            line-height: map-get($form-select-rows-2-line-height, $size-name);
                        }

                        .c-select__description {
                            font-size: map-get($form-select-description-rows-2-font-sizes, $size-name);
                        }
                    }
                }
            }
        }

        &.m-single-value {
            &.m-active.m-float .c-select__label {
                #{$global-left}: $form-select-single-value-label-float-left;
                padding: $form-select-single-value-label-float-padding;
                width: 100%;
                max-width: 100%;
                text-align: inherit;
                text-transform: $form-select-single-value-label-float-text-transform;

                &::before {
                    display: none;
                }
            }

            .c-select__field,
            .c-select__placeholder {
                @include select-single-value;
            }
        }
        // stylelint-enable max-nesting-depth
    }

    .c-select__container {
        @include select-element;
    }

    .c-select__field {
        @include select-control;
    }

    .c-select__description-wrapper {
        overflow: hidden;
        pointer-events: none;
        flex: $form-select-description-wrapper-flex;
    }

    .c-select__icon {
        position: relative;
        margin: $form-select-icon-margin;
        width: $form-select-icon-size;
        height: $form-select-icon-size;
        align-self: $form-select-icon-align-self;
        flex-shrink: 0;
        background-repeat: no-repeat !important; // stylelint-disable-line
        background-size: 100% !important; // stylelint-disable-line

        &:not(.m-square) {
            border-radius: $form-select-icon-border-radius;
        }
    }

    .c-select__placeholder {
        @include select-element;
        @include select-control($form-select-alt-padding);

        display: flex;
        align-items: center;

        &.m-disabled {
            .c-select__icon {
                @include select-custom-disabled;
            }
        }

        &[aria-expanded="true"] {
            border-color: $form-select-border-color-active;

            &::after {
                transform: $form-select-arrow-opened-transform;
            }
        }

        .c-select__text,
        .c-select__description {
            @include text-truncate;
        }
    }

    .c-select__panel {
        background: $form-select-panel-background;
        border: $form-select-border-width solid $form-select-panel-border-color;
        display: none;
        margin-bottom: -$form-select-border-width;
        margin-top: -$form-select-border-width;
        padding: $form-select-panel-padding;
        position: absolute;
        min-width: $form-select-panel-min-width;
        z-index: z(selectPanel);
    }

    .c-select__panel-list {
        list-style: none;
        padding: $form-select-panel-list-padding;
        margin: 0;
        max-height: $form-select-panel-list-max-height;
        overflow: auto;
        overscroll-behavior: contain; /* stylelint-disable-line */
    }

    .c-select__panel-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: $form-select-panel-item-padding;
        color: $form-select-panel-item-color;
        font: $form-select-panel-item-font;
        text-transform: $form-select-panel-item-text-transform;
        letter-spacing: $form-select-panel-item-letter-spacing;

        &:hover {
            background: $form-select-panel-item-background-hover;
            box-shadow: $form-select-panel-item-box-shadow-hover;
            color: $form-select-panel-item-color-hover;
            font: $form-select-panel-item-font-hover;

            @media (forced-colors: active) {
                border: 1px solid Highlight;
            }
        }

        &.m-selected {
            background: $form-select-panel-item-background-selected;
            box-shadow: $form-select-panel-item-box-shadow-selected;
            color: $form-select-panel-item-color-selected;
            font: $form-select-panel-item-font-selected;

            @media (forced-colors: active) {
                border: 1px solid Highlight;
            }
        }

        &.m-disabled {
            .c-select__icon {
                @include select-custom-disabled;
            }
        }
    }

    .c-select__text,
    .c-select__description {
        display: block;
        flex: $form-select-description-wrapper-flex;
    }

    .c-select__description {
        color: $form-select-description-color;
    }

    .c-select__list {
        margin: 0;
        list-style: none;
    }

    .c-select__label {
        font: $form-select-label-font;
        margin: $form-select-label-margin;

        &.m-float {
            @include lora-helper-form-label-float;

            margin: $form-select-label-float-margin;
        }
    }

    .c-select__badge {
        background: $form-select-badge-background;
        box-shadow: $form-select-badge-shadow;
        border: $form-select-badge-border;
        border-radius: $form-select-badge-border-radius;
        color: $form-select-badge-color;
        font: $form-select-badge-font;
        padding: $form-select-badge-padding;
        text-transform: $form-select-badge-text-transform;
    }

    .c-select__badge-image {
        display: block;
        width: $form-select-badge-image-size;
        height: $form-select-badge-image-size;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .c-select__field:focus + .c-select__label.m-float {
        @include lora-helper-form-label-float-focused;
    }

    .c-select__field:active + .c-select__label.m-float {
        @include lora-helper-form-label-float-focused;
    }

    .c-select__field:valid + .c-select__label.m-float {
        @include lora-helper-form-label-float-focused;
    }

    .c-select.m-active .c-select__label,
    .c-select.m-focus .c-select__label {
        &.m-float {
            @include lora-helper-form-label-float-focused;
        }
    }

    .c-select.m-active.m-opened,
    .c-select.m-focus.m-opened {
        .c-select__label.m-float {
            z-index: z(selectPanelLabel);
        }
    }

    .c-select.m-disabled {
        .c-select__label,
        .c-select__label.m-float {
            color: $form-select-label-color-disabled;
        }

        .c-select__container::after,
        .c-select__placeholder::after {
            @include select-arrow($arrow-color: $form-select-arrow-color-disabled);
        }
    }

    .c-select.m-loading {
        cursor: default;
        pointer-events: none;
        position: relative;

        &::before {
            background: $form-select-loading-overlay-background;
            content: '';
            height: 100%;
            opacity: $form-select-loading-overlay-opacity;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: z(pageLoader, icon);
        }

        &::after {
            animation: $form-select-loading-icon-animation;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: z(pageLoader, icon);

            @include svg-icon($form-select-loading-icon, $form-select-loading-icon-color, $form-select-loading-icon-size, center, no-repeat, $usesvg: $form-select-loading-icon-usesvg);
        }
    }

    .c-select__filter {
        position: relative;
        padding: $form-select-filter-padding;
        background: $form-select-filter-background;

        &.m-active {
            .c-select__filter-button {
                cursor: pointer;
                pointer-events: inherit;

                &::after {
                    @include svg-icon($form-select-filter-button-active-icon, $form-select-filter-button-active-icon-color, 100%, left, no-repeat, $usesvg: $form-select-filter-button-active-icon-usesvg);
                }
            }
        }
    }

    .c-select__filter-field {
        width: 100%;
        padding: $form-select-filter-field-padding;
        background: $form-select-filter-field-background;
        font: $form-select-filter-field-font;
    }

    .c-select__filter-result {
        padding: $form-select-filter-result-padding;
        font: $form-select-filter-result-font;
        color: $form-select-filter-result-color;
    }

    .c-select__filter-button {
        @include vertical-center;

        pointer-events: none;
        padding: $form-select-filter-button-padding;
        #{$global-right}: $form-select-filter-button-right;

        &::after {
            @include svg-icon($form-select-filter-button-icon, $form-select-filter-button-icon-color, 100%, left, no-repeat, $usesvg: $form-select-filter-button-icon-usesvg);

            content: '';
            display: block;
            width: $form-select-filter-button-icon-size;
            height: $form-select-filter-button-icon-size;
        }
    }
}
