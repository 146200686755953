// Accordion Item
$accordion-item-border-bottom-collapsed: rem-calc(1) solid color(border);
$accordion-item-collapsed-first-border-top: $accordion-item-border-bottom-collapsed;

// Accordion Title
$accordion-title-font: 500 #{rem-calc(12)} / rem-calc(14) $font-primary;
$accordion-title-text-transform: uppercase;

// Accordion Title Secondary
$accordion-title-secondary-collapsed-font: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$accordion-title-secondary-collapsed-font--large: $accordion-title-secondary-collapsed-font;
$accordion-title-text-transform: none;
$accordion-title-secondary-font-weight-expanded: 500;
$accordion-icon-width: rem-calc(10);

@import "@lora/05-components/accordion";