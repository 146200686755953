//stylelint-disable lora/variable-pattern

$h-ph-bg: #fff !default;
$h-ph-empty-bg: rgba($h-ph-bg, 0) !default;
$h-ph-item-bg: linear-gradient(to right, rgba($h-ph-bg, 0) 46%, rgba($h-ph-bg, 0.35) 50%, rgba($h-ph-bg, 0) 54%) 50% 50% !default;
$h-ph-color: #ced4da !default;
$h-ph-item-zindex: 1 !default;
$h-ph-cols: 12 !default;
$h-ph-cols-remove-odd: true !default;
$h-ph-gutter: 0 !default;
$h-ph-spacer: 0 !default;
$h-ph-avatar-border-radius: 50% !default;
$h-ph-animation-duration: 0.8s !default;

// Placeholder height settings
$placeholder-height-start: 10 !default;
$placeholder-height-range: 10 !default;
$placeholder-height-max: 60 !default;

@mixin lora-helper-placeholderloading {
    .h-ph-item {
        &,
        *,
        ::after,
        ::before {
            box-sizing: border-box;
        }

        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $h-ph-gutter;
        padding: $h-ph-gutter ($h-ph-gutter / 2) ($h-ph-gutter - $h-ph-spacer) ($h-ph-gutter / 2);
        overflow: hidden;
        direction: $global-text-direction;
        background-color: $h-ph-bg;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 50%;
            z-index: $h-ph-item-zindex;
            width: 500%;
            margin-left: -250%;
            background: $h-ph-item-bg;
            animation: hPhAnimation $h-ph-animation-duration linear infinite;
            content: " ";
            pointer-events: none;
        }

        > * {
            display: flex;
            flex: 1 1 auto;
            flex-flow: column;
            margin-bottom: $h-ph-spacer;
            padding-right: ($h-ph-gutter / 2);
            padding-left: ($h-ph-gutter / 2);
        }
    }

    .h-ph-row {
        display: flex;
        flex-wrap: wrap;
        margin-top: -($h-ph-spacer / 2);

        div {
            height: rem-calc($placeholder-height-start);
            margin-top: ($h-ph-spacer / 2);
            background-color: $h-ph-color;
        }

        // stylelint-disable max-nesting-depth
        @while $placeholder-height-start <= $placeholder-height-max {
            .h-ph-row-#{$placeholder-height-start} {
                height: rem-calc($placeholder-height-start);
            }

            @each $size in $breakpoint-classes {
                $range: if($size == 'large', null, only);

                @include breakpoint($size $range) {
                    .h-ph-row-#{$placeholder-height-start}-for-#{$size} {
                        height: rem-calc($placeholder-height-start);
                    }
                }
            }

            $placeholder-height-start: $placeholder-height-start + $placeholder-height-range;
        }
        // stylelint-enable max-nesting-depth

        .h-ph-content__empty {
            background-color: $h-ph-empty-bg;
        }
    }

    [class*="h-ph-col"] {
        direction: $global-text-direction;

        > * {
            + .h-ph-row {
                margin-top: 0;
            }

            //stylelint-disable selector-max-universal
            + * {
                margin-top: ($h-ph-spacer / 2);
            }
        }
    }

    .h-ph-avatar {
        position: relative;
        width: 100%;
        min-width: 60px;
        overflow: hidden;
        background-color: $h-ph-color;
        border-radius: $h-ph-avatar-border-radius;

        &::before {
            display: block;
            padding-top: 100%;
            content: " ";
        }
    }

    .h-ph-picture {
        width: 100%;
        height: 120px;
        background-color: $h-ph-color;
    }

    @keyframes hPhAnimation {
        0% {
            transform: translate3d(-30%, 0, 0);
        }

        100% {
            transform: translate3d(30%, 0, 0);
        }
    }

    @for $i from 1 through $h-ph-cols {
        @if $h-ph-cols-remove-odd and $i % 2 == 0 {
            @include h-ph-make-col($i, $h-ph-cols);
        }
        @else if not $h-ph-cols-remove-odd {
            @include h-ph-make-col($i, $h-ph-cols);
        }
    }
}

@mixin h-ph-make-col($size, $columns) {
    .h-ph-col--#{$size} {
        flex: 0 0 percentage($size / $columns);
    }
}
