$header-top-nav-item-font-size--large: rem-calc(10);
$header-logo-padding--large: rem-calc(20 0 25);
$header-logo-max-width--large: rem-calc(275);
$header-bottom-background--large: transparent;
$header-top-nav-right-padding--large: rem-calc(40 0 0);
$header-top-nav-link-text-transform: uppercase;
$header-top-nav-link-letter-spacing: rem-calc(1);
$header-top-nav-item-separator-color: transparent;
$header-search-button-font: 700 #{rem-calc(14)} / #{rem-calc(16.8)} $font-tertiary;
$header-search-button-padding--large: rem-calc(16);
$header-search-button-active-padding--large: $header-search-button-padding--large;
$header-search-button-label-margin: rem-calc(0 0 0 8);
$header-search-button-active-padding--large: rem-calc(0 30);
$header-search-button-border--large: rem-calc(1) solid color(primary);
$header-search-button-border-hover--large: $header-search-button-border--large;
$header-sticked-background: color(secondary);
$header-hamburger-navigation-is-fullwidth: true;
$header-sticked-top-nav-item-margin--large: rem-calc(-3 0 0 25);
$header-search-button-icon-width: rem-calc(20);
$header-search-button-icon-height: rem-calc(20);
$header-search-button-icon-active-height: $header-search-button-icon-height;
$header-search-button-icon-active-width: $header-search-button-icon-width;
$header-search-button-icon-height--large: rem-calc(16);
$header-search-button-icon-width--large: rem-calc(16);
$header-user-link-account-sticky-login-icon: user;
$header-user-link-account-sticky-login-icon-width: rem-calc(29);
$header-user-link-account-sticky-login-icon-height: rem-calc(29);
$header-sticked-minicart-link-margin--large: rem-calc(5 10 5 0);
$header-top-nav-item-hover-color: color(primary-active);
$header-border-bottom: rem-calc(1) solid color(border);
$header-is-full-width: true;
$header-top-nav-item-account-display--large: block;
$header-top-nav-item-color: color(text-secondary);
$header-search-button-active-border--large: rem-calc(1) solid color(primary);

// Loyalty
$header-top-nav-loyalty-icon-display: block;
$header-top-nav-loyalty-text-display: none;

// Context components
$header-top-nav-storeslink-padding: rem-calc(14 7 14 24);

//Search Icon
$header-search-button-padding: rem-calc(14 0 14);

// Search button
$header-search-button-color--large: color(secondary);
$header-search-button-background--large: color(primary);
$header-search-button-border-width--large: rem-calc(1);

/// Search button hover
$header-search-button-color-hover--large: $header-search-button-color--large;
$header-search-button-background-hover--large: $header-search-button-background--large;

/// Search button icon
$header-search-button-icon-color--large: color(secondary);
$header-search-button-icon-color-hover--large: color(secondary);

$header-hamburger-border--large: none;

@import "@lora/04-layout/header";