/* stylelint-disable selector-max-type, selector-no-qualifying-type */

////
/// @group forms
////

$form-text-field-sizes: (
    "small": $global-input-primary-height-small,
    "normal": $global-input-primary-height,
    "large": $global-input-primary-height-large
);

$form-text-field-font-sizes: (
    "small": rem-calc(11),
    "normal": rem-calc(14),
    "large": rem-calc(14)
);
/// Text inputs height
/// @type Number
$form-text-field-height: map-get($form-text-field-sizes, 'normal') !default;

/// Text inputs line-height
/// @type Number
$form-text-field-line-height: normal !default;

/// Padding of text inputs
/// @type Number
$form-text-field-padding: rem-calc(0 20) !default;

/// Font color of text inputs.
/// @type Color
$form-text-field-color: color(text) !default;

/// Font color of text inputs when hovered.
/// @type Color
$form-text-field-color-hover: color(primary-active) !default;

/// Font family of text inputs.
/// @type Font
$form-text-field-font-family: $font-primary !default;

/// Font size of text inputs.
/// @type Number
$form-text-field-font-size: map-get($form-text-field-font-sizes, 'normal') !default;

/// Font weight of text inputs.
/// @type Keyword
$form-text-field-font-weight: $global-weight-normal !default;

/// Font of text inputs.
/// @type Font
$form-text-field-font: $form-text-field-font-weight #{$form-text-field-font-size} / $form-text-field-line-height $form-text-field-font-family !default;

/// Letter spacing of text inputs.
/// @type Number
$form-text-field-letter-spacing: null !default;

/// Font weight of text inputs when hovered
/// @type Keyword
$form-text-field-font-weight-hover: $form-text-field-font-weight !default;

/// Background color of text inputs.
/// @type Color
$form-text-field-background: color(light) !default;

/// Border around text inputs.
/// @type Border
$form-text-field-border: 1px solid color(dark) !default;

/// Border around hovered text inputs.
/// @type Color
$form-text-field-border-hover: 1px solid color(dark) !default;

/// Border around focused text inputs.
/// @type Color
$form-text-field-border-focus: 1px solid color(dark) !default;

/// Box shadow inside text inputs when not focused.
/// @type Shadow
$form-text-field-box-shadow: null !default;

/// Box shadow outside text inputs when hovered.
/// @type Shadow
$form-text-field-box-shadow-hover: rem-calc(0 0 0 1) color(dark) !default;

/// Box shadow outside text inputs when focused.
/// @type Shadow
$form-text-field-box-shadow-focus: rem-calc(0 0 0 1) color(dark) !default;

/// Font color of textarea counter.
/// @type Color
$form-text-field-counter-color: color(text-secondary) !default;

/// Font of textarea counter.
/// @type String
$form-text-field-counter-font: null !default;

/// Font size of textarea counter.
/// @type Number
$form-text-field-counter-font-size: rem-calc(12) !default;

/// Padding of textarea counter.
/// @type Number
$form-text-field-counter-padding: rem-calc(5 0) !default;

/// Letter spacing of textarea counter.
/// @type Number
$form-text-field-counter-letter-spacing: null !default;

/// Font color of placeholder text within text inputs.
/// @type Color
$form-text-field-placeholder-color: color(text-secondary) !default;

/// Background color of disabled text inputs.
/// @type Color
$form-text-field-background-disabled: color(light) !default;

/// Border color of disabled text inputs.
/// @type Color
$form-text-field-border-disabled: color(disabled) !default;

/// Border color of disabled text inputs.
/// @type Color
$form-text-field-disabled-label-color: color(disabled) !default;

/// Font color of disabled text inputs.
/// @type Color
$form-text-field-color-disabled: color(disabled) !default;
$form-text-field-box-shadow-disabled: none !default;

/// Cursor to use when hovering over a disabled text input.
/// @type Cursor
$form-text-field-cursor-disabled: not-allowed !default;

/// Properties to transition on text inputs.
/// @type Transition
$form-text-field-transition: box-shadow 0.2s, border-color 0.2s ease-in-out !default;

/// Enables the up/down buttons that Chrome and Firefox add to `<input type='number'>` elements.
/// @type Boolean
$form-text-field-number-spinners: false !default;

/// Radius for text inputs.
/// @type Border
$form-text-field-radius: $form-radius !default;

/// Padding of text area
/// @type Number
$form-text-field-textarea-padding: rem-calc(10 20) !default;

/// Min height for text area
/// @type Number
$form-text-field-textarea-min-height: rem-calc(150) !default;

/// Padding for inner field
/// @type Number
$form-text-field-inner-padding: rem-calc(7 0) !default;

/// Padding of text inputs with float counter
/// @type Number
$form-text-field-counter-float-padding: rem-calc(0 50 0 20) !default;
$form-text-field-textarea-counter-float-padding: rem-calc(10 50 10 20) !default;
$form-text-field-counter-float-text-padding: rem-calc(0 10 0 0) !default;
$form-text-field-counter-float-text-height: map-get($form-text-field-sizes, 'normal') !default;
$form-text-field-counter-float-text-line-height: $form-text-field-counter-float-text-height !default;

/// Password visibility button
$form-text-field-password-padding-side: rem-calc(40) !default;
$form-text-field-password-button-padding: rem-calc(0 10) !default;
$form-text-field-password-button-icon-color: color(text) !default;
$form-text-field-password-button-icon: 'psswd_hidden' !default;
$form-text-field-password-button-visible-icon: 'psswd_visible' !default;
$form-text-field-password-button-icon-width: rem-calc(18) !default;
$form-text-field-password-button-icon-height: $global-input-primary-height !default;
$form-text-field-password-button-icon-usesvg: true !default;
$form-text-field-password-button-visible-icon-usesvg: true !default;

/// Text field valid icon
$form-text-field-valid-padding-side: rem-calc(30) !default;
$form-text-field-valid-after-icon-position-side: rem-calc(10) !default;
$form-text-field-valid-after-icon-color: color(success) !default;
$form-text-field-valid-after-icon: 'success' !default;
$form-text-field-valid-after-icon-width: rem-calc(15) !default;
$form-text-field-valid-after-icon-height: $global-input-primary-height !default;
$form-text-field-valid-after-icon-usesvg: true !default;

/// Search visibility button
$form-text-field-search-padding-side: rem-calc(35) !default;
$form-text-field-search-button-padding: rem-calc(0 10) !default;
$form-text-field-search-button-icon-color: color(text) !default;
$form-text-field-search-button-icon: 'search' !default;
$form-text-field-search-button-icon-width: rem-calc(15) !default;
$form-text-field-search-button-icon-height: $global-input-primary-height !default;
$form-text-field-search-button-icon-usesvg: true !default;

/// Clear visibility button
$form-text-field-clear-padding-side: rem-calc(35) !default;
$form-text-field-clear-button-padding: rem-calc(0 10) !default;
$form-text-field-clear-button-icon-color: color(text) !default;
$form-text-field-clear-button-icon: 'cross' !default;
$form-text-field-clear-button-icon-width: rem-calc(15) !default;
$form-text-field-clear-button-icon-height: $global-input-primary-height !default;
$form-text-field-clear-button-icon-usesvg: true !default;

// Requirements item
$form-text-field-requirements-item-font: #{rem-calc(12)} / 1 $font-primary !default;
$form-text-field-requirements-item-color: color(text) !default;
$form-text-field-requirements-item-margin: rem-calc(0 0 10) !default;
/// Requirements icon
$form-text-field-requirements-item-icon: 'success' !default;
$form-text-field-requirements-item-icon-color: color(disabled) !default;
$form-text-field-requirements-item-icon-size: rem-calc(12) !default;
$form-text-field-requirements-item-icon-margin: rem-calc(0 10 0 0) !default;
$form-text-field-requirements-item-icon-usesvg: true !default;
/// On success
$form-text-field-requirements-item-success-color: color(text) !default;
$form-text-field-requirements-item-success-icon: $form-text-field-requirements-item-icon !default;
$form-text-field-requirements-item-success-icon-color: color(success) !default;
$form-text-field-requirements-item-success-icon-usesvg: true !default;
/// On error
$form-text-field-requirements-item-error-color: color(alert) !default;
$form-text-field-requirements-item-error-icon: 'error' !default;
$form-text-field-requirements-item-error-icon-color: color(alert) !default;
$form-text-field-requirements-item-error-icon-usesvg: true !default;
/// On validate error
$form-text-field-requirements-on-validate-item-error-color: color(disabled) !default;
$form-text-field-requirements-on-validate-item-error-icon: 'success' !default;
$form-text-field-requirements-on-validate-item-error-icon-color: color(disabled) !default;
$form-text-field-requirements-on-validate-item-error-icon-usesvg: true !default;

@mixin lora-helper-form-element {
    background: $form-text-field-background;
    border: $form-text-field-border;
    border-radius: $form-text-field-radius;
    box-shadow: $form-text-field-box-shadow;
    box-sizing: border-box;
    color: $form-text-field-color;
    display: block;
    width: 100%;
    height: $form-text-field-height;
    font: $form-text-field-font;
    padding: $form-text-field-padding;
    letter-spacing: $form-text-field-letter-spacing;

    @if has-value($form-text-field-transition) {
        transition: $form-text-field-transition;
    }

    // Hover state
    &:hover {
        border: $form-text-field-border-hover;
        box-shadow: $form-text-field-box-shadow-hover;
        color: $form-text-field-color-hover;
        font-weight: $form-text-field-font-weight-hover;
    }

    // Focus state
    &:focus {
        border: $form-text-field-border-focus;
        box-shadow: $form-text-field-box-shadow-focus;

        @if has-value($form-text-field-transition) {
            transition: $form-text-field-transition;
        }
    }
}

@mixin lora-helper-form-textarea-counter {
    color: $form-text-field-counter-color;
    display: block;
    font: $form-text-field-counter-font;
    font-size: $form-text-field-counter-font-size;
    padding: $form-text-field-counter-padding;
    letter-spacing: $form-text-field-counter-letter-spacing;
    width: 100%;
    text-align: #{$global-right};
}

@mixin lora-helper-form-text-counter-float {
    .c-text-field__input-text,
    #{text-inputs()} {
        padding: $form-text-field-counter-float-padding;
    }

    // Text areas
    textarea {
        padding: $form-text-field-textarea-counter-float-padding;
    }

    .c-text-field__counter {
        position: absolute;
        top: 0;
        #{$global-right}: 0;
        width: auto;
        padding: $form-text-field-counter-float-text-padding;
        height: $form-text-field-counter-float-text-height;
        line-height: $form-text-field-counter-float-text-line-height;
    }
}

@mixin lora-helper-form-text-password-button {
    position: absolute;
    top: 0;
    #{$global-right}: 0;
    padding: $form-text-field-password-button-padding;
    cursor: pointer;

    &::before {
        @include svg-icon($form-text-field-password-button-icon, $form-text-field-password-button-icon-color, contain, center center, no-repeat, $usesvg: $form-text-field-password-button-icon-usesvg);

        content: "";
        display: block;
        width: $form-text-field-password-button-icon-width;
        height: $form-text-field-password-button-icon-height;
    }

    &.m-visible {
        &::before {
            @include svg-icon($form-text-field-password-button-visible-icon, $form-text-field-password-button-icon-color, contain, center center, no-repeat, $usesvg: $form-text-field-password-button-visible-icon-usesvg);
        }
    }
}

@mixin lora-helper-form-text-requirements-item {
    display: flex;
    flex-wrap: wrap;
    font: $form-text-field-requirements-item-font;
    color: $form-text-field-requirements-item-color;
    margin: $form-text-field-requirements-item-margin;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        @include svg-icon($form-text-field-requirements-item-icon, $form-text-field-requirements-item-icon-color, 100%, left, no-repeat, $usesvg: $form-text-field-requirements-item-icon-usesvg);

        content: "";
        display: block;
        flex: 0 0 auto;
        margin: $form-text-field-requirements-item-icon-margin;
        width: $form-text-field-requirements-item-icon-size;
        height: $form-text-field-requirements-item-icon-size;
    }

    &.m-success {
        color: $form-text-field-requirements-item-success-color;

        &::before {
            @if ($form-text-field-requirements-item-icon != $form-text-field-requirements-item-success-icon) {
                @include svg-icon($form-text-field-requirements-item-success-icon, $form-text-field-requirements-item-success-icon-color, contain, center center, no-repeat, $usesvg: $form-text-field-requirements-item-success-icon-usesvg);
            } @else {
                @include svg-icon($form-text-field-requirements-item-success-icon, $form-text-field-requirements-item-success-icon-color, $color-change: true, $usesvg: $form-text-field-requirements-item-success-icon-usesvg);
            }
        }
    }

    &.m-error {
        color: $form-text-field-requirements-item-error-color;

        &::before {
            @include svg-icon($form-text-field-requirements-item-error-icon, $form-text-field-requirements-item-error-icon-color, contain, center center, no-repeat, $usesvg: $form-text-field-requirements-item-error-icon-usesvg);
        }
    }

    .c-text-field__requirements-list.m-on-validate &.m-error {
        color: $form-text-field-requirements-on-validate-item-error-color;

        &::before {
            @include svg-icon($form-text-field-requirements-on-validate-item-error-icon, $form-text-field-requirements-on-validate-item-error-icon-color, contain, center center, no-repeat, $usesvg: $form-text-field-requirements-on-validate-item-error-icon-usesvg);
        }
    }
}

@mixin lora-helper-form-text-valid-icon {
    &::after {
        @include svg-icon($form-text-field-valid-after-icon, $form-text-field-valid-after-icon-color, contain, center center, no-repeat, $usesvg: $form-text-field-valid-after-icon-usesvg);

        content: "";
        width: $form-text-field-valid-after-icon-width;
        height: $form-text-field-valid-after-icon-height;
        position: absolute;
        top: 0;
        #{$global-right}: $form-text-field-valid-after-icon-position-side;
    }
}

@mixin lora-helper-form-text-search-button {
    position: absolute;
    top: 0;
    #{$global-left}: 0;
    padding: $form-text-field-search-button-padding;
    cursor: pointer;

    &::before {
        @include svg-icon($form-text-field-search-button-icon, $form-text-field-search-button-icon-color, contain, center center, no-repeat, $usesvg: $form-text-field-search-button-icon-usesvg);

        content: "";
        display: block;
        width: $form-text-field-search-button-icon-width;
        height: $form-text-field-search-button-icon-height;
    }
}

@mixin lora-helper-form-text-clear-button {
    position: absolute;
    top: 0;
    #{$global-right}: 0;
    padding: $form-text-field-clear-button-padding;
    cursor: pointer;

    &::before {
        @include svg-icon($form-text-field-clear-button-icon, $form-text-field-clear-button-icon-color, contain, center center, no-repeat, $usesvg: $form-text-field-clear-button-icon-usesvg);

        content: "";
        display: block;
        width: $form-text-field-clear-button-icon-width;
        height: $form-text-field-clear-button-icon-height;
    }
}

@function text-inputs($types: (), $modifier: '') {
    $return: ();
    /* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
    $all-types:
        text
        password
        date
        datetime
        datetime-local
        month
        week
        email
        number
        search
        tel
        time
        url
        color;

    @if not has-value($types) {
        $types: $all-types;
    }

    @each $type in $types {
        $return: append($return, unquote('[type=\'#{$type}\']#{$modifier}'), comma);
    }

    @return $return;
}

@mixin lora-form-text-field {
    // Text inputs
    .c-text-field__input-text,
    #{text-inputs()},
    textarea {
        @include lora-helper-form-element;

        appearance: none;
    }

    // Text areas
    textarea {
        max-width: 100%;
        resize: vertical;
        min-height: $form-text-field-textarea-min-height;
        padding: $form-text-field-textarea-padding;

        &[rows] {
            height: auto;
        }
    }

    input,
    textarea {
        // Placeholder text
        &::placeholder {
            color: $form-text-field-placeholder-color;
        }

        // Disabled/readonly state
        &:disabled,
        &:disabled:hover,
        &[readonly] {
            background-color: $form-text-field-background-disabled;
            border-color: $form-text-field-border-disabled;
            cursor: $form-text-field-cursor-disabled;
            color: $form-text-field-color-disabled;
            box-shadow: $form-text-field-box-shadow-disabled;
        }
    }

    // Reset Normalize setting content-box to search elements
    // scss-lint:disable QualifyingElement
    input[type='search'] {
        box-sizing: border-box;
    }

    // scss-lint:disable QualifyingElement
    input[type='date'] {
        line-height: $form-text-field-height;

        &::-webkit-date-and-time-value {
            text-align: $global-left;
        }
    }

    // Number input styles
    [type='number'] {
        @if not $form-text-field-number-spinners {
            appearance: textfield;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                appearance: none;
                margin: 0;
            }
        }
    }

    .c-text-field {
        position: relative;

        @each $size-name, $size-value in $form-text-field-sizes {
            &.m-#{$size-name} {
                .c-text-field__input-text,
                #{text-inputs()} {
                    height: #{$size-value};
                    font-size: map-get($form-text-field-font-sizes, $size-name);
                }

                .c-text-field__label.m-float {
                    height: #{$size-value};
                    line-height: #{$size-value};
                }

                &.m-float-count .c-text-field__counter {
                    height: #{$size-value};
                    line-height: #{$size-value};
                }

                &.m-valid::after {
                    height: #{$size-value};
                }

                .c-text-field__password-button::before {
                    height: #{$size-value};
                }
            }
        }
    }

    .c-text-field__counter {
        @include lora-helper-form-textarea-counter;
    }

    .c-text-field.m-float-count {
        @include lora-helper-form-text-counter-float;
    }

    .c-text-field.m-float:not(.m-focus):not(.m-active) {
        .c-text-field__input::placeholder {
            color: transparent; //stylelint-disable-line
        }
    }

    .c-text-field__label {
        &.m-float {
            @include lora-helper-form-label-float;
        }
    }

    .c-text-field__input:focus + .c-text-field__label.m-float,
    .c-text-field__textarea:focus + .c-text-field__label.m-float {
        @include lora-helper-form-label-float-focused;
    }

    .c-text-field__input:active + .c-text-field__label.m-float,
    .c-text-field__textarea:active + .c-text-field__label.m-float {
        @include lora-helper-form-label-float-focused;
    }

    .c-text-field__input:not(:placeholder-shown) + .c-text-field__label.m-float,
    .c-text-field__textarea:not(:placeholder-shown) + .c-text-field__label.m-float {
        @include lora-helper-form-label-float-focused;
    }

    .c-text-field.m-focus .c-text-field__label,
    .c-text-field.m-active .c-text-field__label,
    .c-text-field.m-date .c-text-field__label {
        &.m-float {
            @include lora-helper-form-label-float-focused;
        }
    }

    // Fix for webkit/chrome autofill - rule must be separate because it breaks firefox
    #{text-inputs($modifier: ':-webkit-autofill + .c-text-field__label')} {
        @include lora-helper-form-label-float-focused;
    }

    .c-text-field.m-disabled .c-text-field__label {
        color: $form-text-field-disabled-label-color;
    }

    .c-text-field.m-valid:not(.m-password):not(.m-suggestion) {
        @include lora-helper-form-text-valid-icon;

        .c-text-field__input-text,
        #{text-inputs()},
        textarea {
            padding-#{$global-right}: $form-text-field-valid-padding-side;
        }
    }

    .c-text-field.m-password .c-text-field__input {
        padding-#{$global-right}: $form-text-field-password-padding-side;
    }

    .c-text-field__password-button {
        @include lora-helper-form-text-password-button;
    }

    .c-text-field.m-suggestion {
        .c-text-field__input {
            padding-#{$global-left}: $form-text-field-search-padding-side;
            padding-#{$global-right}: $form-text-field-clear-padding-side;
        }

        &:not(.m-focus):not(.m-active) .c-text-field__label.m-float {
            #{$global-left}: $form-text-field-search-padding-side;
        }

        .c-text-field__label {
            max-width: calc(100% - #{$form-text-field-search-padding-side} - #{$form-text-field-clear-padding-side});
        }
    }

    .c-text-field__search-button {
        @include lora-helper-form-text-search-button;
    }

    .c-text-field__clear-button {
        @include lora-helper-form-text-clear-button;
    }

    .c-text-field__input-inner {
        padding: $form-text-field-inner-padding;
    }

    .c-text-field__requirements-item {
        @include lora-helper-form-text-requirements-item;
    }

    .c-text-field__requirements-content {
        flex: 1;
    }
}
