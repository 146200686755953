/*------------------------------------*\
        #FOOTER COPYRIGHT
\*------------------------------------*/

// Deprecated components
@import "../../100-deprecated/footer/footer-copyright";

$footer-copyright-color: color(light) !default;
$footer-copyright-font: #{rem-calc(12)} / 1.6 $font-primary !default;
$footer-copyright-margin: rem-calc(15 auto) !default;
$footer-copyright-columns--large: 8 of 12 !default;
$footer-copyright-padding: $footer-copyright-padding--medium-down !default;
$footer-copyright-text-align: $footer-copyright-text-align--medium-down !default;
$footer-copyright-padding--large: null !default;
$footer-copyright-text-align--large: null !default;
$footer-copyright-letter-spacing: null !default;
$footer-copyright-footer-secondary-margin: rem-calc(16 0 8) !default;

@mixin lora-components-footer-copyright {
    .l-footer__copyright {
        color: $footer-copyright-color;
        font: $footer-copyright-font;
        margin: $footer-copyright-margin;
        padding: $footer-copyright-padding;
        text-align: $footer-copyright-text-align;
        width: $footer-copyright-width--medium-down;
        letter-spacing: $footer-copyright-letter-spacing;

        @include breakpoint(large) {
            @include cell($footer-copyright-columns--large);

            margin-#{$global-right}: auto;
            padding: $footer-copyright-padding--large;
            text-align: $footer-copyright-text-align--large;
        }

        &.m-secondary {
            margin: $footer-copyright-footer-secondary-margin;
        }
    }
}
