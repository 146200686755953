/*------------------------------------*\
        #FOOTER CONTACTS
\*------------------------------------*/

$footer-contacts-padding: rem-calc(10 0 15) !default;
$footer-contacts-padding--large: rem-calc(0 0 20) !default;
$footer-contacts-text-align--medium-down: center !default;
$footer-contacts-display: block !default;
$footer-contacts-border-bottom: rem-calc(1) solid color(light) !default;
$footer-contacts-border-bottom--large: none !default;
$footer-contacts-title-margin: rem-calc(0 0 10) !default;
$footer-contacts-title-margin--large: rem-calc(0 0 5) !default;
$footer-contacts-title-text-transform: none !default;
$footer-contacts-title-text-transform--large: none !default;
$footer-contacts-title-color: color(light) !default;
$footer-contacts-title-color--large: null !default;
$footer-contacts-title-font: #{rem-calc(18)} / 1.25 $font-primary !default;
$footer-contacts-title-font--large: bold #{rem-calc(14)} / 1.25 $font-primary !default;
$footer-contacts-title-letter-spacing: null !default;
$footer-contacts-title-letter-spacing--large: null !default;
$footer-contacts-description-color: color(light) !default;
$footer-contacts-description-font: #{rem-calc(12)} / 1.25 $font-primary !default;
$footer-contacts-description-flex-direction--medium-down: row !default;
$footer-contacts-description-letter-spacing: null !default;
$footer-contacts-description-margin: 0 !default;
$footer-contacts-link-simple-color: color(light) !default;
$footer-contacts-link-simple-hover-color: color(light) !default;
$footer-contacts-link-simple-font: #{rem-calc(12)} / 1.25 $font-primary !default;
$footer-contacts-link-simple-text-decoration: none !default;
$footer-contacts-link-simple-hover-text-decoration: $footer-contacts-link-simple-text-decoration !default;
$footer-contacts-link-background: color(primary) !default;
$footer-contacts-link-hover-background: color(primary-active) !default;
$footer-contacts-link-color: color(light) !default;
$footer-contacts-link-hover-color: $footer-contacts-link-color !default;
$footer-contacts-link-font: #{rem-calc(12)} / 1.25 $font-primary !default;
$footer-contacts-link-flex-basis--medium-down: 0 !default;
$footer-contacts-link-justify-content: center !default;
$footer-contacts-link-padding: rem-calc(0 10) !default;
$footer-contacts-link-margin: rem-calc(0 5) !default;
$footer-contacts-link-radius: rem-calc(3) !default;
$footer-contacts-link-border: null !default;
$footer-contacts-link-height: rem-calc(35) !default;
$footer-contacts-link-text-transform: none !default;
$footer-contacts-link-text-decoration: none !default;
$footer-contacts-link-hover-text-decoration: $footer-contacts-link-text-decoration !default;
$footer-contacts-link-store-icon: pin !default;
$footer-contacts-link-store-icon-height: rem-calc(17) !default;
$footer-contacts-link-store-icon-width: rem-calc(12) !default;
$footer-contacts-link-store-icon-margin: rem-calc(0 10 0 0) !default;
$footer-contacts-link-store-icon-usesvg: true !default;
$footer-contacts-link-phone-icon: phone !default;
$footer-contacts-link-phone-icon-height: rem-calc(19) !default;
$footer-contacts-link-phone-icon-width: rem-calc(19) !default;
$footer-contacts-link-phone-icon-margin: rem-calc(0 5 0 0) !default;
$footer-contacts-link-phone-icon-usesvg: true !default;
$footer-contacts-link-email-icon: email !default;
$footer-contacts-link-email-icon-height: rem-calc(19) !default;
$footer-contacts-link-email-icon-width: rem-calc(19) !default;
$footer-contacts-link-email-icon-margin: rem-calc(0 5 0 0) !default;
$footer-contacts-link-email-icon-usesvg: true !default;
$footer-contacts-link-first-child-margin: rem-calc(0 5 0 0) !default;
$footer-contacts-link-last-child-margin: rem-calc(0 0 0 5) !default;
$footer-contacts-description-link-color: color(light) !default;
$footer-contacts-description-link-hover-color: color(primary-active) !default;

@mixin lora-components-footer-contacts {
    .l-footer__contacts {
        display: $footer-contacts-display;
        border-bottom: $footer-contacts-border-bottom;
        padding: $footer-contacts-padding;

        @include breakpoint(medium down) {
            text-align: $footer-contacts-text-align--medium-down;
        }
        @include breakpoint(large) {
            border-bottom: $footer-contacts-border-bottom--large;
            padding: $footer-contacts-padding--large;
        }
    }

    .l-footer__contacts-title {
        display: block;

        @include text-style(
            $font: (
                small: $footer-contacts-title-font,
                large: $footer-contacts-title-font--large
            ),
            $color: (
                small: $footer-contacts-title-color,
                large: $footer-contacts-title-color--large
            ),
            $margin: (
                small: $footer-contacts-title-margin,
                large: $footer-contacts-title-margin--large
            ),
            $letter-spacing: (
                small: $footer-contacts-title-letter-spacing,
                large: $footer-contacts-title-letter-spacing--large
            ),
            $text-transform: (
                small: $footer-contacts-title-text-transform,
                large: $footer-contacts-title-text-transform--large
            )
        );
    }

    .l-footer__contacts-description {
        color: $footer-contacts-description-color;
        font: $footer-contacts-description-font;
        letter-spacing: $footer-contacts-description-letter-spacing;
        margin: $footer-contacts-description-margin;

        @include breakpoint(medium down) {
            display: flex;
            flex-flow: $footer-contacts-description-flex-direction--medium-down;
        }

        a {
            color: $footer-contacts-description-link-color;

            &:hover {
                color: $footer-contacts-description-link-hover-color;
            }
        }
    }

    .l-footer__contacts-link-simple {
        color: $footer-contacts-link-simple-color;
        font: $footer-contacts-link-simple-font;
        text-decoration: $footer-contacts-link-simple-text-decoration;

        &:hover {
            color: $footer-contacts-link-simple-hover-color;
            text-decoration: $footer-contacts-link-simple-hover-text-decoration;
        }
    }

    .l-footer__contacts-link {
        align-items: center;
        background: $footer-contacts-link-background;
        border-radius: $footer-contacts-link-radius;
        border: $footer-contacts-link-border;
        color: $footer-contacts-link-color;
        display: flex;
        font: $footer-contacts-link-font;
        height: $footer-contacts-link-height;
        justify-content: $footer-contacts-link-justify-content;
        margin: $footer-contacts-link-margin;
        padding: $footer-contacts-link-padding;
        text-decoration: $footer-contacts-link-text-decoration;
        text-transform: $footer-contacts-link-text-transform;

        @include breakpoint(medium down) {
            flex-basis: $footer-contacts-link-flex-basis--medium-down;
            flex-grow: 1;
        }

        &:hover {
            background: $footer-contacts-link-hover-background;
            color: $footer-contacts-link-hover-color;
            text-decoration: $footer-contacts-link-hover-text-decoration;
        }

        &:first-child {
            margin: $footer-contacts-link-first-child-margin;
        }

        &:last-child {
            margin: $footer-contacts-link-last-child-margin;
        }

        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
        }

        &.m-store::before {
            @include svg-icon($footer-contacts-link-store-icon, $footer-contacts-link-color, 100%, left, no-repeat, $usesvg: $footer-contacts-link-store-icon-usesvg);

            height: $footer-contacts-link-store-icon-height;
            margin: $footer-contacts-link-store-icon-margin;
            width: $footer-contacts-link-store-icon-width;
        }

        &.m-phone::before {
            @include svg-icon($footer-contacts-link-phone-icon, $footer-contacts-link-color, 100%, left, no-repeat, $usesvg: $footer-contacts-link-phone-icon-usesvg);

            height: $footer-contacts-link-phone-icon-height;
            margin: $footer-contacts-link-phone-icon-margin;
            width: $footer-contacts-link-phone-icon-width;
        }

        &.m-email::before {
            @include svg-icon($footer-contacts-link-email-icon, $footer-contacts-link-color, 100%, left, no-repeat, $usesvg: $footer-contacts-link-email-icon-usesvg);

            height: $footer-contacts-link-email-icon-height;
            margin: $footer-contacts-link-email-icon-margin;
            width: $footer-contacts-link-email-icon-width;
        }
    }
}
