/*
---
#### Back top

Button that allows the user to scroll back to top without doing it manually
*/
$back-top-background: color(primary) !default;
$back-top-background-hover: color(primary-active) !default;
$back-top-bottom: rem-calc(90) !default;
$back-top-bottom--large: rem-calc(110) !default;
$back-top-border-radius: 50% !default;
$back-top-border-border: 1px solid color(light) !default;
$back-top-hover-border: null !default;
$back-top-box-shadow: rem-calc(0 2 4 0) rgba(color(light), 0.2) !default;
$back-top-right: rem-calc(10) !default;
$back-top-right--large: rem-calc(30) !default;
$back-top-width: rem-calc(24) !default;
$back-top-width--large: rem-calc(40) !default;
$back-top-height: rem-calc(24) !default;
$back-top-height--large: rem-calc(40) !default;
$back-top-padding: 0 !default;
$back-top-padding--large: null !default;
$back-top-color: color(light) !default;
$back-top-font: 0 / 1 $font-primary !default;
$back-top-text-transform: uppercase !default;
$back-top-text-align: center !default;
$back-top-transition: opacity, background 0.5s !default;
$back-top-icon: back-top !default;
$back-top-icon-color: color(light) !default;
$back-top-icon-hover-color: $back-top-icon-color !default;
$back-top-icon-height: rem-calc(16) !default;
$back-top-icon-width: rem-calc(16) !default;
$back-top-icon-height--large: rem-calc(24) !default;
$back-top-icon-width--large: rem-calc(24) !default;
$back-top-icon-usesvg: true !default;
$back-top-box-shadow: null !default;
$back-top-visibility: (
    small: true,
    medium: true,
    large: true
) !default;

@mixin lora-components-back-top {
    .c-back-top {
        background: $back-top-background;
        color: $back-top-color;
        cursor: pointer;
        font: $back-top-font;
        height: $back-top-height;
        opacity: 0;
        padding: $back-top-padding;
        border: $back-top-border-border;
        border-radius: $back-top-border-radius;
        pointer-events: none;
        position: fixed;
        bottom: $back-top-bottom;
        #{$global-right}: $back-top-right;
        text-align: $back-top-text-align;
        text-transform: $back-top-text-transform;
        transition: $back-top-transition;
        width: $back-top-width;
        z-index: z(backTop);
        box-shadow: $back-top-box-shadow;

        @include breakpoint(large) {
            padding: $back-top-padding--large;
            #{$global-right}: $back-top-right--large;
            bottom: $back-top-bottom--large;
            width: $back-top-width--large;
            height: $back-top-height--large;
        }

        &:hover {
            background: $back-top-background-hover;
            border: $back-top-hover-border;

            &::after {
                @include svg-icon($back-top-icon, $back-top-icon-hover-color, 100%, center, no-repeat, $color-change: true, $usesvg: $back-top-icon-usesvg);
            }
        }

        &::after {
            @include svg-icon($back-top-icon, $back-top-icon-color, 100%, center, no-repeat, $usesvg: $back-top-icon-usesvg);

            content: '';
            display: inline-block;
            height: $back-top-icon-height;
            width: $back-top-icon-width;

            @include breakpoint(large) {
                height: $back-top-icon-height--large;
                width: $back-top-icon-width--large;
            }
        }

        &.m-active {
            opacity: 1;
            pointer-events: all;
        }

        @each $breakpoint, $visible in $back-top-visibility {
            @if not($visible) {
                @include breakpoint($breakpoint) {
                    display: none;
                }
            }
        }
    }
}
