// Title
$section-title-font: bold rem-calc(22) $font-tertiary;
$section-title-font--large: bold rem-calc(32) $font-tertiary;
$section-title-text-align: center;
$section-title-margin: rem-calc(30 0);
$section-title-margin--large: rem-calc(3.2 0);

$section-secondary-title-text-align: center;
$section-secondary-title-font: 500 #{rem-calc(14)} / 1 $font-primary;
$section-secondary-title-font--large: $section-secondary-title-font;
$section-description-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$section-description-letter-spacing: rem-calc(1);
$section-view-more-link-font: 500 #{rem-calc(12)} / rem-calc(16) $font-primary;
$section-view-more-link-font--large: 500 #{rem-calc(14)} / rem-calc(16) $font-primary;
$section-secondary-title-font--large: 500 #{rem-calc(24)} / 1 $font-primary;
$section-secondary-title-text-align--large: left;

//Description
$section-description-font: 500 #{rem-calc(14)} / 1.42 $font-primary;
$section-description-font--large: 500 #{rem-calc(14)} / 1.42 $font-primary;

@import "@lora/05-components/section";