$tabs-tab-font: 500 #{rem-calc(12)} / rem-calc(18) $font-primary;
$tabs-tab-font--large: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$tabs-tab-text-transform: uppercase;
$tabs-tab-color: color(text-secondary);
$tabs-tab-active-color: color(dark);
$tabs-tab-active-font: 500 #{rem-calc(12)} / rem-calc(18) $font-secondary;
$tabs-tab-active-font--large: $tabs-tab-font--large;
$tabs-tab-letter-spacing--large: rem-calc(1);
$tabs-tab-after-border-bottom: none;
$tabs-tab-active-after-border-bottom: 1px solid color(primary);
$tabs-tab-margin: 0;
$tabs-tab-margin--large: 0;
$tabs-tab-after-width: 100%;
$tabs-tab-padding: rem-calc(15 20);
$tabs-tab-focus-outline-offset: 2px;

$tabs-secondary-tab-font-size--large: rem-calc(14);
$tabs-secondary-controls-align-self: center;
$tabs-secondary-tab-margin: rem-calc(3 0 0);
$tabs-secondary-tab-padding: rem-calc(15 30);
$tabs-secondary-tab-padding--large: rem-calc(15 30);
$tabs-secondary-content-font: normal #{rem-calc(14)} / 1.42 $font-primary;
$tabs-secondary-content-font--large: normal #{rem-calc(14)} / 1.42 $font-primary;
$tabs-secondary-content-letter-spacing: rem-calc(1);
$tabs-secondary-tab-border: rem-calc(1) solid color(disabled);
$tabs-secondary-tab-active-color: color(light);
$tabs-secondary-tab-active-background: color(dark);
$tabs-secondary-control-items-justify-content: center;
$tabs-secondary-tab-divider-border-bottom-width: none;
$tabs-secondary-control-items-border-bottom: none;

$tabs-tertiary-tab-font: 500 #{rem-calc(14)} $font-primary;
$tabs-tertiary-tab-active-font: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$tabs-tertiary-tab-active-font-weight: 500;
$tabs-tertiary-tab-text-transform: uppercase;
$tabs-tertiary-controls-background-color: color(secondary);
$tabs-tertiary-tab-active-color: color(dark);
$tabs-tertiary-tab-font--large: 500 #{rem-calc(12)} / 1.5 $font-primary;
$tabs-tertiary-tab-color: color(text-secondary);
$tabs-secondary-controls-background: transparent;

@import "@lora/05-components/tabs";