$product-swatches-toggle-font--large: 500 #{rem-calc(12)} / 1.33 $font-primary;
$product-swatches-toggle-icon-margin: rem-calc(0 -14 0 5);
$product-swatches-pdp-swatch-margin: rem-calc(2);
$product-swatches-pdp-swatch-margin--large: rem-calc(2);
$product-swatches-selected-text-transform: uppercase;
$product-swatches-pdpv2-swatch-margin: rem-calc(2);
$product-swatches-pdpv2-swatch-margin--large: rem-calc(2);
$product-swatches-selected-font--large: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$product-swatches-selected-color: color(text-secondary);
$product-swatches-selected-color--large: $product-swatches-selected-color;
$product-swatches-view-all-link-font: normal #{rem-calc(14)} / 1.42 $font-primary;

@import "@lora/05-components/product/product-swatches";