/*------------------------------------*\
        #FOOTER LINKS
\*------------------------------------*/

// Deprecated components
@import "../../100-deprecated/footer/footer-links";

$footer-links-justify-content--large: flex-end !default;
$footer-links-color: color(light) !default;
$footer-links-hover-color: $footer-links-color !default;
$footer-links-text-decoration: none !default;
$footer-links-focus-color: $footer-links-hover-color !default;
$footer-links-hover-text-decoration: underline !default;
$footer-links-font: #{rem-calc(12)} / 1.6 $font-primary !default;
$footer-links-text-align: $global-left;
$footer-links-margin--large: rem-calc(15 0 20) !default;
$footer-links-divider-padding: rem-calc(0 2) !default;
$footer-links-divider-padding--large: rem-calc(0 6) !default;
$footer-links-divider-char: '\2014' !default; // em-dash char
$footer-links-divider-char--large: '\2022' !default; // bullet char
$footer-links-divider-after-display: inline-block !default;
$footer-links-divider-last-padding: rem-calc(0 7) !default;
$footer-links-divider-last-char: '|' !default;
$footer-links-list-white-space: nowrap !default;
$footer-links-columns--large: 4 of 12 !default;
$footer-links-letter-spacing: null !default;
$footer-links-footer-secondary-margin--large: rem-calc(8 0 16) !default;

@mixin lora-components-footer-links {
    .l-footer__links {
        text-align: $footer-links-text-align;

        @include breakpoint(large) {
            @include cell($footer-links-columns--large);

            display: flex;
            justify-content: $footer-links-justify-content--large;
            margin: $footer-links-margin--large;
            text-align: $global-left;
            white-space: $footer-links-list-white-space;
        }

        &.m-secondary {
            @include breakpoint(large) {
                margin: $footer-links-footer-secondary-margin--large;
            }
        }
    }

    .l-footer__links-divider::after {
        @include breakpoint(large) {
            color: $footer-links-color;
            content: $footer-links-divider-last-char;
            display: $footer-links-divider-after-display;
            padding: $footer-links-divider-last-padding;
        }
    }

    .l-footer__links-list {
        list-style: none;
        margin: 0;
    }

    .l-footer__links-item {
        color: $footer-links-color;
        display: inline-block;
        font: $footer-links-font;

        &:not(:first-child)::before {
            content: $footer-links-divider-char;
            display: inline-block;
            padding: $footer-links-divider-padding;

            @include breakpoint(large) {
                content: $footer-links-divider-char--large;
                padding: $footer-links-divider-padding--large;
            }
        }
    }

    .l-footer__links-link {
        color: $footer-links-color;
        font: $footer-links-font;
        text-decoration: $footer-links-text-decoration;
        letter-spacing: $footer-links-letter-spacing;

        &:hover {
            color: $footer-links-hover-color;
            text-decoration: $footer-links-hover-text-decoration;
        }

        &:focus {
            color: $footer-links-focus-color;
        }
    }
}
