// Name and subtitle
$product-tile-horizontal-name-font-size: rem-calc(14);
$product-tile-horizontal-name-font-size--large: $product-tile-horizontal-name-font-size;
$product-tile-horizontal-name-link-text-transform: uppercase;
$product-tile-horizontal-name-letter-spacing: rem-calc(1);
$product-tile-horizontal-subtitle-text-transform: uppercase;
$product-tile-horizontal-subtitle-letter-spacing: rem-calc(0.5);
$product-tile-horizontal-subtitle-font-size: rem-calc(10);
$product-tile-horizontal-subtitle-margin--large: rem-calc(3 0 10);
$product-tile-horizontal-name-max-lines--large: 2;
$product-tile-horizontal-name-max-lines: 2;
$product-tile-horizontal-name-text-transform: uppercase;
$product-tile-horizontal-name-font-weight: 500;
$product-tile-horizontal-name-font-family: $font-secondary;
$product-tile-horizontal-name-line-height: 1.42;

// Options, QTY, variations
$product-tile-horizontal-variation-margin--large: rem-calc(20 0 10);
$product-tile-horizontal-variation-font: 600 #{rem-calc(12)} / 1.5 $font-secondary;
$product-tile-horizontal-variation-text-transform: uppercase;

// Table
$product-tile-horizontal-table-margin: 0;

// Info
$product-tile-horizontal-info-item-font-weight--large: 500;
$product-tile-horizontal-name-link-text-transform: uppercase;
$product-tile-horizontal-subtitle-font-size: rem-calc(10);
$product-tile-horizontal-subtitle-font-size--large: rem-calc(14);
$product-tile-horizontal-subtitle-margin--large: rem-calc(0 0 25);
$product-tile-horizontal-subtitle-text-transform: uppercase;
$product-tile-horizontal-subtitle-font-weight: normal;
$product-tile-horizontal-info-item-font: 600 #{rem-calc(12)} / 1.5 $font-secondary;
$product-tile-horizontal-actions-padding: rem-calc(0);
$product-tile-horizontal-variation-font--large: normal #{rem-calc(12)} / 1.5 $font-primary;
$product-tile-horizontal-price-font: 500 #{rem-calc(14)} / 1.42 $font-secondary;

@import "@lora/05-components/product/product-tile-horizontal";