/* stylelint-disable selector-max-type */
// Demo settings
@import '@cwd/app_demo/demo-settings';

@import 'fonts';
@import "colors";
@import 'global';
@import "body";
@import "paragraphs";
@import "headings";
@import "forms";
@import "links";
@import "lists";
@import "media";

@mixin lora-base {
    // Typography resets
    div,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    form,
    p,
    blockquote,
    th,
    td {
        margin: 0;
        padding: 0;
    }

    // Emphasized text
    em,
    i {
        font-style: italic;
        line-height: inherit;
    }

    // Strong text
    strong,
    b {
        font-weight: $global-weight-bold;
        line-height: inherit;
    }

    // Small text
    small {
        font-size: $small-font-size;
        line-height: inherit;
    }
}

@mixin lora-base-styles {
    @include normalize;
    @include unfocus;
    @include lora-base;
    @include lora-base-lists;
    @include lora-base-paragraphs;
    @include lora-base-headings;
    @include lora-base-links;
    @include lora-base-body;
    @include lora-base-forms;
    @include lora-base-media;
}
