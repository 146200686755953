/// Font color for buttons.
/// @type Color
$button-color: color(light);

/// Secondary button color.
/// @type Map
$button-secondary: (
    background: color(secondary),
    background-hover: color(secondary),
    color: color(dark),
    color-hover: color(dark),
    border: 1px solid color(dark),
    border-hover: 1px solid color(dark),
    shadow-hover: rem-calc(0 0 0 1) color(dark) inset
);

/// Alert button color.
/// @type Map
$button-alert: (
    background: color(alert),
    color: $button-color
);

/// Coloring classes. A map of classes to output in your CSS, like `.secondary`, `.success`, and so on.
/// @type Map
$button-palette: (
    secondary: $button-secondary,
    alert: $button-alert
);

/// Sizes for buttons.
/// @type Map
$button-sizes: (
    tiny: (
        padding: rem-calc(18 12),
        font-size: rem-calc(8),
    ),
    small: (
        padding: rem-calc(18 12),
        font-size: rem-calc(10),
    ),
    large: (
        padding: rem-calc(18 12),
        font-size: rem-calc(14),
    ),
);

/// Default font size for buttons.
/// @type Number
$button-font-size: rem-calc(14);

// Social buttons
$button-social-list: (
    facebook: (
        icon: facebook-2,
        background: color(dark),
        background-hover: color(dark),
        icon-color: color(light),
        icon-color-hover: color(light),
        color: color(light),
        color-hover: color(light),
        border: null,
        border-hover: null
    ),
    instagram: (
        icon: instagram,
        background: null,
        background-hover: null,
        color: null,
        color-hover: null,
        border: null,
        border-hover: null
    ),
    twitter: (
        icon: twitter,
        background: color(dark),
        background-hover: color(dark),
        icon-color: color(light),
        icon-color-hover: color(light),
        color: color(light),
        color-hover: color(light),
        border: null,
        border-hover: null
    ),
    google: (
        icon: google-2,
        background: color(dark),
        background-hover: color(dark),
        icon-color: color(light),
        icon-color-hover: color(light),
        color: color(light),
        color-hover: color(light),
        border: null,
        border-hover: null
    ),
);

/// Default font size for buttons.
/// @type Number
$button-font-family: $font-secondary;

$button-font-weight: 500;
$button-padding: rem-calc(18 12);
$button-letter-spacing: rem-calc(1);

$button-social-icon-left: false;
$button-social-icon-size: rem-calc(20);
$button-min-width: rem-calc(170);
$button-search-height: rem-calc(50);
$button-icon-only-height: rem-calc(50);
$button-row-2-padding: rem-calc(9 12);

@import "@lora/05-components/button";