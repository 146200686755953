/* stylelint-disable scale-unlimited/declaration-strict-value */
////
/// @group Swatch
////

$swatch-sizes: (
    default: (
        swatch-size: rem-calc(24),
        border-size: rem-calc(2)
    ),
    small: (
        swatch-size: rem-calc(19),
        border-size: rem-calc(1)
    ),
    medium: (
        swatch-size: rem-calc(34),
        border-size: rem-calc(3)
    ),
    large: (
        swatch-size: rem-calc(49),
        border-size: rem-calc(7)
    ),
    x-large: (
        swatch-size: rem-calc(56),
        border-size: rem-calc(4)
    )
) !default;
$swatch-default-bg: transparent !default;
$swatch-border-width: rem-calc(1) !default;
$swatch-selected-border-padding: $swatch-border-width solid color(element-background) !default;
$swatch-selected-border-color: color(primary-active) !default;
$swatch-selected-box-shadow: null !default;
$swatch-transition: border 0.2s ease-in-out !default;
$swatch-radius: 100% !default;
$swatch-radius--large: null !default;
$swatch-plp-margin: 0 rem-calc(8) !default;
$swatch-padding: 0 !default;
$swatch-padding--large: null !default;
$swatch-disabled-icon: cross !default;
$swatch-disabled-icon-color: color(light) !default;
$swatch-disabled-icon-usesvg: true !default;
$swatch-background-clip: content-box !default;
$swatch-radio-focus-outline: rem-calc(1) dotted color(dark) !default;
// Badge title
$swatch-badge-title-bottom: rem-calc(-10) !default;
$swatch-badge-title-margin: 0 !default;
// Badge image
$swatch-badge-image-bottom: rem-calc(-15) !default;
$swatch-badge-image-max-width: null !default;

@mixin lora-helper-swatch (
    $border-padding: $swatch-selected-border-padding,
    $background-color: false
) {
    background-clip: $swatch-background-clip;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: $border-padding;
    border-radius: $swatch-radius;
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: $swatch-transition;
    vertical-align: middle;
    padding: $swatch-padding;

    @if $background-color {
        background: $background-color;
    }

    @include breakpoint(large) {
        border-radius: $swatch-radius--large;
        padding: $swatch-padding--large;
    }

    @media (forced-colors: active) {
        border-color: ButtonFace;
    }
}

@mixin lora-helper-swatch-selected(
    $selected-border-color: $swatch-selected-border-color,
    $selected-box-shadow: $swatch-selected-box-shadow) {
    @include hover {
        &:is(a, button, label, span):hover {
            border-color: $selected-border-color;
            box-shadow: $selected-box-shadow;

            @media (forced-colors: active) {
                border-color: Highlight;
            }
        }
    }

    &.m-selected {
        border-color: $selected-border-color;
        box-shadow: $selected-box-shadow;
        pointer-events: none;

        @media (forced-colors: active) {
            border-color: Highlight;
        }
    }
}

@mixin lora-helper-swatch-disabled(
    $disabled-icon: $swatch-disabled-icon,
    $disabled-icon-color: $swatch-disabled-icon-color) {
    &.m-disabled {
        &::after {
            @include svg-icon($disabled-icon, $disabled-icon-color, 100%, left, no-repeat, $usesvg: $swatch-disabled-icon-usesvg);
            @include absolute-center;

            content: '';
        }
    }
}

@mixin lora-helper-swatch-size($size) {
    height: map-deep-get($swatch-sizes, $size, "swatch-size");
    padding: map-deep-get($swatch-sizes, $size, "border-size");
    width: map-deep-get($swatch-sizes, $size, "swatch-size");
    min-width: map-deep-get($swatch-sizes, $size, "swatch-size");
    transform: translate3d(0, 0, 0);

    &::after {
        // Formula the square inradius in terms of the circumradius r = R/√2
        // R - inner swatch radius = swatch-size - (border-size + $swatch-border-width) * 2
        // √2 = 1.414
        width: calc((#{map-deep-get($swatch-sizes, $size, "swatch-size")} - (#{map-deep-get($swatch-sizes, $size, "border-size")} + #{$swatch-border-width}) * 2) / 1.414);
        height: calc((#{map-deep-get($swatch-sizes, $size, "swatch-size")} - (#{map-deep-get($swatch-sizes, $size, "border-size")} + #{$swatch-border-width}) * 2) / 1.414);
        mask-size: cover;
    }
}

@mixin lora-components-swatch {
    .c-swatch {
        @include lora-helper-swatch;
        @include lora-helper-swatch-size(default);
        @include lora-helper-swatch-selected;
        @include lora-helper-swatch-disabled;

        // Will generate the rules for the sizes
        // m-small, m-large, etc..
        /* stylelint-disable max-nesting-depth */
        @each $media in $breakpoint-classes {
            @each $name, $size in $swatch-sizes {
                @if ($name != 'default') {
                    &.m-#{$name} {
                        @include lora-helper-swatch-size($name);
                    }

                    &.m-#{$name}-for-#{$media} {
                        @include breakpoint($media) {
                            @include lora-helper-swatch-size($name);
                        }
                    }

                    &.m-#{$name}-for-#{$media}-only {
                        @include breakpoint($media only) {
                            @include lora-helper-swatch-size($name);
                        }
                    }

                    &.m-#{$name}-for-#{$media}-down {
                        @include breakpoint($media down) {
                            @include lora-helper-swatch-size($name);
                        }
                    }
                }
            }
        }
        @media (forced-colors: active) {
            forced-color-adjust: none;
        }
        /* stylelint-enable max-nesting-depth */

        .c-product-badge__title {
            @include horizontal-center;

            bottom: $swatch-badge-title-bottom;
            margin: $swatch-badge-title-margin;
        }

        .c-product-badge__image {
            @include horizontal-center;

            bottom: $swatch-badge-image-bottom;
            max-width: $swatch-badge-image-max-width;
        }
    }

    .c-swatch__inner {
        display: block;
        width: 100%;
        height: 100%;
    }

    .c-swatch__group {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .c-swatch__radio {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        &:focus + .c-swatch {
            outline: $swatch-radio-focus-outline;
        }
    }
}
