$modal-title-margin: rem-calc(0 0 20 15);
$modal-title-margin--large: rem-calc(0 0 28 0);
$modal-title-font: bold #{rem-calc(22)} / 1.09 $font-tertiary;
$modal-title-font--large: bold #{rem-calc(32)} / 1 $font-tertiary;
$modal-text-font-size: rem-calc(14);
$modal-text-large-font-size: $modal-text-font-size;
$modal-text-margin: rem-calc(0 0 50);
$modal-text-color: color(text-secondary);
$modal-subtitle-font: 500 #{rem-calc(18)} / 1.25 $font-primary;
$modal-subtitle-font--large: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$modal-wrapper-padding--large: rem-calc(65 100 55);
$modal-wrapper-width--large: rem-calc(840);
$modal-body-font: normal #{rem-calc(14)} / 1.4 $font-primary;
$modal-message-text-align: left;
$modal-title-text-align: left;
$modal-title-text-align--large: left;
$modal-close-position--medium: rem-calc(18);
$modal-close-icon-size: rem-calc(12);
$modal-close-icon-size--large: rem-calc(16);
$modal-small-wrapper--medium: rem-calc(570);
$modal-header-secondary-title-text-align: left;
$modal-header-secondary-title-border-bottom: 1px solid color(border);
$modal-header-secondary-title-padding: rem-calc(63 50 28 0);
$modal-text-large-font-weight: 400;

@import "@lora/05-components/modal";