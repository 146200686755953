// Layout
$content-grid-columns--large: 4 !default;
$content-grid-column2-columns--large: 2 !default;
$content-grid-column3-columns--large: 3 !default;
$content-grid-padding: rem-calc(0) !default;
$content-grid-padding--large: rem-calc(0) !default;
// Title
$content-grid-title-margin: rem-calc(0 0 10) !default;
$content-grid-title-margin--large: rem-calc(0 0 10) !default;
$content-grid-title-font: 500 #{rem-calc(18)} / 1.5 $font-primary !default;
$content-grid-title-font--large: #{rem-calc(30)} / 1.25 $font-primary !default;
$content-grid-title-text-transform: uppercase !default;
$content-grid-title-text-transform--large: null !default;
$content-grid-title-text-align: $global-left !default;
$content-grid-title-text-align--large: center !default;
$content-grid-title-color: null !default;
$content-grid-title-color--large: null !default;
// Description
$content-grid-description-margin: rem-calc(0 0 10) !default;
$content-grid-description-margin--large: null !default;
$content-grid-description-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$content-grid-description-font--large: #{rem-calc(14)} / 1.5 $font-primary !default;
$content-grid-description-text-align: $global-left !default;
$content-grid-description-text-align--large: center !default;
$content-grid-description-padding: 0 !default;
$content-grid-description-color: null !default;
$content-grid-description-text-transform: null !default;
$content-grid-description-text-transform--large: null !default;
$content-grid-description-letter-spacing: null !default;
// Articles
$content-grid-article-margin: rem-calc(20 0 0) !default;
$content-grid-article-margin--large: rem-calc(40 0 0) !default;
$content-grid-article-gutter--large: rem-calc(30) !default;
$content-grid-article-halfgutter--large: $content-grid-article-gutter--large / 2 !default;
$content-grid-article-padding--large: rem-calc(0 $content-grid-article-halfgutter--large) !default;
$content-grid-items-position: relative !default;
$content-grid-items-margin--large: rem-calc(0 (-$content-grid-article-halfgutter--large)) !default;
$content-grid-article-fullwidth-margin: rem-calc(40 0) !default;
$content-grid-article-fullwidth-margin--large: $content-grid-article-fullwidth-margin !default;
$content-grid-article-border-bottom: null !default;
$content-grid-article-border-bottom--large: null !default;
// View more
$content-grid-view-more-margin: rem-calc(20 0 0) !default;
$content-grid-view-more-margin--large: rem-calc(0) !default;
$content-grid-view-more-text-align: center !default;
$content-grid-view-more-text-align--large: null !default;
$content-grid-view-more-link-font: 500 #{rem-calc(10)} / 1.5 $font-primary !default;
$content-grid-view-more-link-font--large: normal #{rem-calc(12)} / 1.5 $font-primary !default;
$content-grid-view-more-link-text-transform: uppercase !default;
$content-grid-view-more-link-text-transform--large: none !default;

@mixin lora-helper-content-grid-title {
    @include text-style(
        $font: (
            small: $content-grid-title-font,
            large: $content-grid-title-font--large
        ),
        $margin: (
            small: $content-grid-title-margin,
            large: $content-grid-title-margin--large
        ),
        $text-align: (
            small: $content-grid-title-text-align,
            large: $content-grid-title-text-align--large
        ),
        $text-transform: (
            small: $content-grid-title-text-transform,
            large: $content-grid-title-text-transform--large
        ),
        $color: (
            small: $content-grid-title-color,
            large: $content-grid-title-color--large
        )
    );
}

@mixin lora-helper-content-grid-description {
    @include text-style(
        $color: (
            small: $content-grid-description-color
        ),
        $font: (
            small: $content-grid-description-font,
            large: $content-grid-description-font--large
        ),
        $margin: (
            small: $content-grid-description-margin,
            large: $content-grid-description-margin--large
        ),
        $text-align: (
            small: $content-grid-description-text-align,
            large: $content-grid-description-text-align--large
        ),
        $text-transform: (
            small: $content-grid-description-text-transform,
            large: $content-grid-description-text-transform--large
        ),
        $padding: (
            small: $content-grid-description-padding
        ),
        $letter-spacing: (
            small: $content-grid-description-letter-spacing
        )
    );
}

@mixin lora-components-content-grid {
    .c-content-grid {
        padding: $content-grid-padding;

        @include breakpoint(large) {
            display: flex;
            flex-flow: column;
            padding: $content-grid-padding--large;
        }
    }

    .c-content-grid__title {
        @include lora-helper-content-grid-title;
    }

    .c-content-grid__description {
        @include lora-helper-content-grid-description;
    }

    .c-content-grid__items {
        position: $content-grid-items-position;

        @include breakpoint(large) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: $content-grid-items-margin--large;
        }

        &.m-column-2 {
            .c-content-grid__article:not(.m-fullwidth) {
                @include breakpoint(large) {
                    width: percentage(1 / $content-grid-column2-columns--large);
                }
            }
        }

        &.m-column-3 {
            .c-content-grid__article:not(.m-fullwidth) {
                @include breakpoint(large) {
                    width: percentage(1 / $content-grid-column3-columns--large);
                }
            }
        }
    }

    .c-content-grid__article {
        margin: $content-grid-article-margin;
        border-bottom: $content-grid-article-border-bottom;

        @include breakpoint(large) {
            margin: $content-grid-article-margin--large;
            padding: $content-grid-article-padding--large;
            width: percentage(1 / $content-grid-columns--large);
            border-bottom: $content-grid-article-border-bottom--large;
        }

        &.m-fullwidth {
            width: 100%;
            margin: $content-grid-article-fullwidth-margin;

            @include breakpoint(large) {
                margin: $content-grid-article-fullwidth-margin--large;
            }
        }
    }

    .c-content-grid__view-more {
        margin: $content-grid-view-more-margin;
        text-align: $content-grid-view-more-text-align;

        @include breakpoint(large) {
            margin: $content-grid-view-more-margin--large;
            text-align: $content-grid-view-more-text-align--large;
        }
    }

    .c-content-grid__view-more-link {
        font: $content-grid-view-more-link-font;
        text-transform: $content-grid-view-more-link-text-transform;

        @include breakpoint(large) {
            font: $content-grid-view-more-link-font--large;
            text-transform: $content-grid-view-more-link-text-transform--large;
        }
    }
}
