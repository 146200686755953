$locale-selector-padding--medium-down: rem-calc(20 15) !default;
$locale-selector-background--medium-down: color(element-background) !default;
$locale-selector-content-border-bottom--medium-down: 1px solid color(light) !default;
// Select
$locale-selector-select-color: color(text-secondary) !default;
$locale-selector-select-color--large: null !default;
$locale-selector-select-padding: rem-calc(0 15 0 0) !default;
$locale-selector-select-padding--large: null !default;
$locale-selector-select-align-self--medium-down: center !default;
$locale-selector-select-hover-color: null !default;
$locale-selector-select-background--large: null !default;
$locale-selector-select-font--large: null !default;
$locale-selector-select-hover-border: null !default;
// Select Arrow
$locale-selector-select-arrow: "chevron-down" !default;
$locale-selector-select-arrow-size: rem-calc(10) !default;
$locale-selector-select-arrow-position: 0 !default;
$locale-selector-select-arrow-color: color(text) !default;
$locale-selector-select-arrow-usesvg: true !default;
/// Select secondary
$locale-selector-select-secondary-color: color(light) !default;
$locale-selector-select-secondary-color--large: $locale-selector-select-secondary-color !default;
$locale-selector-select-secondary-hover-text-decoration: null !default;
$locale-selector-select-secondary-hover-color: null !default;
$locale-selector-select-secondary-font: null !default;
$locale-selector-select-secondary-font--large: $locale-selector-select-secondary-font !default;
$locale-selector-select-secondary-padding: rem-calc(10 30 10 12) !default;
$locale-selector-select-secondary-padding--large: $locale-selector-select-secondary-padding !default;
$locale-selector-select-secondary-margin: null !default;
$locale-selector-select-secondary-margin--large: null !default;
$locale-selector-select-secondary-hover-background-color: null !default;
$locale-selector-select-secondary-hover-border: null !default;
/// Select secondary arrow
$locale-selector-select-secondary-arrow-width: null !default;
$locale-selector-select-secondary-arrow-height: null !default;
$locale-selector-select-secondary-arrow: $locale-selector-select-arrow !default;
$locale-selector-select-secondary-arrow-color: color(light) !default;
$locale-selector-select-secondary-arrow-position: rem-calc(15) !default;
$locale-selector-select-secondary-arrow-usesvg: true !default;
// Content
$locale-selector-content-padding: rem-calc(10 15) !default;
$locale-selector-content-padding--large: rem-calc(30 50) !default;
$locale-selector-content-width--large: rem-calc(650) !default;
// Title
$locale-selector-title-color: color(text) !default;
$locale-selector-title-color--large: null !default;
$locale-selector-title-font: #{rem-calc(18)} / 1.4 $font-primary !default;
$locale-selector-title-font--large: null !default;
$locale-selector-title-margin: rem-calc(0 0 15) !default;
$locale-selector-title-margin--large: null !default;
$locale-selector-title-text-align: $global-left !default;
$locale-selector-title-text-align--large: null !default;
$locale-selector-title-text-transform: uppercase !default;
$locale-selector-title-text-transform--large: null !default;
// Description
$locale-selector-description-color: color(text) !default;
$locale-selector-description-color--large: null !default;
$locale-selector-description-font: #{rem-calc(12)} / 1.4 $font-primary !default;
$locale-selector-description-font--large: null !default;
$locale-selector-description-margin: rem-calc(0 0 5) !default;
$locale-selector-description-margin--large: null !default;
$locale-selector-description-text-align: $global-left !default;
$locale-selector-description-text-align--large: null !default;
$locale-selector-description-text-transform: none !default;
$locale-selector-description-text-transform--large: null !default;
$locale-selector-description-letter-spacing: null !default;
// Stores
$locale-selector-stores-margin: rem-calc(0 0 5) !default;
$locale-selector-stores-margin--large: rem-calc(0 0 20) !default;
$locale-selector-stores-text-align: $global-left !default;
$locale-selector-stores-text-align--large: null !default;
$locale-selector-stores-order--medium-down: 1 !default;
// Stores link
$locale-selector-stores-stores-link-color: color(text) !default;
$locale-selector-stores-stores-link-color--large: null !default;
$locale-selector-stores-stores-link-font: #{rem-calc(14)} / 1.4 $font-primary !default;
$locale-selector-stores-stores-link-font--large: null !default;
$locale-selector-stores-stores-link-text-transform: uppercase !default;
$locale-selector-stores-stores-link-text-transform--large: null !default;
$locale-selector-stores-stores-link-text-decoration: underline !default;
$locale-selector-stores-stores-link-text-decoration--large: null !default;
// Items
$locale-selector-items-margin: 0 !default;
$locale-selector-items-margin--large: null !default;
$locale-selector-items-columns: 6 of 12 !default;
$locale-selector-items-columns--large: 3 of 12 !default;
// Items level 2
$locale-selector-items-level2-margin: rem-calc(10 0 0 10) !default;
$locale-selector-items-level2-margin--large: $locale-selector-items-level2-margin !default;
$locale-selector-items-level2-padding: 0 !default;
$locale-selector-items-level2-padding--large: $locale-selector-items-level2-padding !default;
// Item
$locale-selector-item-margin: rem-calc(0 0 15) !default;
// Item title
$locale-selector-item-title-color: color(text-secondary) !default;
$locale-selector-item-title-color--large: null !default;
$locale-selector-item-title-font: #{rem-calc(12)} / 1.4 $font-primary !default;
$locale-selector-item-title-font--large: null !default;
$locale-selector-item-title-text-transform: none !default;
$locale-selector-item-title-text-transform--large: null !default;
$locale-selector-item-title-padding: rem-calc(0 20 0 0) !default;
$locale-selector-item-title-cursor: pointer !default;
$locale-selector-item-title-display: inline-block !default;
// Item title Arrow
$locale-selector-item-title-arrow: "chevron-down" !default;
$locale-selector-item-title-arrow-top: rem-calc(3) !default;
$locale-selector-item-title-arrow-size: rem-calc(10) !default;
$locale-selector-item-title-arrow-color: color(text-secondary) !default;
$locale-selector-item-title-arrow-opened-transform: rotate(180deg) !default;
$locale-selector-item-title-arrow-usesvg: true !default;
// Item link
$locale-selector-item-link-color: color(text-secondary) !default;
$locale-selector-item-link-color--large: null !default;
$locale-selector-item-link-font: #{rem-calc(12)} / 1.4 $font-primary !default;
$locale-selector-item-link-font--large: null !default;
$locale-selector-item-link-text-transform: none !default;
$locale-selector-item-link-text-transform--large: null !default;
$locale-selector-item-link-letter-spacing: null !default;
// Item active link
$locale-selector-item-active-link-color: color(text) !default;
$locale-selector-item-active-link-color--large: $locale-selector-item-active-link-color !default;
$locale-selector-item-active-link-font: #{rem-calc(12)} / 1.4 $font-primary !default;
$locale-selector-item-active-link-font--large: $locale-selector-item-active-link-font !default;
$locale-selector-item-active-link-text-decoration: none !default;

@mixin lora-components-locale-selector {
    .c-locale-selector {
        @include breakpoint(medium down) {
            padding: $locale-selector-padding--medium-down;
            background: $locale-selector-background--medium-down;
            border-bottom: $locale-selector-content-border-bottom--medium-down;
            display: flex;
            flex-flow: column;
        }
    }

    .c-locale-selector__content {
        padding: $locale-selector-content-padding;

        @include breakpoint(large) {
            width: $locale-selector-content-width--large;
            padding: $locale-selector-content-padding--large;
        }
        @include breakpoint(medium down) {
            display: flex;
            flex-flow: column;
        }
    }

    .c-locale-selector__select {
        position: relative;
        display: block;
        cursor: pointer;
        padding: $locale-selector-select-padding;
        color: $locale-selector-select-color;

        &.m-disabled {
            cursor: default;
            padding: 0;
        }

        &:not(.m-disabled):hover,
        &:not(.m-disabled):focus {
            color: $locale-selector-select-hover-color;
            border: $locale-selector-select-hover-border;
        }

        @include breakpoint(large) {
            color: $locale-selector-select-color--large;
            padding: $locale-selector-select-padding--large;
            background: $locale-selector-select-background--large;
            font: $locale-selector-select-font--large;
        }

        @include breakpoint(medium down) {
            align-self: $locale-selector-select-align-self--medium-down;
        }

        &:not(.m-disabled)::after {
            @include svg-icon($locale-selector-select-arrow, $locale-selector-select-arrow-color, 100%, left, no-repeat, $usesvg: $locale-selector-select-arrow-usesvg);
            @include vertical-center;

            content: '';
            width: $locale-selector-select-arrow-size;
            height: $locale-selector-select-arrow-size;
            pointer-events: none;
            #{$global-right}: $locale-selector-select-arrow-position;
        }

        &.m-secondary {
            @include text-style(
                $font: (
                    small: $locale-selector-select-secondary-font,
                    large: $locale-selector-select-secondary-font--large
                ),
                $margin: (
                    small: $locale-selector-select-secondary-margin,
                    large: $locale-selector-select-secondary-margin--large
                ),
                $padding: (
                    small: $locale-selector-select-secondary-padding,
                    large: $locale-selector-select-secondary-padding--large
                ),
                $color: (
                    small: $locale-selector-select-secondary-color,
                    large: $locale-selector-select-secondary-color--large
                )
            );

            &::after {
                @include svg-icon($locale-selector-select-secondary-arrow, $locale-selector-select-secondary-arrow-color, 100%, left, no-repeat, $usesvg: $locale-selector-select-secondary-arrow-usesvg);

                width: $locale-selector-select-secondary-arrow-width;
                height: $locale-selector-select-secondary-arrow-height;
                #{$global-right}: $locale-selector-select-secondary-arrow-position;
            }

            &:hover,
            &:focus {
                color: $locale-selector-select-secondary-hover-color;
                text-decoration: $locale-selector-select-secondary-hover-text-decoration;
                background-color: $locale-selector-select-secondary-hover-background-color;
                border: $locale-selector-select-secondary-hover-border;
            }
        }
    }

    .c-locale-selector__title {
        @include text-style(
            $color: (
                small: $locale-selector-title-color,
                large: $locale-selector-title-color--large
            ),
            $font: (
                small: $locale-selector-title-font,
                large: $locale-selector-title-font--large
            ),
            $margin: (
                small: $locale-selector-title-margin,
                large: $locale-selector-title-margin--large
            ),
            $text-align: (
                small: $locale-selector-title-text-align,
                large: $locale-selector-title-text-align--large
            ),
            $text-transform: (
                small: $locale-selector-title-text-transform,
                large: $locale-selector-title-text-transform--large
            )
        );
    }

    .c-locale-selector__description {
        @include text-style(
            $color: (
                small: $locale-selector-description-color,
                large: $locale-selector-description-color--large
            ),
            $font: (
                small: $locale-selector-description-font,
                large: $locale-selector-description-font--large
            ),
            $margin: (
                small: $locale-selector-description-margin,
                large: $locale-selector-description-margin--large
            ),
            $text-align: (
                small: $locale-selector-description-text-align,
                large: $locale-selector-description-text-align--large
            ),
            $text-transform: (
                small: $locale-selector-description-text-transform,
                large: $locale-selector-description-text-transform--large
            ),
            $letter-spacing: (
                small: $locale-selector-description-letter-spacing
            )
        );
    }

    .c-locale-selector__stores {
        margin: $locale-selector-stores-margin;
        text-align: $locale-selector-stores-text-align;

        @include breakpoint(large) {
            margin: $locale-selector-stores-margin--large;
            text-align: $locale-selector-stores-text-align--large;
        }

        @include breakpoint(medium down) {
            order: $locale-selector-stores-order--medium-down;
        }
    }

    .c-locale-selector__stores-link {
        @include text-style(
            $color: (
                small: $locale-selector-stores-stores-link-color,
                large: $locale-selector-stores-stores-link-color--large
            ),
            $font: (
                small: $locale-selector-stores-stores-link-font,
                large: $locale-selector-stores-stores-link-font--large
            ),
            $text-transform: (
                small: $locale-selector-stores-stores-link-text-transform,
                large: $locale-selector-stores-stores-link-text-transform--large
            ),
            $text-decoration: (
                small: $locale-selector-stores-stores-link-text-decoration,
                large: $locale-selector-stores-stores-link-text-decoration--large
            )
        );

        &::before {
            display: none;
        }
    }

    .c-locale-selector__list {
        @include grid;
    }

    .c-locale-selector__items {
        @include cell($locale-selector-items-columns);

        list-style: none;
        margin: $locale-selector-items-margin;

        @include breakpoint(large) {
            @include cell($locale-selector-items-columns--large);

            margin: $locale-selector-items-margin--large;
        }

        &.m-level-2 {
            max-height: 0;
            overflow: hidden;
            width: 100%;
            padding: $locale-selector-items-level2-padding;
        }
    }

    .c-locale-selector__item {
        margin: $locale-selector-item-margin;

        &.m-expanded {
            & > .c-locale-selector__item-title::after {
                transform: $locale-selector-item-title-arrow-opened-transform;
            }

            & > .c-locale-selector__items.m-level-2 {
                max-height: initial;
                overflow: visible;
                margin: $locale-selector-items-level2-margin;

                @include breakpoint(large) {
                    margin: $locale-selector-items-level2-margin--large;
                    padding: $locale-selector-items-level2-padding--large;
                }
            }
        }

        &.m-active {
            .c-locale-selector__item-link {
                @include text-style(
                    $color: (
                        small: $locale-selector-item-active-link-color,
                        large: $locale-selector-item-active-link-color--large
                    ),
                    $font: (
                        small: $locale-selector-item-active-link-font,
                        large: $locale-selector-item-active-link-font--large
                    ),
                    $text-decoration: (
                        small: $locale-selector-item-active-link-text-decoration
                    )
                );

                pointer-events: none;
                cursor: default;
            }
        }
    }

    .c-locale-selector__item-title {
        @include text-style(
            $color: (
                small: $locale-selector-item-title-color,
                large: $locale-selector-item-title-color--large
            ),
            $font: (
                small: $locale-selector-item-title-font,
                large: $locale-selector-item-title-font--large
            ),
            $text-transform: (
                small: $locale-selector-item-title-text-transform,
                large: $locale-selector-item-title-text-transform--large
            ),
            $padding: (
                small: $locale-selector-item-title-padding
            )
        );

        position: relative;
        user-select: none;
        cursor: $locale-selector-item-title-cursor;
        display: $locale-selector-item-title-display;

        &::after {
            @include svg-icon($locale-selector-item-title-arrow, $locale-selector-item-title-arrow-color, 100%, left, no-repeat, $usesvg: $locale-selector-item-title-arrow-usesvg);

            position: absolute;
            top: $locale-selector-item-title-arrow-top;
            content: '';
            width: $locale-selector-item-title-arrow-size;
            height: $locale-selector-item-title-arrow-size;
            pointer-events: none;
            #{$global-right}: 0;
        }
    }

    .c-locale-selector__item-link {
        @include text-style(
            $color: (
                small: $locale-selector-item-link-color,
                large: $locale-selector-item-link-color--large
            ),
            $font: (
                small: $locale-selector-item-link-font,
                large: $locale-selector-item-link-font--large
            ),
            $text-transform: (
                small: $locale-selector-item-link-text-transform,
                large: $locale-selector-item-link-text-transform--large
            ),
            $letter-spacing: (
                small: $locale-selector-item-link-letter-spacing
            )
        );
    }
}
