// Carousel Inline

@mixin lora-components-carousel-inline {
    .c-carousel.m-inline {
        margin-left: inherit;
        margin-right: inherit;

        .c-carousel__item {
            align-items: center;
            display: flex;
            justify-content: center;
            width: auto;
            height: auto;
        }

        /* stylelint-disable selector-max-compound-selectors */
        &.m-slidesperview-auto-for-medium-down:not(.m-ready) {
            & > .c-carousel__inner > .c-carousel__content > .c-carousel__item {
                @include breakpoint(medium down) {
                    width: auto;
                }
            }
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}
