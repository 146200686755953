$header-user-menu-padding: rem-calc(16);
$header-user-menu-justify-content: flex-start;
$header-user-menu-font: 300 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-user-menu-title-font: 300 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-user-menu-content-link-logout-background: color(primary);
$header-user-menu-back-button-text-transform: capitalize;
$header-user-menu-back-button-font: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$header-user-menu-back-button-icon-height: rem-calc(9);
$header-user-menu-back-button-icon-width: rem-calc(4);
$header-user-menu-content-title-text-transform--medium-down: uppercase;
$header-user-menu-content-title-font-weight--medium-down: 500;
$header-user-menu-content-title-height: rem-calc(50);
$header-user-menu-content-title-font-size: rem-calc(14);
$header-user-menu-content-link-text-transform: uppercase;
$header-user-menu-content-link-font-size: rem-calc(12);
$header-user-menu-content-link-logout-margin: rem-calc(20 45 0);
$header-user-menu-title-flex-direction: column;
$header-user-menu-title-margin: rem-calc(0 10 0 0);
$header-user-menu-secondary-list-margin: rem-calc(20 40);
$header-user-menu-content-item-border-top: 1px solid color(border);
$header-user-menu-content-link-logout-margin: rem-calc(15 0);
$header-user-menu-content-link-logout-text-transform: uppercase;
$header-user-menu-content-link-logout-hover-background: color(dark);
$header-user-menu-logout-link-height: rem-calc(19);
$header-user-menu-secondary-link-height: rem-calc(50);
$header-user-menu-secondary-list-border-top: rem-calc(1) solid color(border);
$header-user-menu-content-title-font-family--medium-down: $font-secondary;
$header-user-menu-background: color(primary-active);
$header-user-menu-sign-in-color: color(secondary);
$header-user-menu-icon-color: color(secondary);
$header-user-menu-title-color: color(secondary);
$header-user-menu-title-name-link-color: color(secondary);
$header-user-menu-logout-link-color: color(disabled);
$header-user-menu-logout-link-font: 300 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;

@import "@lora/05-components/header/header-user-menu";