// Title
$content-hero-title-font: bold #{rem-calc(32)} / 1 $font-tertiary;
$content-hero-title-font--large: bold #{rem-calc(50)} / 1 $font-tertiary;
$content-hero-title-color--large: color(text);
$content-hero-title-letter-spacing: rem-calc(2);

// Description
$content-hero-description-font: #{rem-calc(12)} / 1.5 $font-primary;
$content-hero-description-font--large: #{rem-calc(14)} / 1.42 $font-primary;
$content-hero-description-letter-spacing: rem-calc(1);
$content-hero-description-color: color(text);
$content-hero-description-color--large: color(text);
$content-hero-description-text-transform: none;
$content-hero-description-text-transform--large: $content-hero-description-text-transform;

//label
$content-hero-label-font: 500 #{rem-calc(12)} / #{rem-calc(18)} $font-tertiary;
$content-hero-label-border-left: rem-calc(4) solid color(primary);
$content-hero-label-background-padding: rem-calc(7 22);

@import "@lora/05-components/content/content-hero";