/* stylelint-disable scale-unlimited/declaration-strict-value */
////
/// @group forms
////

$form-check-field-label-font-size: rem-calc(14) !default;
$form-check-field-label-font-weight: null !default;
$form-check-field-label-height: 1.5 !default;
$form-check-field-label-side-margin: rem-calc(10) !default;
$form-check-field-label-text-transform: null !default;
$form-check-field-label-letter-spacing: null !default;
// Radiobuttons
$form-check-field-radio-control-size: rem-calc(16) !default;
$form-check-field-radio-control-background: color(primary) !default;
$form-check-field-radio-control-before-background: color(light) !default;
$form-check-field-radio-control-border-radius: 50% !default;
$form-check-field-radio-control-border: #{$form-check-field-radio-control-size / 2} solid color(light) !default;
$form-check-field-radio-control-transition: box-shadow 0.2s, border-color 0.2s ease-in-out !default;
$form-check-field-radio-control-after-top: rem-calc(2) !default;
$form-check-field-radio-control-after-size: rem-calc(18) !default;
$form-check-field-radio-control-after-border: rem-calc(1) solid color(primary) !default;
$form-check-field-radio-checked-control-border-width: #{$form-check-field-radio-control-size / 4} !default;
$form-check-field-radio-checked-control-second-border-width: $form-check-field-radio-checked-control-border-width !default;
/// Unchecked Radio Hover, Focus, Active states
$form-check-field-radio-control-hover-box-shadow: rem-calc(0 0 0 4) rgba(color(dark), 0.15) !default;
$form-check-field-radio-control-focus-box-shadow: rem-calc(0 0 0 4) rgba(color(dark), 0.35) !default;
$form-check-field-radio-control-active-box-shadow: rem-calc(0 0 0 5) rgba(color(dark), 0.35) !default;
/// Checked Radio Hover, Focus, Active states
$form-check-field-radio-checked-control-hover-box-shadow: rem-calc(0 0 0 4) rgba(color(primary), 0.15) !default;
$form-check-field-radio-checked-control-focus-box-shadow: rem-calc(0 0 0 4) rgba(color(primary), 0.35) !default;
$form-check-field-radio-checked-control-active-box-shadow: rem-calc(0 0 0 5) rgba(color(primary), 0.35) !default;
$form-check-field-radio-checked-control-font-weight: inherit !default;
$form-check-field-radio-checked-control-border: null !default;

// Checkboxes
$form-check-field-checkbox-control-background: color(light) !default;
$form-check-field-checkbox-control-border-radius: null !default;
$form-check-field-checkbox-control-border: 1px solid color(primary) !default;
$form-check-field-checkbox-control-margin-top: 0 !default;
$form-check-field-checkbox-control-margin-top--retina: 0 !default;
$form-check-field-checkbox-control-size: rem-calc(20) !default;
$form-check-field-checkbox-control-transition: box-shadow 0.2s, border-color 0.2s ease-in-out !default;
/// Unchecked Hover, Focus, Active states
$form-check-field-checkbox-control-hover-box-shadow: rem-calc(0 0 1 3) rgba(color(dark), 0.15) !default;
$form-check-field-checkbox-control-focus-box-shadow: rem-calc(0 0 1 3) rgba(color(dark), 0.35) !default;
$form-check-field-checkbox-control-active-box-shadow: rem-calc(0 0 0 5) rgba(color(dark), 0.35) !default;
// Checked state
$form-check-field-checkbox-checked-control-background: color(primary-active) !default;
$form-check-field-checkbox-checked-control-border: 1px solid color(primary) !default;
$form-check-field-checkbox-checked-icon: cross !default;
$form-check-field-checkbox-checked-icon-color: color(light) !default;
$form-check-field-checkbox-checked-icon-width: rem-calc(10) !default;
$form-check-field-checkbox-checked-icon-usesvg: false !default;
// Background size calculation based on existing variables, overloaded on brands
// Equal to icon size divided by checkbox size (with an exclusion of 2px border)
$form-check-field-checkbox-checked-icon-size: percentage($form-check-field-checkbox-checked-icon-width / ($form-check-field-checkbox-control-size - rem-calc(2))) !default;
/// Checked Hover, Focus, Active states
$form-check-field-checkbox-checked-control-hover-box-shadow: rem-calc(0 0 1 3) rgba(color(primary), 0.15) !default;
$form-check-field-checkbox-checked-control-focus-box-shadow: rem-calc(0 0 1 3) rgba(color(primary), 0.35) !default;
$form-check-field-checkbox-checked-control-active-box-shadow: rem-calc(0 0 0 5) rgba(color(primary), 0.35) !default;
$form-check-field-checkbox-checked-control-font-weight: inherit !default;
$form-check-field-checkbox-checked-control-text-shadow: null !default;
// Switch
$form-check-field-switch-enabled: true !default;
$form-check-field-switch-padding: rem-calc(0 50 0 0) !default;
/// Switch before
$form-check-field-switch-before-background: color(organism-background) !default;
$form-check-field-switch-before-border-radius: rem-calc(10) !default;
$form-check-field-switch-before-border: null !default;
$form-check-field-switch-before-margin: 0 !default;
$form-check-field-switch-before-width: rem-calc(36) !default;
$form-check-field-switch-before-height: rem-calc(18) !default;
$form-check-field-switch-before-transition: all 0.2s ease-in-out !default;
$form-check-field-switch-before-position--top: 0 !default;
$form-check-field-switch-before-position--right: 0 !default;
/// Switch after
$form-check-field-switch-after-width: rem-calc(22) !default;
$form-check-field-switch-after-height: rem-calc(22) !default;
$form-check-field-switch-after-position--top: rem-calc(-2) !default;
$form-check-field-switch-after-position--right: rem-calc(15) !default;
$form-check-field-switch-after-background: color(global-background) !default;
$form-check-field-switch-after-border-radius: 50% !default;
$form-check-field-switch-after-border: null !default;
$form-check-field-switch-after-box-shadow: rem-calc(0 0 2 0) rgba(color(dark), 0.5) !default;
$form-check-field-switch-after-transition: all 0.2s ease-in-out !default;
/// Unchecked Hover, Focus, Active states
$form-check-field-switch-after-hover-box-shadow: rem-calc(0 0 1 3) rgba(color(dark), 0.15) !default;
$form-check-field-switch-after-focus-box-shadow: rem-calc(0 0 1 3) rgba(color(dark), 0.35) !default;
$form-check-field-switch-after-active-box-shadow: rem-calc(0 0 0 5) rgba(color(dark), 0.35) !default;
// Checked state
$form-check-field-switch-checked-before-background: color(primary-active) !default;
$form-check-field-switch-checked-before-border: null !default;
$form-check-field-switch-checked-after-position--right: 0 !default;
$form-check-field-switch-checked-after-background: color(primary) !default;
$form-check-field-switch-checked-after-border: null !default;
/// Checked Hover, Focus, Active states
$form-check-field-switch-checked-after-hover-box-shadow: rem-calc(0 0 1 3) rgba(color(primary), 0.15) !default;
$form-check-field-switch-checked-after-focus-box-shadow: rem-calc(0 0 1 3) rgba(color(primary), 0.35) !default;
$form-check-field-switch-checked-after-active-box-shadow: rem-calc(0 0 0 5) rgba(color(primary), 0.35) !default;
$form-check-field-switch-checked-font-weight: inherit !default;
/// Switch disabled state
$form-check-field-switch-disabled-color: color(disabled) !default;
$form-check-field-switch-disabled-before-border-color: null !default;
$form-check-field-switch-disabled-before-background: lighten($form-check-field-switch-before-background, 25%) !default;
$form-check-field-switch-disabled-after-border-color: null !default;
$form-check-field-switch-disabled-after-background: lighten($form-check-field-switch-after-background, 25%) !default;
// Disabled state
$form-check-field-disabled-control-color: color(disabled) !default;
$form-check-field-disabled-control-border-color: color(disabled) !default;
$form-check-field-disabled-control-background: color(organism-background) !default;
// Error state
$form-check-field-error-control-color: color(text) !default;
$form-check-field-error-control-border-color: color(alert) !default;
$form-check-field-error-checked-control-background: color(alert) !default;
$form-check-field-error-control-box-shadow: null !default;
// label image
$form-check-field-image-label-align-items: center !default;
$form-check-field-image-label-image-margin: rem-calc(0 5 0 0) !default;
$form-check-field-image-label-before-gutter: rem-calc(20) !default;
$form-check-field-image-label-min-height: rem-calc(80) !default;
$form-check-field-image-label-border: 1px solid color(border) !default;
$form-check-field-image-checked-label-border: 2px solid color(primary-active) !default;
$form-check-field-image-label-width: 100% !default;
$form-check-field-image-label-padding: rem-calc(10 20 10 48) !default;
$form-check-field-image-label-align-items: center !default;

@mixin lora-helper-check-field-label-before {
    content: '';
    display: inline-block;
    vertical-align: top;
}

@mixin lora-helper-check-field-radio-unchecked-state {
    // Must equal to control size + side margin for a proper alignment of label in multiple rows
    padding-#{$global-left}: $form-check-field-label-side-margin + $form-check-field-radio-control-after-size;

    &::before {
        content: '';
        position: absolute;
        #{$global-left}: 0;
        background: $form-check-field-radio-control-before-background;
        border-radius: $form-check-field-radio-control-border-radius;
        border: $form-check-field-radio-control-after-border;
        margin-top: $form-check-field-radio-control-after-top;
        height: $form-check-field-radio-control-after-size;
        width: $form-check-field-radio-control-after-size;
        transition: $form-check-field-radio-control-transition;
    }

    &:hover {
        &::before {
            box-shadow: $form-check-field-radio-control-hover-box-shadow;
        }
    }

    &:focus {
        &::before {
            box-shadow: $form-check-field-radio-control-focus-box-shadow;
        }
    }

    &:active {
        &::before {
            box-shadow: $form-check-field-radio-control-active-box-shadow;
        }
    }
}

@mixin lora-helper-check-field-radio-checked-state {
    font-weight: $form-check-field-radio-checked-control-font-weight;

    &::before {
        background: radial-gradient(circle, $form-check-field-radio-control-background 0%, $form-check-field-radio-control-background $form-check-field-radio-checked-control-border-width, $form-check-field-radio-control-before-background $form-check-field-radio-checked-control-second-border-width, $form-check-field-radio-control-before-background 100%);
        border: $form-check-field-radio-checked-control-border;

        @media (forced-colors: active) {
            background: SelectedItemText;
            padding: $form-check-field-radio-checked-control-border-width;
            background-clip: content-box;
        }
    }

    &:hover {
        &::before {
            box-shadow: $form-check-field-radio-checked-control-hover-box-shadow;
        }
    }

    &:focus {
        &::before {
            box-shadow: $form-check-field-radio-checked-control-focus-box-shadow;
        }
    }

    &:active {
        &::before {
            box-shadow: $form-check-field-radio-checked-control-active-box-shadow;
        }
    }
}

@mixin lora-helper-check-field-radio-disabled-state {
    color: $form-check-field-disabled-control-color;
    cursor: default;

    &::before {
        border-color: $form-check-field-disabled-control-border-color;
    }

    &:hover,
    &:focus,
    &:active {
        &::before {
            box-shadow: none;
        }
    }
}

@mixin lora-helper-check-field-radio-disabled-checked-state {
    &::before {
        background: radial-gradient(circle, $form-check-field-disabled-control-background 0%, $form-check-field-disabled-control-background $form-check-field-radio-checked-control-border-width, color(light) $form-check-field-radio-checked-control-border-width, color(light) 100%);
    }
}

@mixin lora-helper-check-field-radio-error-state {
    color: $form-check-field-error-control-color;

    &::before {
        border-color: $form-check-field-error-control-border-color;

        @media (forced-colors: active) {
            border-color: ActiveText !important; /* stylelint-disable-line */
        }
    }
}

@mixin lora-helper-check-field-checkbox-unchecked-state {
    // Must equal to control size + side margin for a proper alignment of label in multiple rows
    padding-#{$global-left}: $form-check-field-label-side-margin + $form-check-field-checkbox-control-size;

    &::before {
        content: '';
        position: absolute;
        #{$global-left}: 0;
        background: $form-check-field-checkbox-control-background;
        border-radius: $form-check-field-checkbox-control-border-radius;
        border: $form-check-field-checkbox-control-border;
        margin-top: $form-check-field-checkbox-control-margin-top;
        height: $form-check-field-checkbox-control-size;
        width: $form-check-field-checkbox-control-size;
        transition: $form-check-field-checkbox-control-transition;

        @include breakpoint(retina) {
            margin-top: $form-check-field-checkbox-control-margin-top--retina;
        }
    }

    &:hover {
        &::before {
            box-shadow: $form-check-field-checkbox-control-hover-box-shadow;
        }
    }

    &:focus {
        &::before {
            box-shadow: $form-check-field-checkbox-control-focus-box-shadow;
        }
    }

    &:active {
        &::before {
            box-shadow: $form-check-field-checkbox-control-active-box-shadow;
        }
    }
}

@mixin lora-helper-check-field-checkbox-checked-state {
    font-weight: $form-check-field-checkbox-checked-control-font-weight;
    text-shadow: $form-check-field-checkbox-checked-control-text-shadow;

    &::before {
        background: $form-check-field-checkbox-checked-control-background;
        border: $form-check-field-checkbox-checked-control-border;

        @include svg-icon(
            $form-check-field-checkbox-checked-icon,
            $form-check-field-checkbox-checked-icon-color,
            $form-check-field-checkbox-checked-icon-size,
            center,
            no-repeat,
            $usesvg: $form-check-field-checkbox-checked-icon-usesvg
        );
    }

    &:hover {
        &::before {
            box-shadow: $form-check-field-checkbox-checked-control-hover-box-shadow;
        }
    }

    &:focus {
        &::before {
            box-shadow: $form-check-field-checkbox-checked-control-focus-box-shadow;
        }
    }

    &:active {
        &::before {
            box-shadow: $form-check-field-checkbox-checked-control-active-box-shadow;
        }
    }
}

@mixin lora-helper-check-field-checkbox-disabled-state {
    color: $form-check-field-disabled-control-color;
    cursor: default;

    &::before {
        border-color: $form-check-field-disabled-control-border-color;
    }

    &:hover,
    &:focus,
    &:active {
        &::before {
            box-shadow: none;
        }
    }
}

@mixin lora-helper-check-field-checkbox-disabled-checked-state {
    &::before {
        background-color: $form-check-field-disabled-control-background;
    }
}

@mixin lora-helper-check-field-checkbox-error-state {
    color: $form-check-field-error-control-color;

    &::before {
        border-color: $form-check-field-error-control-border-color;
        box-shadow: $form-check-field-error-control-box-shadow;

        @media (forced-colors: active) {
            border-color: ActiveText !important; /* stylelint-disable-line */
        }
    }
}

@mixin lora-helper-check-field-checkbox-unchecked-focused-state {
    &::before {
        @include focus-style;

        box-shadow: $form-check-field-checkbox-control-focus-box-shadow;
    }
}

@mixin lora-helper-check-field-radio-unchecked-focused-state {
    &::before {
        @include focus-style;

        box-shadow: $form-check-field-radio-control-focus-box-shadow;
    }
}

@mixin lora-helper-check-field-checkbox-checked-focused-state {
    &::before {
        box-shadow: $form-check-field-checkbox-checked-control-focus-box-shadow;
    }
}

@mixin lora-helper-check-field-radio-checked-focused-state {
    &::before {
        box-shadow: $form-check-field-radio-checked-control-focus-box-shadow;
    }
}

@mixin lora-helper-check-field-switch-unchecked-state {
    position: relative;
    padding: $form-check-field-switch-padding;
    display: inline-block;
    vertical-align: top;

    &::before {
        content: '';
        background: $form-check-field-switch-before-background;
        border-radius: $form-check-field-switch-before-border-radius;
        border: $form-check-field-switch-before-border;
        margin: $form-check-field-switch-before-margin;
        height: $form-check-field-switch-before-height;
        width: $form-check-field-switch-before-width;
        transition: $form-check-field-switch-before-transition;
        position: absolute;
        #{$global-right}: $form-check-field-switch-before-position--right;
        top: $form-check-field-switch-before-position--top;

        @media (forced-colors: active) {
            background: ButtonFace;
            border: 1px solid ButtonBorder;
        }
    }

    &::after {
        content: '';
        height: $form-check-field-switch-after-height;
        width: $form-check-field-switch-after-width;
        position: absolute;
        top: $form-check-field-switch-after-position--top;
        #{$global-right}: $form-check-field-switch-after-position--right;
        background: $form-check-field-switch-after-background;
        border-radius: $form-check-field-switch-after-border-radius;
        border: $form-check-field-switch-after-border;
        box-shadow: $form-check-field-switch-after-box-shadow;
        transition: $form-check-field-switch-after-transition;

        @media (forced-colors: active) {
            background: ButtonBorder;
        }
    }

    &:hover {
        &::after {
            box-shadow: $form-check-field-switch-after-hover-box-shadow;
        }
    }

    &:focus {
        &::after {
            box-shadow: $form-check-field-switch-after-focus-box-shadow;
        }
    }

    &:active {
        &::after {
            box-shadow: $form-check-field-switch-after-active-box-shadow;
        }
    }
}

@mixin lora-helper-check-field-switch-checked-state {
    font-weight: $form-check-field-switch-checked-font-weight;

    &::before {
        background: $form-check-field-switch-checked-before-background;
        border: $form-check-field-switch-checked-before-border;

        @media (forced-colors: active) {
            background: Highlight;
            border-color: ButtonText;
        }
    }

    &::after {
        background: $form-check-field-switch-checked-after-background;
        border: $form-check-field-switch-checked-after-border;
        #{$global-right}: $form-check-field-switch-checked-after-position--right;

        @media (forced-colors: active) {
            background: ButtonText;
            border: 1px solid ButtonText;
        }
    }

    &:hover {
        &::after {
            box-shadow: $form-check-field-switch-checked-after-hover-box-shadow;
        }
    }

    &:focus {
        &::after {
            box-shadow: $form-check-field-switch-checked-after-focus-box-shadow;
        }
    }

    &:active {
        &::after {
            box-shadow: $form-check-field-switch-checked-after-active-box-shadow;
        }
    }
}

@mixin lora-helper-check-field-switch-disabled-state {
    color: $form-check-field-switch-disabled-color;
    cursor: default;

    &::before {
        border-color: $form-check-field-switch-disabled-before-border-color;
        background: $form-check-field-switch-disabled-before-background;
    }

    &::after {
        border-color: $form-check-field-switch-disabled-after-border-color;
        background: $form-check-field-switch-disabled-after-background;
    }
}

@mixin lora-helper-check-field-switch-unchecked-focused-state {
    &::before {
        box-shadow: $form-check-field-switch-after-focus-box-shadow;
    }

    &::after {
        @include focus-style;
    }
}

@mixin lora-helper-check-field-switch-checked-focused-state {
    &::before {
        box-shadow: $form-check-field-switch-checked-after-focus-box-shadow;
    }

    &::after {
        @include focus-style;
    }
}

@mixin lora-form-check-field {
    .c-check-field {
        position: relative;
    }

    .c-check-field__input {
        width: 100%;
        height: 100%;
        #{$global-left}: 0;
        opacity: 0;
        position: absolute;
        cursor: pointer;
    }

    .c-check-field__label {
        display: inline-block;
        font-size: $form-check-field-label-font-size;
        font-weight: $form-check-field-label-font-weight;
        line-height: $form-check-field-label-height;
        position: relative;
        width: auto;
        outline-width: 0;
        text-transform: $form-check-field-label-text-transform;
        letter-spacing: $form-check-field-label-letter-spacing;

        &::before {
            @include lora-helper-check-field-label-before;
        }

        .c-form__row.m-error .c-check-field.m-radio & {
            @include lora-helper-check-field-radio-error-state;
        }
    }

    // Radio button control
    .c-check-field.m-radio {
        .c-check-field__input {
            height: $form-check-field-radio-control-after-size;
            width: $form-check-field-radio-control-after-size;
            margin-top: $form-check-field-radio-control-after-top;
        }
        // Radio button unchecked state
        .c-check-field__label {
            @include lora-helper-check-field-radio-unchecked-state;
        }
        // Radio button checked state
        .c-check-field__input:checked + .c-check-field__label {
            @include lora-helper-check-field-radio-checked-state;
        }
        // Radio button focused state
        &.m-focus .c-check-field__label,
        .c-check-field__input:focus + .c-check-field__label {
            @include lora-helper-check-field-radio-unchecked-focused-state;
        }
        // Radio button checked focused state
        &.m-focus .c-check-field__input:checked + .c-check-field__label,
        .c-check-field__input:checked:focus + .c-check-field__label {
            @include lora-helper-check-field-radio-checked-focused-state;
        }
        // Radio button disabled state
        .c-check-field__input:disabled + .c-check-field__label {
            @include lora-helper-check-field-radio-disabled-state;
        }

        .c-check-field__input:disabled:checked + .c-check-field__label {
            @include lora-helper-check-field-radio-disabled-checked-state;
        }
        // Radio button error state
        &.m-error .c-check-field__label {
            @include lora-helper-check-field-radio-error-state;
        }
    }
    // EO Radio button control

    // Checkbox button control
    .c-check-field.m-checkbox {
        .c-check-field__input {
            height: $form-check-field-checkbox-control-size;
            width: $form-check-field-checkbox-control-size;
            margin-top: $form-check-field-checkbox-control-margin-top;
        }

        // Checkbox button unchecked state
        .c-check-field__label {
            @include lora-helper-check-field-checkbox-unchecked-state;
        }
        // Checkbox button checked state
        .c-check-field__input:checked + .c-check-field__label {
            @include lora-helper-check-field-checkbox-checked-state;
        }
        // Checkbox button focused state
        &.m-focus .c-check-field__label,
        .c-check-field__input:focus + .c-check-field__label {
            @include lora-helper-check-field-checkbox-unchecked-focused-state;
        }
        // Checkbox button checked focused state
        &.m-focus .c-check-field__input:checked + .c-check-field__label,
        .c-check-field__input:checked:focus + .c-check-field__label {
            @include lora-helper-check-field-checkbox-checked-focused-state;
        }
        // Checkbox button disabled state
        .c-check-field__input:disabled + .c-check-field__label {
            @include lora-helper-check-field-checkbox-disabled-state;
        }

        .c-check-field__input:disabled:checked + .c-check-field__label {
            @include lora-helper-check-field-checkbox-disabled-checked-state;
        }
        // Checkbox button error state
        &.m-error .c-check-field__label {
            @include lora-helper-check-field-checkbox-error-state;
        }
    }
    // EO Checkbox button control

    @if ($form-check-field-switch-enabled) {
        // Switcher button control
        .c-check-field.m-switch {
            .c-check-field__input {
                margin: $form-check-field-switch-before-margin;
                height: $form-check-field-switch-before-height;
                width: $form-check-field-switch-before-width;
                #{$global-left}: auto;
                #{$global-right}: $form-check-field-switch-before-position--right;
            }

            // Switch button unchecked state
            .c-check-field__label {
                @include lora-helper-check-field-switch-unchecked-state;
            }
            // Switch button checked state
            .c-check-field__input:checked + .c-check-field__label {
                @include lora-helper-check-field-switch-checked-state;
            }
            // Switch button focused state
            &.m-focus .c-check-field__label,
            .c-check-field__input:focus + .c-check-field__label {
                @include lora-helper-check-field-switch-unchecked-focused-state;
            }
            // Switch button checked focused state
            &.m-focus .c-check-field__input:checked + .c-check-field__label,
            .c-check-field__input:checked:focus + .c-check-field__label {
                @include lora-helper-check-field-switch-checked-focused-state;
            }
            // Switch button disabled state
            .c-check-field__input:disabled + .c-check-field__label {
                @include lora-helper-check-field-switch-disabled-state;
            }
        }
    }

    .c-check-field.m-full {
        .c-check-field__label {
            display: block;
            width: 100%;
        }
    }

    .c-check-field.m-image {
        .c-check-field__label {
            display: flex;
            align-items: $form-check-field-image-label-align-items;
            min-height: $form-check-field-image-label-min-height;
            border: $form-check-field-image-label-border;
            width: $form-check-field-image-label-width;
            padding: $form-check-field-image-label-padding;

            &::before {
                flex-shrink: 0;
                #{$global-left}: $form-check-field-image-label-before-gutter;
            }

            .c-check-field__label-image {
                margin: $form-check-field-image-label-image-margin;
            }
        }

        .c-check-field__input {
            width: 100%;
            height: 100%;
        }

        .c-check-field__input:checked + .c-check-field__label {
            border: $form-check-field-image-checked-label-border;
        }

        .c-check-field__label-image {
            flex-shrink: 0;
        }
    }
}
