//stylelint-disable lora/variable-pattern
//stylelint-disable value-list-max-empty-lines, function-max-empty-lines
$palette-default: (
    light:     #fff, // White
    dark:      #202020,// Black
    transparent: transparent,
) !default;
$palette-custom: () !default;
$palette: map-extend($palette-default, $palette-custom) !default;

$dark-mode-colors: (
    background:           #202020,
    primary:              #1c304a,
    primary-active:       #305686,
    text:                 #fff,
    border:               #fff,
) !default;

$semantic-colors-default: (
    //#Core semantic colors
    primary:             #1c304a,
    primary-active:      #305686,
    secondary:           #013537,
    secondary-active:    #005e63,
    text:                color(dark),
    text-secondary:      #5f5f5f,
    success:             #46bf81,
    warning:             #ffae00,
    alert:               #b40a0a,
    disabled:            #767676,
    element-background:  #d8d8d8,
    global-background:   #f3f3f3,
    organism-background: #595959,
    primary-background:  #e0eafa,
    border:              #d8d8d8,
    overlay:             rgba(color(dark), 0.5),
    highlight:           #b73300,
    info:                #05408d,
    info-secondary:      #e9eef6,
    success-secondary:   #d8efe3,
    warning-secondary:   #fff0d3,
    error-secondary:     #ffd2d2
) !default;

$semantic-colors-custom: () !default;
$semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom) !default;
//stylelint-enable all
