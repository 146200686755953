/* stylelint-disable declaration-no-important */

////
/// @group font
////

@mixin lora-helpers-font {
    @each $name, $font in $global-font {
        .h-font-#{$name} {
            font-family: $font !important;
        }
    }
}
