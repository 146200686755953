@import "../accordion";

$header-navigation-flex: 1 0 0% !default;
$header-navigation-flex--large: none !default;
// Items lists
$header-navigation-list-margin: 0 !default;
$header-navigation-list-level-1-background: color(light) !default;
$header-navigation-list-level-1-justify-content--large: space-around !default;
$header-navigation-list-level-2-background: color(global-background) !default;
$header-navigation-list-level-2-padding--large: rem-calc(15 0) !default;
$header-navigation-list-level-2-margin--large: rem-calc(0 -10) !default;
// Container
$header-navigation-container-content-padding: rem-calc(10 15 15) !default;
$header-navigation-container-content-padding--xlarge: 0 !default;
// Navigation grid
$header-navigation-container-grid-spacing: rem-calc(24) !default;
$header-navigation-container-grid-display--xlarge: grid !default;
$header-navigation-container-grid-gap--xlarge: 0 $header-navigation-container-grid-spacing !default;
$header-navigation-container-grid-template-columns--xlarge: repeat(2, calc(50% - #{$header-navigation-container-grid-spacing/2})) !default;
$header-navigation-container-grid-template-rows--xlarge: 0fr 1fr !default;
$header-navigation-container-grid-padding--xlarge: rem-calc(15 0) !default;

// Navigation view all
$header-navigation-container-grid-view-all-grid-column--xlarge: 1 !default;
$header-navigation-container-grid-view-all-grid-row--xlarge: 1 !default;
/// View all link level 2
$header-navigation-container-grid-item-level-2-view-all-display--xlarge: block !default;
// Navigation main
$header-navigation-container-grid-main-list-grid-column--xlarge: 2 !default;
$header-navigation-container-grid-main-list-grid-row--xlarge: 1 / span 2 !default;
// Navigation container content
$header-navigation-container-grid-content-grid-column--xlarge: 1 !default;
$header-navigation-container-grid-content-grid-row--xlarge: 2 !default;
// Navigation flyout slot
$header-navigation-container-grid-flyout-slot-grid-column--xlarge: 1 / span 2 !default;
$header-navigation-container-grid-flyout-slot-grid-row--xlarge: 3 !default;
// Navigation list level 2
$header-navigation-container-grid-list-level-2-padding--large: rem-calc(0 0 15) !default;
$header-navigation-container-grid-list-level-2-gap--large: rem-calc(15 0) !default;
// Navigation Item level 2
$header-navigation-container-grid-item-level-2-width--xlarge: 100% / 3 !default;
// Items
/// Item level 1
$header-navigation-item-level-1-border-bottom: $global-border !default;
$header-navigation-item-level-1-first-border-top: $global-border !default;
$header-navigation-item-level-1-margin--large: 0 !default;
$header-navigation-item-level-1-white-space--large: normal !default;
$header-navigation-item-level-1-flex-grow--large: null !default;
/// Item level 2
$header-navigation-item-level-2-padding--large: rem-calc(5 10 0) !default;
$header-navigation-item-level-2-columns--large: 4 !default;
$header-navigation-item-level-2-width--large: (100% / $header-navigation-item-level-2-columns--large) !default;
$header-navigation-item-level-2-border: 1px solid transparent !default;
$header-navigation-item-level-2-border-width: rem-calc(1 0) !default;
$header-navigation-item-level-2-expanded-border: $global-border !default;
$header-navigation-item-level-2-expanded-border-width: 0 !default;
$header-navigation-item-level-2-first-child-border-top: null !default;
$header-navigation-item-level-2-last-child-border-bottom: none !default;
/// View all link in level 2
$header-navigation-item-level-2-view-all-border: none !default;
$header-navigation-item-level-2-view-all-border-width: null !default;
$header-navigation-item-level-2-view-all-display--xlarge: none !default;
/// View all link in level 2
$header-navigation-item-level-2-all-link-font: #{rem-calc(14)} / 1.4 $font-primary !default;
$header-navigation-item-level-2-all-link-font--large: null !default;
$header-navigation-item-level-2-all-link-text-decoration: null !default;
$header-navigation-item-level-2-all-link-text-decoration--large: null !default;
$header-navigation-item-level-2-all-link-text-transform: none !default;
$header-navigation-item-level-2-all-link-text-transform--large: null !default;
$header-navigation-item-level-2-all-link-color: null !default;
$header-navigation-item-level-2-all-link-color--large: null !default;
/// View all link in level 3
$header-navigation-item-level-3-all-link-font: null !default;
$header-navigation-item-level-3-all-link-font--large: null !default;
$header-navigation-item-level-3-all-link-text-decoration: null !default;
$header-navigation-item-level-3-all-link-text-decoration--large: null !default;
$header-navigation-item-level-3-all-link-text-transform: none !default;
$header-navigation-item-level-3-all-link-text-transform--large: null !default;
$header-navigation-item-level-3-all-link-color: null !default;
$header-navigation-item-level-3-all-link-color--large: null !default;
$header-navigation-item-level-3-all-link-margin--large: null !default;
$header-navigation-list-level-3-margin--large: 0 !default;
$header-navigation-item-level-3-border: none !default;
$header-navigation-item-level-3-border-width: null !default;
$header-navigation-item-level-3-first-border: null !default;
$header-navigation-item-level-3-first-border-width: null !default;
$header-navigation-item-level-3-column-gap: rem-calc(10) !default;
$header-navigation-item-level-3-padding--large: rem-calc(5 0) !default;
$header-navigation-item-level-3-white-space--large: normal !default;
$header-navigation-list-level-3-background--large-down: color(light) !default;
//// View all link in level 3 hover
$header-navigation-item-level-3-all-link-hover-color: null !default;
$header-navigation-item-level-3-all-link-hover-text-decoration--large: null !default;
// Item titles
$header-navigation-item-title-padding: rem-calc(14 0) !default;
$header-navigation-item-title-transition: color 0.3s ease-in-out !default;
$header-navigation-item-title-level-1-font: #{rem-calc(14)} / 1.4 $font-primary !default;
$header-navigation-item-title-level-1-font--large: null !default;
$header-navigation-item-title-level-2-font: #{rem-calc(14)} / 1.4 $font-primary !default;
$header-navigation-item-title-level-2-font--large: null !default;
$header-navigation-item-title-level-3-font-size: null !default;
$header-navigation-item-title-level-3-font-size--large: null !default;
$header-navigation-item-title-level-1-color: color(text) !default;
$header-navigation-item-title-level-2-color: color(text) !default;
$header-navigation-item-title-level-3-color: color(text) !default;
$header-navigation-item-title-level-1-color--large: null !default;
$header-navigation-item-title-level-2-color--large: null !default;
$header-navigation-item-title-level-3-color--large: null !default;
$header-navigation-item-title-level-3-line-height--large: null !default;
$header-navigation-item-title-level-1-padding-left: rem-calc(16) !default;
$header-navigation-item-title-level-2-padding-left: rem-calc(16) !default;
$header-navigation-item-title-level-1-expanded-background: null !default;
$header-navigation-item-title-level-1-expanded-color: color(primary) !default;
$header-navigation-item-title-level-1-expanded-font: 700 #{rem-calc(14)} / 1.4 $font-primary !default;
$header-navigation-item-title-level-2-expanded-background: null !default;
$header-navigation-item-title-level-2-expanded-color: color(primary) !default;
$header-navigation-item-title-level-2-expanded-font: 700 #{rem-calc(14)} / 1.4 $font-primary !default;
$header-navigation-item-title-level-2-background: null !default;
$header-navigation-item-title-level-2-align-items--large: flex-start !default;
$header-navigation-item-title-level-3-background: null !default;
// Item accordion controls
$header-navigation-item-title-accordion-padding: rem-calc(14 50 14 0) !default;
$header-navigation-item-title-accordion-level-1-border: null !default;
$header-navigation-item-title-accordion-level-1-border-width: null !default;
$header-navigation-item-title-accordion-level-1-expanded-border: $global-border !default;
$header-navigation-item-title-accordion-level-1-expanded-border-width: rem-calc(0 0 1) !default;
/// Accordion icon
$header-navigation-item-title-accordion-icon: 'chevron2-down' !default;
$header-navigation-item-title-accordion-icon-usesvg: true !default;
$header-navigation-item-title-accordion-icon-color: color(dark) !default;
$header-navigation-item-title-accordion-icon-size: rem-calc(16) !default;
$header-navigation-item-title-accordion-icon-position-side: rem-calc(16) !default;
$header-navigation-item-title-accordion-icon-transition: transform 0.3s ease-in-out !default;
/// Expanded icon
$header-navigation-item-title-accordion-icon-expanded-transform: translateY(-50%) rotate(180deg) !default;
// Navigation item level 1
$header-navigation-item-button-level-1-focus-icon: "chevron-down" !default;
$header-navigation-item-button-level-1-focus-icon-color: color(primary) !default;
$header-navigation-item-button-level-1-focus-icon-height: rem-calc(14) !default;
$header-navigation-item-button-level-1-focus-icon-width: rem-calc(8) !default;
$header-navigation-item-button-level-1-focus-icon-bottom: rem-calc(3) !default;
$header-navigation-item-button-level-1-focus-icon-usesvg: true !default;
$header-navigation-item-button-level-1-expanded-focus-icon: "chevron-top" !default;
$header-navigation-item-button-level-1-expanded-focus-icon-usesvg: true !default;
// Links
$header-navigation-link-color: null !default;
$header-navigation-link-font-weight: null !default;
$header-navigation-link-hover-font-weight: null !default;
$header-navigation-link-font-size: null !default;
$header-navigation-link-letter-spacing: null !default;
$header-navigation-link-letter-spacing--large: null !default;
$header-navigation-link-margin: 0 !default;
$header-navigation-link-text-decoration: none !default;
$header-navigation-link-text-transform: none !default;
// Link image
$header-navigation-link-image-margin: rem-calc(-6 0 -6 -8) !default;
$header-navigation-link-image-flex: 0 0 auto !default;
// Link badge
$header-navigation-link-badge-margin: null !default;
/// Link level 1
$header-navigation-link-level-1-color: inherit !default;
$header-navigation-link-level-1-color--large: null !default;
$header-navigation-link-level-1-font-family: null !default;
$header-navigation-link-level-1-font-family--large: null !default;
$header-navigation-link-level-1-font-weight: null !default;
$header-navigation-link-level-1-font-weight--large: null !default;
$header-navigation-link-level-1-font-size: null !default;
$header-navigation-link-level-1-font-size--large: null !default;
$header-navigation-link-level-1-text-transform: null !default;
$header-navigation-link-level-1-text-transform--large: null !default;
$header-navigation-link-level-1-text-align--large: null !default;
$header-navigation-link-level-1-letter-spacing--large: null !default;
$header-navigation-link-level-1-padding--large: rem-calc(14 16) !default;
//// Hover link level 1
$header-navigation-link-level-1-hover-color: null !default;
$header-navigation-link-level-1-hover-color--large: color(primary) !default;
$header-navigation-link-level-1-hover-font-weight: null !default;
$header-navigation-link-level-1-hover-font-weight--large: 700 !default;
$header-navigation-link-level-1-hover-text-decoration: null !default;
$header-navigation-link-level-1-hover-text-decoration--large: null !default;
$header-navigation-link-level-1-hover-before-height--large: rem-calc(3) !default;
$header-navigation-link-level-1-hover-before-background-color--large: color(primary) !default;
/// Link level 2
$header-navigation-link-level-2-color: inherit !default;
$header-navigation-link-level-2-color--large: null !default;
$header-navigation-link-level-2-font-weight: null !default;
$header-navigation-link-level-2-font-weight--large: bold !default;
$header-navigation-link-level-2-font-size: null !default;
$header-navigation-link-level-2-font-size--large: null !default;
$header-navigation-link-level-2-text-transform--large: null !default;
$header-navigation-link-level-2-font-family: null !default;
$header-navigation-link-level-2-font-family--large: null !default;
$header-navigation-link-level-2-text-transform: null !default;
$header-navigation-link-level-2-letter-spacing--large: null !default;
//// Hover link level 2
$header-navigation-link-level-2-hover-color: color(text-secondary) !default;
$header-navigation-link-level-2-hover-font-weight: null !default;
$header-navigation-link-level-2-hover-text-decoration: underline !default;
/// Link level 3
$header-navigation-link-level-3-color: null !default;
$header-navigation-link-level-3-color--large: null !default;
$header-navigation-link-level-3-text-transform: null !default;
$header-navigation-link-level-3-font: #{rem-calc(14)} / 1.4 $font-primary !default;
$header-navigation-link-level-3-font--large: null !default;
$header-navigation-link-level-3-padding--medium-down: rem-calc(10 0 10 30) !default;
$header-navigation-link-level-3-text-transform--large: null !default;
//// Hover link level 3
$header-navigation-link-level-3-hover-color: color(text-secondary) !default;
$header-navigation-link-level-3-hover-font-weight: null !default;
$header-navigation-link-level-3-hover-text-decoration: underline !default;
// Flyout
$header-navigation-flyout-element-show-arrow: false !default;
$header-navigation-flyout-element-background--large-down: color(global-background) !default;
$header-navigation-flyout-element-background--large: color(light) !default;
$header-navigation-flyout-element-border-bottom--large: null !default;
$header-navigation-flyout-element-border-top--large: $global-border !default;
$header-navigation-flyout-element-margin-left--large: -5px !default;
$header-navigation-flyout-element-box-shadow--large: none !default;
$header-navigation-flyout-element-before-background--large: color(overlay) !default;
$header-navigation-flyout-slot-padding--large: rem-calc(0) !default;

// Content tile
$header-navigation-content-color--large: color(text) !default;
$header-navigation-content-hover-color--large: $header-navigation-content-color--large !default;
$header-navigation-content-font--large: bold #{rem-calc(14)} / 1.5 $font-primary !default;
$header-navigation-content-hover-font--large: $header-navigation-content-font--large !default;
$header-navigation-content-margin--large: rem-calc(0 35 0 0) !default;
$header-navigation-content-text-decoration--large: none !default;
$header-navigation-content-title-color--large: null !default;
$header-navigation-content-title-font--large: null !default;
$header-navigation-content-title-margin--large: rem-calc(20 0 0) !default;
$header-navigation-content-title-text-transform--large: uppercase !default;

// Accordion content
$header-navigation-accordion-content-padding: rem-calc(12 16) !default;

// Vertical mode for desktop 2-level Navigation
$header-navigation-vertical-navigation-list-level-2-align-content--large: flex-start !default;
$header-navigation-vertical-navigation-list-level-2-flex-wrap--large: wrap !default;
$header-navigation-vertical-navigation-list-level-2-flex-flow--large: column $header-navigation-vertical-navigation-list-level-2-flex-wrap--large !default;
$header-navigation-vertical-navigation-list-level-2-max-height--large: rem-calc(390) !default;
$header-navigation-vertical-navigation-list-level-2-margin--large: 0 !default;
$header-navigation-vertical-navigation-list-level-2-gap--large: 0 !default;
/// Item level 2
$header-navigation-vertical-navigation-item-level-2-padding--large: rem-calc(5 0) !default;
/// Item title level 2
$header-navigation-vertical-navigation-item-title-level-2-color--large: null !default;
$header-navigation-vertical-navigation-item-title-level-2-font-size--large: null !default;
$header-navigation-vertical-navigation-item-title-level-2-font-weight--large: 400 !default;
// Item icon
// Left and right icon
$header-navigation-item-icon-item-icon-width: rem-calc(20) !default;
$header-navigation-item-icon-item-icon-padding: rem-calc(8) !default;
$header-navigation-link-item-icon-link-hover-name-shift--large: null !default; // fix for jumping on hover
// Top icon
$header-navigation-item-icon-top-item-icon-top: rem-calc(-14) !default;
$header-navigation-item-icon-top-item-icon-top--large: rem-calc(-13) !default;
$header-navigation-item-icon-top-item-icon-bottom: 0 !default;
$header-navigation-item-icon-top-item-icon-bottom--large: 0 !default;

@mixin lora-helper-navigation-control-expanded {
    & > .c-navigation__icon::after {
        transform: $header-navigation-item-title-accordion-icon-expanded-transform;
    }

    &.m-level-1 {
        border: $header-navigation-item-title-accordion-level-1-expanded-border;
        border-width: $header-navigation-item-title-accordion-level-1-expanded-border-width;
    }
}

@mixin lora-helper-navigation-control-collapsed {
    position: relative;
    width: 100%;
    padding: $header-navigation-item-title-accordion-padding;

    &:is(button),
    &:is(a) {
        cursor: pointer;
    }

    & > .c-navigation__icon::after {
        @include svg-icon($header-navigation-item-title-accordion-icon, $header-navigation-item-title-accordion-icon-color, 100%, center, no-repeat, $usesvg: $header-navigation-item-title-accordion-icon-usesvg);
        @include vertical-center;

        content: '';
        #{$global-right}: $header-navigation-item-title-accordion-icon-position-side;
        transition: $header-navigation-item-title-accordion-icon-transition;
        width: $header-navigation-item-title-accordion-icon-size;
        height: $header-navigation-item-title-accordion-icon-size;
    }

    &.m-level-1 {
        border: $header-navigation-item-title-accordion-level-1-border;
        border-width: $header-navigation-item-title-accordion-level-1-border-width;
    }
}

@mixin lora-components-navigation {
    .c-navigation {
        align-items: stretch;
        display: flex;
        flex: $header-navigation-flex;

        @include breakpoint(xlarge) {
            flex: $header-navigation-flex--large;
        }
    }

    // LISTS
    .c-navigation__list {
        list-style: none;
        margin: $header-navigation-list-margin;
    }

    .c-navigation__container {
        @include breakpoint(large down) {
            @include lora-animation-collapsed;
        }

        &.m-navigation-grid {
            @include breakpoint(xlarge) {
                display: $header-navigation-container-grid-display--xlarge;
                grid-template-columns: $header-navigation-container-grid-template-columns--xlarge;
                grid-template-rows: $header-navigation-container-grid-template-rows--xlarge;
                gap: $header-navigation-container-grid-gap--xlarge;
                padding: $header-navigation-container-grid-padding--xlarge;

                .c-navigation__view-all {
                    grid-column: $header-navigation-container-grid-view-all-grid-column--xlarge;
                    grid-row: $header-navigation-container-grid-view-all-grid-row--xlarge;
                }

                .c-navigation__main-list {
                    grid-column: $header-navigation-container-grid-main-list-grid-column--xlarge;
                    grid-row: $header-navigation-container-grid-main-list-grid-row--xlarge;
                }

                .c-navigation__container-content {
                    grid-column: $header-navigation-container-grid-content-grid-column--xlarge;
                    grid-row: $header-navigation-container-grid-content-grid-row--xlarge;
                }

                .c-navigation__flyout-slot {
                    grid-column: $header-navigation-container-grid-flyout-slot-grid-column--xlarge;
                    grid-row: $header-navigation-container-grid-flyout-slot-grid-row--xlarge;
                }

                .c-navigation__list.m-level-2 {
                    padding: $header-navigation-container-grid-list-level-2-padding--large;
                }

                .c-navigation__list.m-level-2:not(.m-view-all) {
                    flex-wrap: wrap;
                    gap: $header-navigation-container-grid-list-level-2-gap--large;
                }

                .c-navigation__item.m-level-2:not(.m-view-all) {
                    width: $header-navigation-container-grid-item-level-2-width--xlarge;
                }

                .c-navigation__item.m-level-2.m-view-all {
                    display: $header-navigation-container-grid-item-level-2-view-all-display--xlarge;
                    width: 100%;
                }
            }
        }
    }

    .c-navigation__container-content {
        padding: $header-navigation-container-content-padding;

        @include breakpoint(xlarge) {
            padding: $header-navigation-container-content-padding--xlarge;
        }
    }

    .c-navigation__list.m-level-1 {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
        width: 100%;

        @include breakpoint(large down) {
            background: $header-navigation-list-level-1-background;
        }
        @include breakpoint(xlarge) {
            align-items: stretch;
            flex-flow: row nowrap;
            justify-content: $header-navigation-list-level-1-justify-content--large;
        }
    }

    .c-navigation__list.m-level-2 {
        display: flex;

        @include breakpoint(large down) {
            flex-flow: column nowrap;
            background: $header-navigation-list-level-2-background;
        }
        @include breakpoint(xlarge) {
            flex-flow: row nowrap;
            padding: $header-navigation-list-level-2-padding--large;
            margin: $header-navigation-list-level-2-margin--large;
        }

        & + & {
            @include breakpoint(xlarge) {
                padding-top: 0;
            }
        }
    }

    .c-navigation__list.m-level-3 {
        @include breakpoint(large down) {
            background: $header-navigation-list-level-3-background--large-down;
        }

        @include breakpoint(xlarge) {
            display: flex;
            flex-flow: column;
            margin: $header-navigation-list-level-3-margin--large;
        }
    }

    // EO LISTS

    // ITEMS
    .c-navigation__item {
        display: block;
        text-align: $global-left;
    }

    .c-navigation__item.m-expanded > .c-navigation__container,
    .c-navigation__item.m-level-1.m-expanded .c-navigation__container:first-child {
        @include breakpoint(large down) {
            @include lora-animation-expanded;
        }
    }

    .c-navigation__item.m-level-1 {
        @include breakpoint(large down) {
            border-bottom: $header-navigation-item-level-1-border-bottom;

            &:first-child {
                border-top: $header-navigation-item-level-1-first-border-top;
            }
        }

        @include breakpoint(xlarge) {
            align-items: stretch;
            display: flex;
            margin: $header-navigation-item-level-1-margin--large;
            white-space: $header-navigation-item-level-1-white-space--large;
            flex-grow: $header-navigation-item-level-1-flex-grow--large;
        }
    }

    // stylelint-disable max-nesting-depth
    .c-navigation__item.m-level-2 {
        @include breakpoint(large down) {
            border: $header-navigation-item-level-2-border;
            border-width: $header-navigation-item-level-2-border-width;

            &.m-expanded:not(:first-child) {
                border: $header-navigation-item-level-2-expanded-border;
                border-width: $header-navigation-item-level-2-expanded-border-width;
            }

            &:first-child {
                border-top: $header-navigation-item-level-2-first-child-border-top;
            }

            &:last-child {
                border-bottom: $header-navigation-item-level-2-last-child-border-bottom;
            }
        }

        @include breakpoint(xlarge) {
            padding: $header-navigation-item-level-2-padding--large;
            width: $header-navigation-item-level-2-width--large;

            @for $i from 2 through $header-navigation-item-level-2-columns--large {
                &.m-column-#{$i} {
                    width: $i * $header-navigation-item-level-2-width--large;

                    .c-navigation__list.m-level-3 {
                        display: list-item;
                        columns: $i;
                        column-gap: $header-navigation-item-level-3-column-gap;
                    }
                }
            }
        }
    }
    // stylelint-enable max-nesting-depth

    .c-navigation__item.m-level-2.m-view-all {
        @include breakpoint(large down) {
            border: $header-navigation-item-level-2-view-all-border;
            border-width: $header-navigation-item-level-2-view-all-border-width;
        }

        @include breakpoint(xlarge) {
            display: $header-navigation-item-level-2-view-all-display--xlarge;
        }

        .c-navigation__link {
            color: $header-navigation-item-level-2-all-link-color;
            font: $header-navigation-item-level-2-all-link-font;
            text-transform: $header-navigation-item-level-2-all-link-text-transform;
            text-decoration: $header-navigation-item-level-2-all-link-text-decoration;

            @include breakpoint(xlarge) {
                color: $header-navigation-item-level-2-all-link-color--large;
                font: $header-navigation-item-level-2-all-link-font--large;
                text-transform: $header-navigation-item-level-2-all-link-text-transform--large;
                text-decoration: $header-navigation-item-level-2-all-link-text-decoration--large;
            }
        }
    }

    .c-navigation__item.m-level-3 {
        @include breakpoint(large down) {
            border: $header-navigation-item-level-3-border;
            border-width: $header-navigation-item-level-3-border-width;
        }

        @include breakpoint(xlarge) {
            padding: $header-navigation-item-level-3-padding--large;
            white-space: $header-navigation-item-level-3-white-space--large;

            &.m-break-after {
                break-after: column;
            }
        }

        &:first-child {
            @include breakpoint(large down) {
                border: $header-navigation-item-level-3-first-border;
                border-width: $header-navigation-item-level-3-first-border-width;
            }
        }
    }

    .c-navigation__item.m-level-3.m-view-all {
        .c-navigation__link {
            color: $header-navigation-item-level-3-all-link-color;
            font: $header-navigation-item-level-3-all-link-font;
            text-transform: $header-navigation-item-level-3-all-link-text-transform;
            text-decoration: $header-navigation-item-level-3-all-link-text-decoration;

            @include breakpoint(xlarge) {
                color: $header-navigation-item-level-3-all-link-color--large;
                font: $header-navigation-item-level-3-all-link-font--large;
                text-transform: $header-navigation-item-level-3-all-link-text-transform--large;
                text-decoration: $header-navigation-item-level-3-all-link-text-decoration--large;
                margin: $header-navigation-item-level-3-all-link-margin--large;
            }

            &:is(a):hover {
                color: $header-navigation-item-level-3-all-link-hover-color;
                text-decoration: $header-navigation-item-level-3-all-link-hover-text-decoration--large;
            }
        }
    }
    // EO ITEMS

    // TITLES
    .c-navigation__item-title {
        align-items: center;
        display: flex;

        @include breakpoint(large down) {
            justify-content: space-between;
            padding: $header-navigation-item-title-padding;
            transition: $header-navigation-item-title-transition;
        }
    }

    // ITEM ACCORDION CONTROLS
    .c-navigation__item-title.m-accordion-control {
        @include breakpoint(large down) {
            @include lora-helper-navigation-control-collapsed;
        }
    }

    .c-navigation__item.m-expanded > .c-navigation__item-title.m-accordion-control {
        @include breakpoint(large down) {
            @include lora-helper-navigation-control-expanded;
        }
    }
    // EO ITEM ACCORDION CONTROLS

    .c-navigation__item-title.m-level-1 {
        color: $header-navigation-item-title-level-1-color;
        font: $header-navigation-item-title-level-1-font;
        position: relative;

        @include breakpoint(large down) {
            padding-#{$global-left}: $header-navigation-item-title-level-1-padding-left;
        }

        @include breakpoint(xlarge) {
            font: $header-navigation-item-title-level-1-font--large;
            color: $header-navigation-item-title-level-1-color--large;
            z-index: z(header, navigationTitle);
        }
    }

    @if $header-navigation-flyout-element-show-arrow {
        .c-navigation__item-title.m-level-1::before { // arrow at the bottom of the top link
            @include breakpoint(xlarge) {
                background: $header-navigation-flyout-element-background--large;
                border: $header-navigation-flyout-element-border-top--large;
                border-bottom: 0;
                border-#{$global-left}: 0;
                content: '';
                height: 10px;
                #{$global-left}: 50%;
                margin-#{$global-left}: $header-navigation-flyout-element-margin-left--large;
                opacity: 0;
                position: absolute;
                top: 100%;
                transform-origin: 0 0;
                transform: rotateZ(-45deg);
                transition: opacity 0s;
                width: 10px;
            }
        }
    }

    .c-navigation__item.m-opened .c-navigation__item-title::before,
    .c-navigation__item.m-opened:focus .c-navigation__item-title::before {
        @include breakpoint(xlarge) {
            opacity: 1;
        }
    }

    .c-navigation__item-title.m-level-2 {
        color: $header-navigation-item-title-level-2-color;
        font: $header-navigation-item-title-level-2-font;

        @include breakpoint(large down) {
            background: $header-navigation-item-title-level-2-background;
            padding-#{$global-left}: $header-navigation-item-title-level-2-padding-left;
        }

        @include breakpoint(xlarge) {
            color: $header-navigation-item-title-level-2-color--large;
            font: $header-navigation-item-title-level-2-font--large;
            align-items: $header-navigation-item-title-level-2-align-items--large;
        }
    }

    .c-navigation__item-title.m-level-3 {
        color: $header-navigation-item-title-level-3-color;
        font-size: $header-navigation-item-title-level-3-font-size;

        @include breakpoint(large down) {
            background: $header-navigation-item-title-level-3-background;
            padding: 0;
        }
        @include breakpoint(xlarge) {
            font-size: $header-navigation-item-title-level-3-font-size--large;
            color: $header-navigation-item-title-level-3-color--large;
            line-height: $header-navigation-item-title-level-3-line-height--large;
        }
    }
    // EO ITEM

    // ITEM EXPANDED TITLE
    .c-navigation__item.m-level-1.m-expanded > .c-navigation__item-title {
        @include breakpoint(large down) {
            background: $header-navigation-item-title-level-1-expanded-background;
            color: $header-navigation-item-title-level-1-expanded-color;
            font: $header-navigation-item-title-level-1-expanded-font;
        }
    }

    .c-navigation__item.m-level-2.m-expanded > .c-navigation__item-title {
        @include breakpoint(large down) {
            background: $header-navigation-item-title-level-2-expanded-background;
            color: $header-navigation-item-title-level-2-expanded-color;
            font: $header-navigation-item-title-level-2-expanded-font;
        }
    }
    // EO ITEM EXPANDED TITLE

    // NAVIGATION FLYOUT (for large viewports)
    .c-navigation__flyout-element {
        @include breakpoint(large down) {
            background: $header-navigation-flyout-element-background--large-down;
        }

        @include breakpoint(xlarge) {
            background: $header-navigation-flyout-element-background--large;
            border-bottom: $header-navigation-flyout-element-border-bottom--large;
            border-top: $header-navigation-flyout-element-border-top--large;
            box-shadow: $header-navigation-flyout-element-box-shadow--large;
            #{$global-left}: 0;
            margin: 0 -50vw;
            padding: 0 50vw;
            position: absolute;
            #{$global-right}: 0;
            top: 100%;
            display: none;
            z-index: z(header, meganav);
        }

        &::before {
            @include breakpoint(xlarge) {
                content: '';
                position: absolute;
                width: 100%;
                left: 0;
                top: 100%;
                height: vh(100);
                pointer-events: none;
                background: $header-navigation-flyout-element-before-background--large;
            }
        }
    }

    .c-navigation.m-init .c-navigation__flyout-element {
        @include breakpoint(xlarge) {
            display: block;
            visibility: hidden;
        }
    }

    .c-navigation__item.m-opened .c-navigation__flyout-element {
        @include breakpoint(xlarge) {
            display: block;
        }
    }
    // EO NAVIGATION FLYOUT (for large viewports)

    // LINKS
    .c-navigation__link {
        @include bold-link;

        color: $header-navigation-link-color;
        position: relative;
        display: inline-block;
        font-size: $header-navigation-link-font-size;
        font-weight: $header-navigation-link-font-weight;
        margin: $header-navigation-link-margin;
        text-decoration: $header-navigation-link-text-decoration;
        text-transform: $header-navigation-link-text-transform;
        z-index: 2; // stylelint-disable-line
        letter-spacing: $header-navigation-link-letter-spacing;

        @include breakpoint(xlarge) {
            display: block;
            letter-spacing: $header-navigation-link-letter-spacing--large;
        }

        &:is(a):hover {
            color: $header-navigation-link-color;
            font-weight: $header-navigation-link-hover-font-weight;
        }

        &:is(span) {
            @include breakpoint(large down) {
                pointer-events: none;
            }
        }
    }

    .c-navigation__link.m-without-subcategories {
        flex-grow: 1;
    }

    .c-navigation__link.m-not-clickable-for-mobile {
        @include breakpoint(large down) {
            pointer-events: none;
        }
    }

    .c-navigation__link.m-level-1 {
        color: $header-navigation-link-level-1-color;
        font-family: $header-navigation-link-level-1-font-family;
        font-weight: $header-navigation-link-level-1-font-weight;
        font-size: $header-navigation-link-level-1-font-size;
        text-transform: $header-navigation-link-level-1-text-transform;
        height: 100%;

        @include breakpoint(xlarge) {
            color: $header-navigation-link-level-1-color--large;
            font-weight: $header-navigation-link-level-1-font-weight--large;
            font-size: $header-navigation-link-level-1-font-size--large;
            font-family: $header-navigation-link-level-1-font-family--large;
            padding: $header-navigation-link-level-1-padding--large;
            position: relative;
            text-transform: $header-navigation-link-level-1-text-transform--large;
            text-align: $header-navigation-link-level-1-text-align--large;
            letter-spacing: $header-navigation-link-level-1-letter-spacing--large;
        }

        &:is(a):hover,
        .c-navigation__item.m-opened & {
            color: $header-navigation-link-level-1-hover-color;
            font-weight: $header-navigation-link-level-1-hover-font-weight;
            text-decoration: $header-navigation-link-level-1-hover-text-decoration;

            @include breakpoint(xlarge) {
                color: $header-navigation-link-level-1-hover-color--large;
                text-decoration: $header-navigation-link-level-1-hover-text-decoration--large;
                font-weight: $header-navigation-link-level-1-hover-font-weight--large;

                &::before {
                    content: '';
                    height: $header-navigation-link-level-1-hover-before-height--large;
                    background-color: $header-navigation-link-level-1-hover-before-background-color--large;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
        }
    }

    .c-navigation__link.m-level-2 {
        color: $header-navigation-link-level-2-color;
        font-family: $header-navigation-link-level-2-font-family;
        font-size: $header-navigation-link-level-2-font-size;
        font-weight: $header-navigation-link-level-2-font-weight;
        text-transform: $header-navigation-link-level-2-text-transform;

        @include breakpoint(xlarge) {
            color: $header-navigation-link-level-2-color--large;
            font-family: $header-navigation-link-level-2-font-family--large;
            font-weight: $header-navigation-link-level-2-font-weight--large;
            font-size: $header-navigation-link-level-2-font-size--large;
            text-transform: $header-navigation-link-level-2-text-transform--large;
            letter-spacing: $header-navigation-link-level-2-letter-spacing--large;
            flex-grow: 0;
        }

        &:is(a):hover {
            color: $header-navigation-link-level-2-hover-color;
            font-weight: $header-navigation-link-level-2-hover-font-weight;
            text-decoration: $header-navigation-link-level-2-hover-text-decoration;
        }
    }

    .c-navigation__link.m-level-3 {
        color: $header-navigation-link-level-3-color;
        text-transform: $header-navigation-link-level-3-text-transform;
        font: $header-navigation-link-level-3-font;

        @include breakpoint(large down) {
            padding: $header-navigation-link-level-3-padding--medium-down;
        }

        @include breakpoint(xlarge) {
            color: $header-navigation-link-level-3-color--large;
            font: $header-navigation-link-level-3-font--large;
            text-transform: $header-navigation-link-level-3-text-transform--large;
            flex-grow: 0;
        }

        &:is(a):hover {
            color: $header-navigation-link-level-3-hover-color;
            font-weight: $header-navigation-link-level-3-hover-font-weight;
            text-decoration: $header-navigation-link-level-3-hover-text-decoration;
        }
    }

    // EO LINKS

    // ACCESSABILITY BUTTON
    .c-navigation__button {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1; // stylelint-disable-line

        @include breakpoint(xlarge) {
            pointer-events: none;
        }
    }

    .c-navigation__button.m-level-1:focus {
        &::after {
            @include breakpoint(xlarge) {
                @include svg-icon($header-navigation-item-button-level-1-focus-icon, $header-navigation-item-button-level-1-focus-icon-color, 100%, center, no-repeat, $usesvg: $header-navigation-item-button-level-1-focus-icon-usesvg);
                @include horizontal-center;

                content: '';
                height: $header-navigation-item-button-level-1-focus-icon-height;
                width: $header-navigation-item-button-level-1-focus-icon-width;
                bottom: $header-navigation-item-button-level-1-focus-icon-bottom;
            }
        }

        .c-navigation__item.m-opened & {
            &::after {
                @include breakpoint(xlarge) {
                    @include svg-icon($header-navigation-item-button-level-1-expanded-focus-icon, $header-navigation-item-button-level-1-focus-icon-color, 100%, center, no-repeat, $usesvg: $header-navigation-item-button-level-1-expanded-focus-icon-usesvg);
                }
            }
        }
    }

    .c-navigation__button.m-level-2 {
        @include breakpoint(xlarge) {
            display: none;
        }
    }
    // EO ACCESSABILITY BUTTON

    .c-navigation__flyout-slot {
        @include breakpoint(large down) {
            display: none;
        }

        @include breakpoint(xlarge) {
            padding: $header-navigation-flyout-slot-padding--large;
        }
    }

    .c-navigation .c-navigation-flyout {
        @include layout;

        margin: 0 auto;
    }

    .c-navigation__content {
        display: block;

        @include breakpoint(xlarge) {
            color: $header-navigation-content-color--large;
            font: $header-navigation-content-font--large;
            margin: $header-navigation-content-margin--large;
            text-decoration: $header-navigation-content-text-decoration--large;
        }

        &:is(a):hover,
        &:focus {
            @include breakpoint(xlarge) {
                color: $header-navigation-content-hover-color--large;
                font: $header-navigation-content-hover-font--large;
            }
        }
    }

    .c-navigation__content-title {
        @include breakpoint(xlarge) {
            color: $header-navigation-content-title-color--large;
            font: $header-navigation-content-title-font--large;
            margin: $header-navigation-content-title-margin--large;
            text-transform: $header-navigation-content-title-text-transform--large;
        }
    }

    .c-navigation__accordion-content {
        padding: $header-navigation-accordion-content-padding;
    }

    .c-navigation__item.m-level-1.m-vertical {
        .c-navigation__list.m-level-2 {
            @include breakpoint(xlarge) {
                align-content: $header-navigation-vertical-navigation-list-level-2-align-content--large;
                flex-flow: $header-navigation-vertical-navigation-list-level-2-flex-flow--large;
                max-height: $header-navigation-vertical-navigation-list-level-2-max-height--large;
                width: 100%;
                margin: $header-navigation-vertical-navigation-list-level-2-margin--large;
                gap: $header-navigation-vertical-navigation-list-level-2-gap--large;
            }
        }

        .c-navigation__item.m-level-2 {
            @include breakpoint(xlarge) {
                padding: $header-navigation-vertical-navigation-item-level-2-padding--large;
            }
        }

        .c-navigation__item-title.m-level-2,
        .c-navigation__link.m-level-2 {
            @include breakpoint(xlarge) {
                color: $header-navigation-vertical-navigation-item-title-level-2-color--large;
                font-size: $header-navigation-vertical-navigation-item-title-level-2-font-size--large;
                font-weight: $header-navigation-vertical-navigation-item-title-level-2-font-weight--large;
            }
        }

        .m-opened.c-navigation__flyout-element {
            @include breakpoint(large) {
                display: flex;
            }
        }
    }

    .c-navigation__link-image {
        display: inline-block;
        vertical-align: middle;
        margin: $header-navigation-link-image-margin;
        flex: $header-navigation-link-image-flex;
    }

    .c-navigation__link-badge {
        display: inline-block;
        vertical-align: middle;
        margin: $header-navigation-link-badge-margin;
    }

    .c-navigation__link-content {
        display: flex;
        align-items: center;
        gap: $header-navigation-item-icon-item-icon-padding;
        height: 100%;
    }

    .c-navigation__item-icon {
        width: $header-navigation-item-icon-item-icon-width;
    }

    .c-navigation__item.m-icon-left,
    .c-navigation__item.m-icon-right {
        .c-navigation__link {
            &:is(a):hover {
                @include breakpoint(xlarge) {
                    .c-navigation__link-name { /* stylelint-disable-line max-nesting-depth */
                        margin-left: $header-navigation-link-item-icon-link-hover-name-shift--large;
                        margin-right: $header-navigation-link-item-icon-link-hover-name-shift--large;
                    }
                }
            }
        }
    }

    .c-navigation__item.m-icon-right {
        .c-navigation__item-icon {
            order: 1;
        }
    }

    .c-navigation__item.m-icon-top {
        .c-navigation__item-icon {
            position: absolute;
            top: $header-navigation-item-icon-top-item-icon-top;
            bottom: $header-navigation-item-icon-top-item-icon-bottom;

            @include breakpoint(xlarge) {
                @include horizontal-center;

                top: $header-navigation-item-icon-top-item-icon-top--large;
                bottom: $header-navigation-item-icon-top-item-icon-bottom--large;
            }
        }
    }
}
