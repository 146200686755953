$loyalty-joinnow-padding: null !default;
$loyalty-joinnow-padding--large: null !default;
$loyalty-joinnow-max-width: null !default;
$loyalty-joinnow-max-width--large: rem-calc(570) !default;
$loyalty-joinnow-margin: null !default;
$loyalty-joinnow-margin--large: 0 auto !default;
// Title
$loyalty-joinnow-title-font: #{rem-calc(24)} / 1.4 $font-primary !default;
$loyalty-joinnow-title-font--large: #{rem-calc(30)} / 1.4 $font-primary !default;
$loyalty-joinnow-title-text-transform: uppercase !default;
$loyalty-joinnow-title-margin: rem-calc(0 0 20) !default;
$loyalty-joinnow-title-margin--large: null !default;
// Secondary
$loyalty-joinnow-secondary-padding: rem-calc(15 15 0) !default;
$loyalty-joinnow-secondary-padding--large: rem-calc(20 20 5) !default;
$loyalty-joinnow-secondary-margin: rem-calc(20 0) !default;
$loyalty-joinnow-secondary-margin--large: null !default;
$loyalty-joinnow-secondary-background: color(element-background) !default;
$loyalty-joinnow-secondary-background--large: null !default;
$loyalty-joinnow-secondary-expanded-background: null !default;
$loyalty-joinnow-secondary-expanded-background--large: null !default;

// Secondary title
$loyalty-joinnow-secondary-title-font: #{rem-calc(18)} / 1.4 $font-primary !default;
$loyalty-joinnow-secondary-title-font--large: null !default;
$loyalty-joinnow-secondary-title-margin: rem-calc(0 0 10) !default;
$loyalty-joinnow-secondary-title-margin--large: rem-calc(0 0 5) !default;

@mixin lora-components-loyalty-joinnow {
    .c-loyalty-joinnow {
        padding: $loyalty-joinnow-padding;
        max-width: $loyalty-joinnow-max-width;
        margin: $loyalty-joinnow-margin;

        @include breakpoint(large) {
            padding: $loyalty-joinnow-padding--large;
            max-width: $loyalty-joinnow-max-width--large;
            margin: $loyalty-joinnow-margin--large;
        }
    }

    .c-loyalty-joinnow.m-secondary {
        padding: $loyalty-joinnow-secondary-padding;
        margin: $loyalty-joinnow-secondary-margin;
        background: $loyalty-joinnow-secondary-background;

        @include breakpoint(large) {
            padding: $loyalty-joinnow-secondary-padding--large;
            margin: $loyalty-joinnow-secondary-margin--large;
            background: $loyalty-joinnow-secondary-background--large;
        }

        &.m-expanded {
            background: $loyalty-joinnow-secondary-expanded-background;
            @include breakpoint(large) {
                background: $loyalty-joinnow-secondary-expanded-background--large;
            }
        }

        .c-loyalty-joinnow__title {
            font: $loyalty-joinnow-secondary-title-font;
            margin: $loyalty-joinnow-secondary-title-margin;
    
            @include breakpoint(large) {
                font: $loyalty-joinnow-secondary-title-font--large;
                margin: $loyalty-joinnow-secondary-title-margin--large;
            }
        }
    }

    .c-loyalty-joinnow__title {
        font: $loyalty-joinnow-title-font;
        margin: $loyalty-joinnow-title-margin;
        text-transform: $loyalty-joinnow-title-text-transform;

        @include breakpoint(large) {
            font: $loyalty-joinnow-title-font--large;
            margin: $loyalty-joinnow-title-margin--large;
        }
    }
}