$anchor-offset: rem-calc(220) !default;
$anchor-offset--large: rem-calc(180) !default;
$anchor-secondary-offset: rem-calc(220) !default;
$anchor-secondary-offset--large: rem-calc(260) !default;

@mixin lora-components-anchor {
    .c-anchor {
        padding-top: $anchor-offset;
        margin-top: -$anchor-offset;
        background-clip: content-box;
        position: absolute;
        z-index: -1;

        @include breakpoint(large) {
            padding-top: $anchor-offset--large;
            margin-top: -$anchor-offset--large;
        }
    }

    .c-anchor-secondary {
        padding-top: $anchor-secondary-offset;
        margin-top: -$anchor-secondary-offset;
        background-clip: content-box;
        position: absolute;
        z-index: -1;

        @include breakpoint(large) {
            padding-top: $anchor-secondary-offset--large;
            margin-top: -$anchor-secondary-offset--large;
        }
    }

    .c-anchor__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        pointer-events: none;
        z-index: -1;
    }
}
