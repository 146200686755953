// Label Large
$content-tile-large-label-font: 500 #{rem-calc(10)} / rem-calc(12) $font-primary;
$content-tile-large-label-font--large: $content-tile-large-label-font;
$content-tile-large-label-text-transform: uppercase;

// Title Large
$content-tile-large-title-font: 500 #{rem-calc(24)} / rem-calc(26) $font-primary;
$content-tile-large-title-font--large: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$content-tile-large-title-text-transform: uppercase;
$content-tile-large-title-text-transform--large: $content-tile-large-title-text-transform;
$content-tile-large-title-letter-spacing: rem-calc(2);

// Description Large
$content-tile-large-description-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$content-tile-large-description-font--large: $content-tile-large-description-font;
$content-tile-large-description-text-transform: none;
$content-tile-large-description-text-transform--large: $content-tile-large-description-text-transform;
$content-tile-large-description-letter-spacing: rem-calc(1);
$content-tile-large-description-color--large: color(text-secondary);

// Content width
$content-tile-video-min-width: 40%;

/// Title
$content-tile-title-font: bold #{rem-calc(22)} / rem-calc(24) $font-tertiary;
$content-tile-title-font--large: $content-tile-title-font;
$content-tile-title-letter-spacing: rem-calc(0);
$content-tile-title-margin: rem-calc(0 0 20);

/// Description
$content-tile-description-font: #{rem-calc(14)} / 1.4 $font-primary;
$content-tile-description-font--large: $content-tile-description-font;
$content-tile-description-color: color(text-secondary);
$content-tile-description-color--large: $content-tile-description-color;
$content-tile-description-text-transform: none;
$content-tile-description-text-transform--large: $content-tile-description-text-transform;
$content-tile-description-letter-spacing: rem-calc(1);

// Label
$content-tile-label-font: 500 #{rem-calc(10)} / rem-calc(12) $font-primary;
$content-tile-label-font--large: $content-tile-label-font;
$content-tile-label-letter-spacing: rem-calc(1);
$content-tile-label-margin: rem-calc(0 0 20);
$content-tile-label-margin--large: rem-calc(0 0 5);

/// Space between sections on vertical position
$content-tile-stack-gutter: rem-calc(30);

@import "@lora/05-components/content-tile";