/* stylelint-disable declaration-no-important */
////
/// @group flex
////

@mixin lora-helpers-flex {
    .h-order-2 {
        order: 2;
    }

    .h-order-2-for-large {
        @include breakpoint(large) {
            order: 2;
        }
    }

    .h-flex {
        display: flex !important;
    }
}
