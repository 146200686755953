/// Text mixin with common rules like font, color, margin, etc.
/// Each rule contain `small` and `large` breakpoint label to cover breakpoints
/// where `small` means mobile, tablet or all devices if `large` is null
/// and `large` means desktop devices only.
/// If any rule have `null` value or not added then it won't be generated in css
///
/// @param {color} $color - text color
/// @param {font} $font - shorthand font styles
/// @param {margin} $margin - space around text block
/// @param {text-align} $text-align - text align
/// @param {text-transform} $text-transform - capitalize an element's text
/// @param {text-decoration} $text-decoration - appearance of decorative lines on text
/// @param {padding} $padding - space inside text block
/// @param {letter-spacing} $letter-spacing - horizontal spacing behavior between text characters
/// @example scss
///
/// 	.text-selector {
/// 		@include text-style(
///             $color: (
///                 small: color(light),
///                 large: color(black)
///             ),
///             $font: (
///                 small: 'rem-calc(14) $font-primary'
///             ),
///             $text-decoration: (
///                 large: uppercase
///             )
///         );
/// 	}
///
/// 	...generates..
/// 	.text-selector {
/// 		color: #fff;
///         font: .875rem CircularStd,sans-serif;
/// 	}
///     @media screen and (min-width: 64em) {
/// 	    .text-selector {
/// 		    color: #000;
///             text-decoration: uppercase;
/// 	    }
///     }
///
@mixin text-style(
    $color: (
        small: null,
        large: null
    ),
    $font: (
        small: null,
        large: null
    ),
    $margin: (
        small: null,
        large: null
    ),
    $text-align: (
        small: null,
        large: null
    ),
    $text-transform: (
        small: null,
        large: null
    ),
    $text-decoration: (
        small: null,
        large: null
    ),
    $padding: (
        small: null,
        large: null
    ),
    $letter-spacing: (
        small: null,
        large: null
    )
) {
    color: map-deep-get($color, "small"); // stylelint-disable-line scale-unlimited/declaration-strict-value
    font: map-deep-get($font, "small");
    margin: map-deep-get($margin, "small");
    text-align: map-deep-get($text-align, "small");
    text-transform: map-deep-get($text-transform, "small");
    text-decoration: map-deep-get($text-decoration, "small");
    padding: map-deep-get($padding, "small");
    letter-spacing: map-deep-get($letter-spacing, "small");

    @include breakpoint(large) {
        color: map-deep-get($color, "large"); // stylelint-disable-line scale-unlimited/declaration-strict-value
        font: map-deep-get($font, "large");
        margin: map-deep-get($margin, "large");
        text-align: map-deep-get($text-align, "large");
        text-transform: map-deep-get($text-transform, "large");
        text-decoration: map-deep-get($text-decoration, "large");
        padding: map-deep-get($padding, "large");
        letter-spacing: map-deep-get($letter-spacing, "large");
    }
}
