/* stylelint-disable selector-max-type, selector-no-universal, selector-no-qualifying-type */

/*------------------------------------*\
        #BODY
\*------------------------------------*/
/// Background color of the body.
/// @type Color
$body-background: color(light) !default;

/// Text color of the body.
/// @type Color
$body-font-color: color(text) !default;

/// Font size of the body.
$body-font-size: rem-calc(12) !default;

/// Font stack of the body.
/// @type List
$body-font-family: $font-primary !default;

/**
  * Body base styles
  */

@mixin lora-base-body {
    html {
        box-sizing: border-box;
        font-size: $global-font-size;
        overflow: hidden auto;
        scroll-behavior: smooth;

        @if ($global-text-direction == rtl) {
            direction: rtl;
            text-align: right;
        }
    }

    html.m-scroll-blocked {
        overflow-y: hidden;

        @include breakpoint(medium down) {
            height: vh(100);
            position: relative;
        }

        body {
            @include breakpoint(medium down) {
                position: fixed;
                width: 100vw;
                height: vh(100);
            }
        }
    }

    // Set box-sizing globally to handle padding and border widths
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    // Default body styles
    body {
        background: $body-background;
        color: $body-font-color;
        font: $global-weight-normal #{$body-font-size} / $global-lineheight $body-font-family;
        margin: 0;
        min-height: vh(100);
        overflow: hidden;
        padding: 0;
        scroll-behavior: smooth;
        text-align: #{$global-left};
        direction: $global-text-direction;

        &.m-sticky-layout {
            @include breakpoint(large) {
                overflow: clip;
            }
        }
    }

    html.m-prevent-mobile-scroll { // Fix for IOS to prevent main content scrolling when a full-screen overlay is opened. (NGLORA-2575, NGLORA-3023)
        @include breakpoint(medium down) {
            overflow-y: hidden;

            body {
                position: fixed;
                width: 100vw;
            }
        }
    }
}
