$icon-color: color(text) !default;
$icon-size: rem-calc(20) !default;
$icon-size-small: rem-calc(12) !default;
$icon-is-mask: true !default;
$icon-color-hover: color(primary-active) !default;
$icon-label-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$icon-label-text-transform: null !default;
$icon-svg-margin: 0 !default;
$icon-label-margin: rem-calc(10 0 0) !default;
$icon-padding: 0 !default;
$icon-svg-selector: '.c-icon__svg' !default;
$icon-label-selector: '.c-icon__label' !default;
$icon-list: (
    cross: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    heart-outline: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    heart: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    questionmark: (
        color: color(info),
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    info: (
        color: color(info),
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    chevron2-right: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    star: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    filter: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    tick: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    psswd_visible: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    pin: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    phone: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    map: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    hour: (
        color: $icon-color,
        size: $icon-size,
        size-small: $icon-size-small,
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
    options: (
        color: $icon-color,
        size: rem-calc(24),
        size-small: rem-calc(18),
        is-mask: $icon-is-mask,
        color-hover: $icon-color-hover,
    ),
) !default;

// Dark mode
$icon-dark-color: color(light) !default;
// Icons in the text
$icon-after-text-margin-left: rem-calc(5) !default;
$icon-before-text-margin-right: rem-calc(5) !default;

@mixin lora-helper-icon-base(
    $color: $icon-color,
    $label-font: $icon-label-font,
    $svg-margin: $icon-svg-margin,
    $label-margin: $icon-label-margin,
    $svg-selector: $icon-svg-selector,
    $label-selector: $icon-label-selector,
    $label-text-transform: $icon-label-text-transform
) {
    display: inline-block;
    text-align: center;
    vertical-align: middle;

    #{$svg-selector} {
        margin: $svg-margin;
        display: block;
        text-align: center;
        line-height: 1;
    }

    @if ($label-selector) {
        #{$label-selector} {
            display: inline-block;
            margin: $label-margin;
            color: $color;
            font: $label-font;
            text-transform: $label-text-transform;
        }
    }
}

@mixin lora-helper-icon-svg(
    $icon,
    $color: $icon-color,
    $size: $icon-size,
    $size-small: $icon-size-small,
    $svg-selector: $icon-svg-selector,
    $is-mask: $icon-is-mask,
) {
    #{$svg-selector}::before {
        @include svg-icon($icon, $color, contain, center, no-repeat, $usesvg: $is-mask);

        display: inline-block;
        content: '';
        @if ($size) {
            width: $size;
            height: $size;
        }
    }

    &.m-small #{$svg-selector}::before {
        width: $size-small;
        height: $size-small;
    }

    &.m-dark #{$svg-selector}::before {
        @include svg-icon($icon, dark-mode-color(text), contain, center, no-repeat, $usesvg: $is-mask, $color-change: true);
    }
}

@mixin lora-helper-icon-hover(
    $icon,
    $color-hover: $icon-color-hover,
    $svg-selector: $icon-svg-selector,
    $label-selector: $icon-label-selector,
    $is-mask: $icon-is-mask,
) {
    cursor: pointer;

    #{$svg-selector}::before {
        @include svg-icon($icon, $color-hover, contain, center, $usesvg: $is-mask, $color-change: true);
    }

    &.m-dark #{$svg-selector}::before {
        @include svg-icon($icon, dark-mode-color(text), contain, center, $usesvg: $is-mask, $color-change: true);
    }

    @if ($label-selector) {
        #{$label-selector} {
            color: $color-hover;
        }

        &.m-dark #{$label-selector} {
            color: dark-mode-color(text);
        }
    }
}

@mixin lora-components-icon {
    .c-icon {
        @include lora-helper-icon-base();

        &.m-dark {
            @include lora-helper-icon-base(dark-mode-color(text));
        }

        // Add an offset when the icon is used after the text
        &.m-after-text {
            margin-#{$global-left}: $icon-after-text-margin-left;
        }

        // Add an offset when the icon is used before the text
        &.m-before-text {
            margin-#{$global-right}: $icon-before-text-margin-right;
        }
    }

    @each $icon, $properties in $icon-list {
        .c-icon.m-#{$icon} {
            @include lora-helper-icon-svg(
                $icon: "#{$icon}",
                $color: map-get($properties, color),
                $size: map-get($properties, size),
                $size-small: map-get($properties, size-small),
                $is-mask: map-get($properties, is-mask),
            );

            &.m-link {
                &:hover,
                &:focus {
                    @include lora-helper-icon-hover(
                        $icon: "#{$icon}",
                        $color-hover: map-get($properties, color-hover),
                    );
                }
            }
        }
    }
}
