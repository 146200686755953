$header-navigation-item-title-accordion-text-transform: uppercase;
$header-navigation-item-title-level-2-padding-left: rem-calc(24);
$header-navigation-item-title-level-2-background: transparent;
$header-navigation-link-level-2-text-transform: uppercase;
$header-navigation-item-title-level-1-expanded-background: transparent;
$header-navigation-item-title-level-1-expanded-color: color(primary);
$header-navigation-item-title-level-1-expanded-font: 400 #{rem-calc(11)} / 1.6 $font-secondary;
$header-navigation-item-title-level-2-expanded-background: transparent;
$header-navigation-item-title-level-2-expanded-color: color(primary);
$header-navigation-link-level-3-padding--medium-down: rem-calc(11.5 8 11.5 48);
$header-navigation-item-level-3-all-link-text-transform: uppercase;
$header-navigation-item-title-level-3-background: transparent;
$header-navigation-link-level-3-text-transform: uppercase;
$header-navigation-link-level-3-text-transform--large: none;
$header-navigation-item-first-border-top: 1px solid color(border);
$header-navigation-link-level-1-font-weight--large: 400;
$header-navigation-link-level-1-font-weight: 700;
$header-navigation-link-level-1-font-size--large: rem-calc(11);
$header-navigation-link-level-1-font-size: rem-calc(14);
$header-navigation-link-level-1-text-transform--large: uppercase;
$header-navigation-link-level-1-padding--large: rem-calc(17 16);
$header-navigation-link-level-1-hover-font-weight: 400;
$header-navigation-item-level-1-margin--large: 0;
$header-navigation-flyout-element-border-top--large: rem-calc(1) solid color(global-background);
$header-navigation-flyout-element-box-shadow--large: rem-calc(0 0 6 0) rgba(color(primary), 0.02), rem-calc(0 2 4 0) rgba(color(primary), 0.08);
$header-navigation-flyout-element-background--large: color(light);
$header-navigation-link-level-2-font-weight--large: 700;
$header-navigation-link-level-2-font-size--large: rem-calc(14);
$header-navigation-link-level-2-text-transform--large: uppercase;
$header-navigation-link-level-2-hover-font-weight: 700;
$header-navigation-list-level-3-margin--large: rem-calc(6 0 0);
$header-navigation-link-level-3-font-size--large: rem-calc(14);
$header-navigation-item-level-3-all-link-font--large: 300 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-navigation-item-level-3-all-link-text-decoration--large: none;
$header-navigation-link-level-1-font-family: $font-tertiary;
$header-navigation-link-level-1-font-family--large: $header-navigation-link-level-1-font-family;
$header-navigation-link-level-2-font-family--large: $font-tertiary;
$header-navigation-link-level-3-color: color(dark);
$header-navigation-link-level-3-color--large: color(element-background);
$header-navigation-item-title-level-1-padding-left: rem-calc(16);
$header-navigation-item-level-3-border: none;
$header-navigation-item-level-3-first-border: none;

/// Item level 1
$header-navigation-item-level-1-border-bottom: 1px solid color(disabled);

/// Item level 2
$header-navigation-item-level-2-expanded-border: 1px solid color(disabled);

// Item accordion controls
$header-navigation-item-title-accordion-level-1-expanded-border: 1px solid color(disabled);

/// Accordion icon
$header-navigation-item-title-accordion-icon-size: rem-calc(12);

// Item accordion controls
$header-navigation-item-title-accordion-padding: rem-calc(15.5 36 15.5 0);

//Items titles
$header-navigation-item-title-level-1-font: 700 #{rem-calc(14)} / #{rem-calc(16.8)} $font-tertiary;
$header-navigation-item-title-level-2-font: 300 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-navigation-item-title-level-2-expanded-font: 700 #{rem-calc(14)} / #{rem-calc(16.8)} $font-tertiary;
$header-navigation-item-title-padding: rem-calc(15.5 0);
$header-navigation-item-title-level-2-font--large: 700 #{rem-calc(14)} / #{rem-calc(16.8)} $font-tertiary;
$header-navigation-item-title-level-1-font--large: 400 #{rem-calc(11)} / #{rem-calc(16.8)} $font-tertiary;

/// View all link in level 2
$header-navigation-item-level-2-all-link-font: 300 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-navigation-item-level-2-all-link-text-transform: uppercase;
$header-navigation-item-level-2-all-link-text-decoration: underline;

/// View all link in level 3
$header-navigation-item-level-3-padding--large: rem-calc(6 0);

// Items lists
$header-navigation-list-level-2-padding--large: rem-calc(55 0 15);

/// Link level 3
$header-navigation-link-level-3-font: 300 #{rem-calc(14)} / #{rem-calc(16.8)} $font-primary;
$header-navigation-item-level-3-all-link-text-decoration: underline;

// Container
$header-navigation-container-content-padding: rem-calc(8 16 16);

//// Hover link level 2
$header-navigation-link-level-2-hover-color: color(element-background);

//// Hover link level 3
$header-navigation-link-level-3-hover-color: color(element-background);

@import "@lora/05-components/header/header-navigation";