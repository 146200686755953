$progress-bar-height: rem-calc(8);
$progress-bar-background-color: color(light);
$progress-bar-value-background-color: color(primary);
$progress-bar-border: rem-calc(1) solid color(border);
$progress-bar-border-radius: none;
$progress-bar-margin: rem-calc(10 4);
$progress-bar-before-size: rem-calc(15);
$progress-bar-before-active-icon-size: rem-calc(7);
$progress-bar-before-icon-color: color(primary);
$progress-bar-before-background-color: $progress-bar-background-color;
$progress-bar-after-background-color: transparent;
$progress-bar-point-descriptions-margin: rem-calc(40 0 0);

$progress-bar-point-description-color: color(element-background);
$progress-bar-point-description-font: 300 #{rem-calc(12)} / #{rem-calc(15)} $font-primary;
$progress-bar-point-description-active-font: 300 #{rem-calc(12)} / #{rem-calc(15)} $font-primary;

$progress-bar-point-price-color: color(element-background);
$progress-bar-point-price-active-font: 700 #{rem-calc(12)} / rem-calc(15) $font-tertiary;
$progress-bar-point-price-font: 300 #{rem-calc(12)} / rem-calc(15) $font-primary;

@import "@lora/05-components/progress-bar";