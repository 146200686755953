@import "scrollable";
$tabs-secondary-controls-offset: map-get($grid-container-padding, small) / 2 !default;
$tabs-control-items-border-bottom: 2px solid transparent !default;
$tabs-control-items-margin: 0 !default;
$tabs-tab-after-border-bottom: 2px solid transparent !default;
$tabs-tab-after-transform: translate3d(-50%, 0, 0) !default;
$tabs-tab-after-transition: width 0.5s, border 0.5s !default;
$tabs-tab-after-position: absolute !default;
$tabs-tab-after-bottom: 0 !default;
$tabs-tab-after-width: 0 !default;
$tabs-controls-align-self: center !default;
$tabs-controls-width: null !default;
$tabs-tab-color: color(text-secondary) !default;
$tabs-tab-font: #{rem-calc(12)} / 1 $font-primary !default;
$tabs-tab-font--large: #{rem-calc(30)} / 1 $font-primary !default;
$tabs-tab-active-font: null !default;
$tabs-tab-active-font--large: $tabs-tab-font--large !default;
$tabs-tab-active-letter-spacing: null !default;
$tabs-tab-text-transform: none !default;
$tabs-tab-text-transform--large: uppercase !default;
$tabs-tab-text-align: null !default;
$tabs-tab-height: rem-calc(50) !default;
$tabs-tab-hover-color: $tabs-tab-color !default;
$tabs-tab-margin: rem-calc(0 5) !default;
$tabs-tab-margin--large: rem-calc(0 5) !default;
$tabs-tab-padding: rem-calc(0 20 25) !default;
$tabs-tab-padding--large: rem-calc(15 35) !default;
$tabs-tab-border: 0 !default;
$tabs-tab-transition: border-color 0.5s, background-color 0.5s, color 0.5s !default;
$tabs-tab-focus-bottom-border-color: color(border) !default;
$tabs-tab-hover-bottom-border-color: null !default;
$tabs-tab-hover-bottom-border-width: null !default;
$tabs-tab-active-color: color(primary-active) !default;
$tabs-tab-active-after-border-bottom: 2px solid !default;
$tabs-tab-active-after-border-bottom--large: null !default;
$tabs-tab-active-background-color: null !default;
$tabs-content-margin: rem-calc(25 0 0) !default;
$tabs-content-margin--large: rem-calc(50 0 0) !default;
$tabs-content-plain-padding: rem-calc(20) !default;
$tabs-content-plain-padding--large: $tabs-content-plain-padding !default;
$tabs-content-plain-background: color(element-background) !default;
$tabs-content-plain-background--large: $tabs-content-plain-background;
$tabs-tab-plain-active-background: null !default;
$tabs-tab-letter-spacing: null !default;
$tabs-tab-letter-spacing--large: null !default;

// Tabs secondary
$tabs-secondary-controls-align-self: flex-start !default;
$tabs-secondary-controls-margin: 0 -#{$tabs-secondary-controls-offset} !default;
$tabs-secondary-controls-margin--large: 0 !default;
$tabs-secondary-controls-padding: 0 $tabs-secondary-controls-offset !default;
$tabs-secondary-controls-padding--large: 0 !default;
$tabs-secondary-controls-background: color(element-background) !default;
$tabs-secondary-controls-background--large: transparent !default;
$tabs-secondary-control-items-border-bottom: 1px solid color(border) !default;
$tabs-secondary-control-items-divider-side-offset: 0 !default;
$tabs-secondary-control-items-justify-content: left !default;
$tabs-secondary-content-margin: rem-calc(0 -20) !default;
$tabs-secondary-content-padding: rem-calc(20) !default;
$tabs-secondary-content-padding--large: rem-calc(30 20 20) !default;
$tabs-secondary-content-background: color(global-background) !default;
$tabs-secondary-content-background--large: transparent !default;
$tabs-secondary-content-font: inherit !default;
$tabs-secondary-content-font--large: inherit !default;
$tabs-secondary-content-letter-spacing: null !default;
$tabs-secondary-content-color: null !default;
$tabs-secondary-content-color--large: null !default;
$tabs-secondary-tab-divider-border-bottom-width: 2px !default;
$tabs-secondary-tab-active-divider-border-bottom-width: null !default;
$tabs-secondary-tab-active-divider-border-bottom-width--large: null !default;
$tabs-secondary-tab-font-size: rem-calc(12) !default;
$tabs-secondary-tab-font-size--large: rem-calc(17) !default;
$tabs-secondary-tab-font-weight: null !default;
$tabs-secondary-tab-font-weight--large: null !default;
$tabs-secondary-tab-line-height: null !default;
$tabs-secondary-tab-line-height--large: null !default;
$tabs-secondary-tab-font-family: null !default;
$tabs-secondary-tab-active-font: null !default;
$tabs-secondary-tab-active-font-weight: null !default;
$tabs-secondary-tab-active-font-weight--large: bold !default;
$tabs-secondary-tab-text-transform: null !default;
$tabs-secondary-tab-text-transform--large: null !default;
$tabs-secondary-tab-active-color: null !default;
$tabs-secondary-tab-active-background: null !default;
$tabs-secondary-tab-border: null !default;
$tabs-secondary-tab-margin: null !default;
$tabs-secondary-tab-color: color(text) !default;
$tabs-secondary-tab-color--large: color(text-secondary) !default;
$tabs-secondary-tab-padding: rem-calc(15 15 15 0) !default;
$tabs-secondary-tab-padding--large: rem-calc(40 20 36 0) !default;
$tabs-secondary-tab-flex--large: initial !default;
$tabs-secondary-tab-text-align: #{$global-left} !default;
$tabs-secondary-controls-width--large: 100% !default;
$tabs-secondary-tab-hover-color: null !default;
$tabs-secondary-tab-focus-outline-offset: null !default;
$tabs-secondary-controls-width: calc(100% + #{$tabs-secondary-controls-offset} * 2) !default;

// Tabs tertiary
$tabs-tertiary-tab-flex-grow: 1 !default;
$tabs-tertiary-tab-padding: rem-calc(15 10) !default;
$tabs-tertiary-tab-padding--large: rem-calc(25 20) !default;
$tabs-tertiary-tab-margin: rem-calc(0 0 2) !default;
$tabs-tertiary-tab-after-bottom: rem-calc(-2) !default;
$tabs-tertiary-tab-color: color(text) !default;
$tabs-tertiary-tab-active-color: color(primary-active) !default;
$tabs-tertiary-tab-active-font-weight: null !default;
$tabs-tertiary-tab-active-font-size: null !default;
$tabs-tertiary-tab-active-font: null !default;
$tabs-tertiary-tab-active-after-border-color: color(primary-active) !default;
$tabs-tertiary-tab-font: #{rem-calc(14)} / 1 $font-primary !default;
$tabs-tertiary-tab-font--large: #{rem-calc(14)} / 1 $font-primary !default;
$tabs-tertiary-tab-text-transform: $tabs-tab-text-transform !default;
$tabs-tertiary-tab-text-transform--large: $tabs-tab-text-transform--large !default;
$tabs-tertiary-tab-text-align: null !default;
$tabs-tertiary-controls-background-color: color(global-background) !default;
$tabs-tertiary-control-items-margin: rem-calc(0) !default;
$tabs-tertiary-control-items-padding: rem-calc(0 15) !default;

$tabs-control-has-background-align-self: start !default;
$tabs-control-sticky-transition: top 0.15s ease-out !default;
$tabs-control-sticky-top: 0 !default;
// Animated tabs
$tabs-tab-animated-top: rem-calc(11) !default;
$tabs-tab-animated-top--large: null !default;
$tabs-tab-animated-color: $tabs-tab-active-color !default;
$tabs-tab-animated-color--large: null !default;
$tabs-tab-animated-after-enabled: true !default;
$tabs-tab-animated-after-border-bottom: $tabs-tab-active-after-border-bottom !default;
$tabs-tab-animated-after-border-bottom--large: null !default;
// Animated icon tabs
$tabs-tab-animated-icon-transition: transform 0.5s ease-in-out !default;
$tabs-tab-animated-icon-background-color: color(primary-active) !default;
$tabs-tab-animated-icon-width: rem-calc(50) !default;
$tabs-tab-animated-icon-height: rem-calc(50) !default;
$tabs-tab-animated-icon-width--large: rem-calc(97) !default;
$tabs-tab-animated-icon-height--large: rem-calc(97) !default;
$tabs-tab-animated-icon-width--retina-large: rem-calc(96) !default;
$tabs-tab-animated-icon-height--retina-large: rem-calc(96) !default;
$tabs-tab-animated-icon-before-transition: bottom 0.5s ease-in-out !default;
$tabs-tab-animated-icon-before-background: color(border) !default;
$tabs-tab-animated-icon-before-height: 50% !default;
$tabs-tab-animated-icon-active-before-bottom: 50% !default;
$tabs-tab-animated-icon-active-transform: rotate(180deg) !default;
// Tabs secondary animated
$tabs-secondary-tab-animated-top: rem-calc(16) !default;
$tabs-secondary-tab-animated-top--large: rem-calc(45) !default;
$tabs-secondary-tab-animated-color: $tabs-secondary-tab-color !default;
$tabs-secondary-tab-animated-color--large: $tabs-secondary-tab-color--large !default;
$tabs-secondary-tab-animated-after-border-bottom-width: $tabs-secondary-tab-divider-border-bottom-width !default;
// Tabs tertiary animated
$tabs-tertiary-tab-animated-top: rem-calc(20) !default;
$tabs-tertiary-tab-animated-top--large: rem-calc(17) !default;
$tabs-tertiary-tab-animated-color: $tabs-tertiary-tab-color !default;
$tabs-tertiary-tab-animated-color--large: null !default;
$tabs-tertiary-tab-animated-after-border-color: $tabs-tertiary-tab-active-after-border-color !default;

@mixin lora-helper-tabs-layout {
    display: flex;
    flex-flow: column;

    &.m-vertical {
        flex-flow: row;
    }
}

@mixin lora-helper-tabs-tab {
    border: $tabs-tab-border;
    flex: 0 0 auto;
    position: relative;
    transition: $tabs-tab-transition;

    @include lora-helper-scrollable-item;
    @include breakpoint(large) {
        cursor: pointer;
    }
    @include text-style(
        $font: (
            small: $tabs-tab-font,
            large: $tabs-tab-font--large
        ),
        $margin: (
            small: $tabs-tab-margin,
            large: $tabs-tab-margin--large
        ),
        $padding: (
            small: $tabs-tab-padding,
            large: $tabs-tab-padding--large
        ),
        $text-align: (
            small: $tabs-tab-text-align
        ),
        $text-transform: (
            small: $tabs-tab-text-transform,
            large: $tabs-tab-text-transform--large
        ),
        $letter-spacing: (
            small: $tabs-tab-letter-spacing,
            large: $tabs-tab-letter-spacing--large
        ),
        $color: (
            small: $tabs-tab-color
        )
    );

    &::after {
        border-bottom: $tabs-tab-after-border-bottom;
        bottom: $tabs-tab-after-bottom;
        content: '';
        #{$global-left}: 50%;
        position: $tabs-tab-after-position;
        transform: $tabs-tab-after-transform;
        transition: $tabs-tab-after-transition;
        width: $tabs-tab-after-width;
    }

    &:focus {
        &::after {
            border-color: $tabs-tab-focus-bottom-border-color;
            width: 100%;
        }
    }

    &:hover {
        color: $tabs-tab-hover-color;

        &::after {
            border-color: $tabs-tab-hover-bottom-border-color;
            width: $tabs-tab-hover-bottom-border-width;
        }
    }

    &.m-active {
        color: $tabs-tab-active-color;
        cursor: default;
        pointer-events: none;
        font: $tabs-tab-active-font;
        background: $tabs-tab-active-background-color;
        letter-spacing: $tabs-tab-active-letter-spacing;

        &::after {
            border-bottom: $tabs-tab-active-after-border-bottom;
            width: 100%;

            @include breakpoint(large) {
                border-bottom: $tabs-tab-active-after-border-bottom--large;
            }
        }

        @include breakpoint(large) {
            font: $tabs-tab-active-font--large;
        }
    }

    &:first-child {
        margin-#{$global-left}: 0;
    }

    &:last-child {
        margin-#{$global-right}: 0;
    }
}

@mixin lora-helper-tabs-content {
    margin: $tabs-content-margin;

    @include breakpoint(large) {
        margin: $tabs-content-margin--large;
    }
}

@mixin lora-helper-tabs-panel {
    display: none;

    &.m-active {
        display: block;
    }
}

@mixin lora-helper-tabs-controls($align: $tabs-controls-align-self) {
    align-self: $align;
    max-width: 100%;
    width: $tabs-controls-width;

    @include lora-helper-scrollable-wrapper;

    &::before {
        border-bottom: $tabs-control-items-border-bottom;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        width: 100%;
    }
}

@mixin lora-helper-tabs-vertical-controls {
    flex: 0 0 auto;

    &::before {
        content: none;
    }
}

@mixin lora-helper-tabs-control-items {
    display: flex;
    margin: $tabs-control-items-margin;

    @include lora-helper-scrollable-items;
}

@mixin lora-helper-tabs-vertical-control-items {
    flex: 0 0 auto;
    flex-flow: column;
}

/// Adds styles for the wrapper that surrounds a tab group's content panes.
@mixin lora-helper-tabs-vertical-content {
    flex: 1 1 auto;
    overflow: hidden;
}

@mixin lora-helper-tabs-fullwidth {
    .c-tabs__controls {
        width: 100%;
    }

    .c-tabs__tab {
        flex: 1;
    }
}

@mixin lora-helper-tabs-style-secondary {
    .c-tabs__controls {
        align-self: $tabs-secondary-controls-align-self;
        background: $tabs-secondary-controls-background;
        margin: $tabs-secondary-controls-margin;
        max-width: initial;
        padding: $tabs-secondary-controls-padding;
        width: $tabs-secondary-controls-width;
        transform: translate3d(0, 0, 0); // Fixes blinking items on iOS

        &::before {
            border-bottom: $tabs-secondary-control-items-border-bottom;
            left: $tabs-secondary-control-items-divider-side-offset;
            right: $tabs-secondary-control-items-divider-side-offset;
        }

        @include breakpoint(large) {
            max-width: 100%;
            width: $tabs-secondary-controls-width--large;
            background: $tabs-secondary-controls-background--large;
            margin: $tabs-secondary-controls-margin--large;
            padding: $tabs-secondary-controls-padding--large;
        }
    }

    .c-tabs__control-items {
        @include lora-helper-scrollable-items;

        @include breakpoint(large) {
            @include lora-helper-scrollable-items;
        }

        display: flex;
        justify-content: $tabs-secondary-control-items-justify-content;
    }

    .c-tabs__content {
        background: $tabs-secondary-content-background;

        @include breakpoint(large) {
            background: $tabs-secondary-content-background--large;
        }
        @include text-style(
            $font: (
                small: $tabs-secondary-content-font,
                large: $tabs-secondary-content-font--large
            ),
            $margin: (
                small: $tabs-secondary-content-margin
            ),
            $padding: (
                small: $tabs-secondary-content-padding,
                large: $tabs-secondary-content-padding--large
            ),
            $letter-spacing: (
                small: $tabs-secondary-content-letter-spacing
            ),
            $color: (
                small: $tabs-secondary-content-color,
                large: $tabs-secondary-content-color--large
            )
        );
    }

    .c-tabs__tab {
        font-size: $tabs-secondary-tab-font-size;
        font-weight: $tabs-secondary-tab-font-weight;
        line-height: $tabs-secondary-tab-line-height;
        padding: $tabs-secondary-tab-padding;
        text-align: $tabs-secondary-tab-text-align;
        font-family: $tabs-secondary-tab-font-family;
        border: $tabs-secondary-tab-border;
        margin: $tabs-secondary-tab-margin;
        text-transform: $tabs-secondary-tab-text-transform;
        color: $tabs-secondary-tab-color;

        @include breakpoint(large) {
            flex: $tabs-secondary-tab-flex--large;
            font-size: $tabs-secondary-tab-font-size--large;
            font-weight: $tabs-secondary-tab-font-weight--large;
            line-height: $tabs-secondary-tab-line-height--large;
            padding: $tabs-secondary-tab-padding--large;
            text-transform: $tabs-secondary-tab-text-transform--large;
            color: $tabs-secondary-tab-color--large;
        }

        &::after {
            border-bottom-width: $tabs-secondary-tab-divider-border-bottom-width;
        }

        &:hover {
            color: $tabs-secondary-tab-hover-color;
        }

        &:focus {
            outline-offset: $tabs-secondary-tab-focus-outline-offset;
        }

        &.m-active {
            font: $tabs-secondary-tab-active-font;
            font-weight: $tabs-secondary-tab-active-font-weight;
            color: $tabs-secondary-tab-active-color;
            background: $tabs-secondary-tab-active-background;

            &::after {
                border-bottom-width: $tabs-secondary-tab-active-divider-border-bottom-width;

                @include breakpoint(large) {
                    border-bottom-width: $tabs-secondary-tab-active-divider-border-bottom-width--large;
                }
            }

            @include breakpoint(large) {
                font-weight: $tabs-secondary-tab-active-font-weight--large;
            }
        }
    }

    .c-tabs__tab-animated {
        top: $tabs-secondary-tab-animated-top;
        color: $tabs-secondary-tab-animated-color;

        @include breakpoint(large) {
            top: $tabs-secondary-tab-animated-top--large;
            color: $tabs-secondary-tab-animated-color--large;
        }

        &::after {
            border-bottom-width: $tabs-secondary-tab-animated-after-border-bottom-width;
        }
    }
}

@mixin lora-helper-tabs-style-tertiary($hide-controls: large) {
    .c-tabs__controls {
        align-self: auto;
        background: $tabs-tertiary-controls-background-color;
        display: block;
        max-width: none;

        @if ($hide-controls) {
            @include hide-for($hide-controls);
        }
    }

    .c-tabs__control-items {
        margin: $tabs-tertiary-control-items-margin;
        padding: $tabs-tertiary-control-items-padding;

        @include lora-helper-scrollable-items;
    }

    .c-tabs__tab {
        flex-grow: $tabs-tertiary-tab-flex-grow;

        @include text-style(
            $font: (
                small: $tabs-tertiary-tab-font,
                large: $tabs-tertiary-tab-font--large
            ),
            $margin: (
                small: $tabs-tertiary-tab-margin
            ),
            $padding: (
                small: $tabs-tertiary-tab-padding,
                large: $tabs-tertiary-tab-padding--large
            ),
            $text-align: (
                small: $tabs-tertiary-tab-text-align
            ),
            $text-transform: (
                small: $tabs-tertiary-tab-text-transform,
                large: $tabs-tertiary-tab-text-transform--large
            ),
            $color: (
                small: $tabs-tertiary-tab-color
            )
        );

        &::after {
            bottom: $tabs-tertiary-tab-after-bottom;
        }

        &.m-active {
            color: $tabs-tertiary-tab-active-color;
            font: $tabs-tertiary-tab-active-font;
            font-weight: $tabs-tertiary-tab-active-font-weight;
            font-size: $tabs-tertiary-tab-active-font-size;

            &::after {
                border-color: $tabs-tertiary-tab-active-after-border-color;
            }
        }
    }

    .c-tabs__tab-animated {
        top: $tabs-tertiary-tab-animated-top;
        color: $tabs-tertiary-tab-animated-color;

        @include breakpoint(large) {
            top: $tabs-tertiary-tab-animated-top--large;
            color: $tabs-tertiary-tab-animated-color--large;
        }

        &::after {
            border-color: $tabs-tertiary-tab-animated-after-border-color;
        }
    }
}

@mixin lora-helper-tabs-dark-mode {
    background: dark-mode-color(background);
    color: dark-mode-color(text);

    .c-tabs__control-items {
        @include lora-helper-scrollable-items;

        @include breakpoint(large) {
            @include lora-helper-scrollable-items;
        }
    }

    .c-tabs__tab {
        color: dark-mode-color(text);

        &.m-active::after {
            border-color: dark-mode-color(border);
        }
    }
}

@mixin lora-components-tabs {
    .c-tabs {
        width: 100%;
        @include lora-helper-tabs-layout;
    }

    .c-tabs.m-vertical .c-tabs__controls {
        @include lora-helper-tabs-vertical-controls;
    }

    .c-tabs.m-vertical .c-tabs__control-items {
        @include lora-helper-tabs-vertical-control-items;
    }

    .c-tabs.m-vertical .c-tabs__content {
        @include lora-helper-tabs-vertical-content;
    }

    .c-tabs.m-fullwidth {
        @include lora-helper-tabs-fullwidth;
    }

    .c-tabs__controls {
        @include lora-helper-tabs-controls;

        &.m-has-background {
            width: 100%;
        }
        
        &.m-sticked {
            @include layout;
    
            top: $tabs-control-sticky-top;
            transition: $tabs-control-sticky-transition;
            position: fixed;
            z-index: z(headerStickyBar);
            padding: 0;
        }
    }

    .c-tabs__controls-inner {
        width: 100%;
    }

    .c-tabs__control-items {
        @include lora-helper-tabs-control-items;
    }

    .c-tabs__tab {
        @include lora-helper-tabs-tab;
    }

    .c-tabs__content {
        @include lora-helper-tabs-content;
    }

    .c-tabs__panel {
        @include lora-helper-tabs-panel;
    }

    .c-tabs.m-dark {
        @include lora-helper-tabs-dark-mode;
    }

    .c-tabs.m-secondary {
        @include lora-helper-tabs-style-secondary;
    }

    .c-tabs.m-tertiary {
        @include lora-helper-tabs-style-tertiary;
    }

    .c-tabs.m-tertiary-for-all {
        @include lora-helper-tabs-style-tertiary(null);
    }

    .c-tabs.m-plain {
        .c-tabs__content {
            margin: 0;
            padding: $tabs-content-plain-padding;
            background: $tabs-content-plain-background;

            @include breakpoint(large) {
                margin: 0;
                padding: $tabs-content-plain-padding--large;
                background: $tabs-content-plain-background--large;
            }
        }

        .c-tabs__tab {
            &.m-active {
                background: $tabs-tab-plain-active-background;
            }
        }
    }

    .c-tabs__tab-animated {
        @include horizontal-center;

        top: $tabs-tab-animated-top;
        color: $tabs-tab-animated-color;
        pointer-events: none;

        @include breakpoint(large) {
            top: $tabs-tab-animated-top--large;
            color: $tabs-tab-animated-color--large;
        }

        @if ($tabs-tab-animated-after-enabled) {
            &::after {
                @include absolute-center;

                content: '';
                width: 100%;
                border-bottom: $tabs-tab-animated-after-border-bottom;

                @include breakpoint(large) {
                    border-bottom: $tabs-tab-animated-after-border-bottom--large;
                }
            }
        }
    }

    .c-tabs__tab-animated-icon {
        display: block;
        width: $tabs-tab-animated-icon-width;
        height: $tabs-tab-animated-icon-height;
        transition: $tabs-tab-animated-icon-transition;
        background-color: $tabs-tab-animated-icon-background-color;

        @include breakpoint(large) {
            width: $tabs-tab-animated-icon-width--large;
            height: $tabs-tab-animated-icon-height--large;

            @include breakpoint(retina) {
                width: $tabs-tab-animated-icon-width--retina-large;
                height: $tabs-tab-animated-icon-height--retina-large;
            }
        }

        &::before {
            content: '';
            width: 100%;
            height: $tabs-tab-animated-icon-before-height;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: $tabs-tab-animated-icon-before-transition;
            background: $tabs-tab-animated-icon-before-background;
        }

        &.m-active {
            transform: $tabs-tab-animated-icon-active-transform;

            &::before {
                bottom: $tabs-tab-animated-icon-active-before-bottom;
            }
        }
    }
}
