$product-badge-margin: rem-calc(3 0 10);
$product-badge-image-max-width: rem-calc(60);
$product-badge-image-max-width--large: rem-calc(90);

// Product badge title
$product-badge-title-text-decoration: none;
$product-badge-title-padding: rem-calc(4 8 2);
$product-badge-title-font: 300 #{rem-calc(10)} / rem-calc(12) $font-primary;
$product-badge-title-border-radius: none;
$product-badge-title-background: rgba(color(dark), 0.5);
$product-badge-title-color: color(light);
$product-badge-title-margin: rem-calc(8 0 5 0);

// Product badge category title
$product-badge-category-title-font: 700 #{rem-calc(12)} / rem-calc(18) $font-primary;
$product-badge-category-title-padding: rem-calc(6 10 2 14);
$product-badge-category-title-border-radius: none;
$product-badge-category-title-border-left: 4px solid color(dark);
$product-badge-category-title-box-shadow: rem-calc(2 3 6 0) rgba(color(dark), 0.1);

@import "@lora/05-components/product/product-badge";