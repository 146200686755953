$layout-horizontal-stack-gutters: (
    // default size of gutter
    default: (
        small: rem-calc(30),
        large: rem-calc(30)
    ),
    // medium size of gutter
    small: (
        small: rem-calc(20),
        large: rem-calc(20)
    ),
    // large size of gutter
    large: (
        small: rem-calc(40),
        large: rem-calc(40)
    )
);
$layout-horizontal-stack-border-color: color(border) !default;
$layout-horizontal-stack-border-width: 1px !default;
$layout-horizontal-stack-border-top: null !default;
$layout-horizontal-stack-last-child-border-color: transparent !default;
// Stack item
$layout-horizontal-stack-item-flex: 1 1 0 !default;
$layout-horizontal-stack-item-text-align: center !default;
/// Stack inline item
$layout-horizontal-stack-inline-item-flex: 0 1 auto !default;

// stylelint-disable max-nesting-depth
@mixin helper-layout-horizontal-stack-gutters {
    @include -zf-each-breakpoint() {
        @each $gutter, $size in $layout-horizontal-stack-gutters {
            @if (map-deep-get($size, $-zf-size)) {
                @if ($gutter == 'default') {
                    .l-horizontal-stack {
                        @include xy-gutters($gutters: map-deep-get($size, $-zf-size), $negative: true);
                    }
                    
                    .l-horizontal-stack__item {
                        @include xy-gutters($gutters: map-deep-get($size, $-zf-size), $gutter-type: padding);
                    }
                }
                @else {
                    .l-horizontal-stack.m-gutter-#{$gutter} {
                        @include xy-gutters($gutters: map-deep-get($size, $-zf-size), $negative: true);
    
                        & > .l-horizontal-stack__item {
                            @include xy-gutters($gutters: map-deep-get($size, $-zf-size), $gutter-type: padding);
                        }
                    }
                }
            }
        }

        .l-horizontal-stack.m-no-gutter-for-#{$-zf-size} {
            @include xy-gutters($gutters: 0);

            & > .l-horizontal-stack__item {
                @include xy-gutters($gutters: 0, $gutter-type: padding);
            }
        }
    }
}
// stylelint-enable max-nesting-depth
@mixin lora-layout-horizontal-stack {
    @include helper-layout-horizontal-stack-gutters;

    .l-horizontal-stack {
        display: flex;

        &.m-inline {
            flex-wrap: wrap;
            
            .l-horizontal-stack__item {
                flex: $layout-horizontal-stack-inline-item-flex;
            }
        }
    }

    .l-horizontal-stack__item {
        flex: $layout-horizontal-stack-item-flex;
        text-align: $layout-horizontal-stack-item-text-align;

        &:empty {
            display: none;
        }
    }

    .l-horizontal-stack__item.m-full-width {
        flex-basis: 100%;
    }

    .l-horizontal-stack.m-divided > {
        .l-horizontal-stack__item {
            border-#{$global-right}: $layout-horizontal-stack-border-width solid $layout-horizontal-stack-border-color;
            border-top: $layout-horizontal-stack-border-top;

            &:last-child {
                border-color: $layout-horizontal-stack-last-child-border-color;
            }
        }
    }
}
