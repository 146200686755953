$toggle-link-padding: rem-calc(0 20 0 0) !default;
$toggle-link-color: color(primary) !default;
// Toggle icon
$toggle-link-icon: "chevron2-down" !default;
$toggle-link-icon-top: rem-calc(3) !default;
$toggle-link-expanded-icon-top: rem-calc(2) !default;
$toggle-link-icon-size: rem-calc(12) !default;
$toggle-link-icon-color: color(primary) !default;
$toggle-link-icon-usesvg: true !default;
$toggle-link-expanded-icon: "chevron2-top" !default;
$toggle-link-expanded-icon-color: $toggle-link-icon-color !default;
$toggle-link-expanded-icon-usesvg: true !default;

@mixin lora-components-toggle-link {
    .c-toggle-link {
        position: relative;
        display: inline-block;
        cursor: pointer;
        padding: $toggle-link-padding;
        color: $toggle-link-color;

        &::after {
            @include svg-icon($toggle-link-icon, $toggle-link-icon-color, 100%, center, no-repeat, $usesvg: $toggle-link-icon-usesvg);

            position: absolute;
            top: $toggle-link-icon-top;
            content: '';
            width: $toggle-link-icon-size;
            height: $toggle-link-icon-size;
            pointer-events: none;
            #{$global-right}: 0;
        }

        &.m-no-toggle {
            &::after {
                display: none;
            }
        }
    }

    .c-toggle-link[aria-expanded="true"] {
        &::after {
            @include svg-icon($toggle-link-expanded-icon, $toggle-link-expanded-icon-color, 100%, center, no-repeat, $usesvg: $toggle-link-expanded-icon-usesvg);

            top: $toggle-link-expanded-icon-top;
        }
    }
}
