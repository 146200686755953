// Animated tile
$carousel-animated-product-image-transform: scale(0.75) !default;
$carousel-animated-product-content-transform: scale(0) !default;

@mixin lora-helper-carousel-product-animated {
    .c-product-image img,
    .c-product-add-bag__button {
        transition: transform 0.3s ease-in-out;

        @include reduced-motion {
            transition-duration: 0s;
        }
    }

    .c-product-tile__caption,
    .c-carousel__item-text {
        transition: opacity 0.3s ease-in-out;
    }

    .c-product-tile__name {
        transition: transform 0.3s ease-in-out;

        @include reduced-motion {
            transition-duration: 0s;
        }
    }

    .c-product-tile__description {
        transition: transform 0.5s ease-in-out;

        @include reduced-motion {
            transition-duration: 0s;
        }
    }
    
    .c-product-tile__rating,
    .c-product-tile__price,
    .c-product-variation {
        transition: transform 0.7s ease-in-out;

        @include reduced-motion {
            transition-duration: 0s;
        }
    }

    .c-carousel__item:not(.m-active) {
        .c-product-image img {
            transform: $carousel-animated-product-image-transform;
        }

        .c-product-tile__caption,
        .c-carousel__item-text,
        .c-carousel__arrows,
        .c-carousel__scrollbar {
            opacity: 0;
            visibility: hidden;
        }

        .c-product-tile__name,
        .c-product-tile__description,
        .c-product-tile__rating,
        .c-product-tile__price,
        .c-product-variation,
        .c-product-add-bag__button {
            transform: $carousel-animated-product-content-transform;
        }
    }
}

@mixin lora-components-carousel-animated {
    .c-carousel.m-animated {
        @include lora-helper-carousel-product-animated;
    }
}
