/* stylelint-disable selector-max-type */

/*------------------------------------*\
        #FORMS
\*------------------------------------*/
@mixin lora-base-forms {
    /**
      * Fieldset base styles
      */
    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }

    /// Make sure textarea takes on height automatically
    textarea {
        height: auto;
        min-height: 50px;
    }

    // Make select elements are 100% width by default
    select {
        width: 100%;
    }

    // Reset <button> styles created by most browsers
    button {
        @include disable-mouse-outline;

        appearance: none;
        background: transparent;
        padding: 0;
        border: 0;
        border-radius: 0;

        // Correct cutted text in iOS and Safari
        /// @link https://actsofvolition.com/2019/10/font-descenders-cut-off-in-safari-buttons-when-line-height-set-to-1/ Safari fix
        line-height: normal;
    }
}
