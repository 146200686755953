$add-to-wishlist-border: null !default;
$add-to-wishlist-border-radius: 50% !default;
$add-to-wishlist-background: color(light) !default;
$add-to-wishlist-box-shadow: rem-calc(0 0 4 0) rgba(color(dark), 0.2) !default;
$add-to-wishlist-padding: rem-calc(10) !default;
$add-to-wishlist-padding--large: rem-calc(10) !default;
$add-to-wishlist-icon: "heart-outline" !default;
$add-to-wishlist-hover-icon: $add-to-wishlist-icon !default;
$add-to-wishlist-active-icon: "heart" !default;
$add-to-wishlist-icon-size: rem-calc(20) !default;
$add-to-wishlist-icon-background-size: 100% !default;
$add-to-wishlist-icon-background-hover-size: 100% !default;
$add-to-wishlist-icon-size--large: rem-calc(20) !default;
$add-to-wishlist-icon-color: color(primary) !default;
$add-to-wishlist-icon-hover-color: color(primary-active) !default;
$add-to-wishlist-icon-active-color: color(primary-active) !default;
$add-to-wishlist-icon-active-hover-focus-color: $add-to-wishlist-icon-active-color !default;
$add-to-wishlist-icon-border: null !default;
$add-to-wishlist-icon-border--large: null !default;
$add-to-wishlist-icon-border-radius: null !default;
$add-to-wishlist-icon-border-radius--large: null !default;
$add-to-wishlist-icon-padding: null !default;
$add-to-wishlist-icon-padding--large: null !default;
// Small
$add-to-wishlist-small-padding: rem-calc(4) !default;
$add-to-wishlist-small-padding--large: null !default;
$add-to-wishlist-small-icon-size: rem-calc(16) !default;
$add-to-wishlist-small-icon-size--large: null !default;
// Usesvg
$add-to-wishlist-icon-usesvg: true !default;
$add-to-wishlist-hover-icon-usesvg: true !default;
$add-to-wishlist-active-icon-usesvg: true !default;

@mixin lora-components-add-to-wishlist {
    .c-add-to-wishlist {
        display: inline-block;
        cursor: pointer;
        border: $add-to-wishlist-border;
        border-radius: $add-to-wishlist-border-radius;
        background: $add-to-wishlist-background;
        box-shadow: $add-to-wishlist-box-shadow;

        @if ($add-to-wishlist-hover-icon != $add-to-wishlist-icon or $add-to-wishlist-icon-hover-color != $add-to-wishlist-icon-color) {
            &:hover {
                .c-add-to-wishlist__inner::after {
                    @include svg-icon($add-to-wishlist-hover-icon, $size: $add-to-wishlist-icon-background-hover-size, $position: center, $repeat: no-repeat, $color: $add-to-wishlist-icon-hover-color, $usesvg: $add-to-wishlist-hover-icon-usesvg);
                }
            }
        }

        &.m-active {
            .c-add-to-wishlist__inner::after {
                @include svg-icon($add-to-wishlist-active-icon, $color: $add-to-wishlist-icon-active-color, $usesvg: $add-to-wishlist-active-icon-usesvg);
            }

            &:hover {
                .c-add-to-wishlist__inner::after {
                    @include svg-icon($add-to-wishlist-active-icon, $color: $add-to-wishlist-icon-active-hover-focus-color, $color-change: true, $usesvg: $add-to-wishlist-active-icon-usesvg);
                }
            }
        }
    }

    .c-add-to-wishlist__inner {
        display: block;
        padding: $add-to-wishlist-padding;

        @include breakpoint(large) {
            padding: $add-to-wishlist-padding--large;
        }

        &::after {
            @include svg-icon($add-to-wishlist-icon, $size: $add-to-wishlist-icon-background-size, $position: center, $repeat: no-repeat, $color: $add-to-wishlist-icon-color, $usesvg: $add-to-wishlist-icon-usesvg);

            content: '';
            display: block;
            height: $add-to-wishlist-icon-size;
            width: $add-to-wishlist-icon-size;
            border: $add-to-wishlist-icon-border;
            border-radius: $add-to-wishlist-icon-border-radius;
            padding: $add-to-wishlist-icon-padding;

            @include breakpoint(large) {
                height: $add-to-wishlist-icon-size--large;
                width: $add-to-wishlist-icon-size--large;
                border: $add-to-wishlist-icon-border--large;
                border-radius: $add-to-wishlist-icon-border-radius--large;
                padding: $add-to-wishlist-icon-padding--large;
            }
        }
    }

    .c-add-to-wishlist.m-small {
        .c-add-to-wishlist__inner {
            padding: $add-to-wishlist-small-padding;

            @include breakpoint(large) {
                padding: $add-to-wishlist-small-padding--large;
            }

            &::after {
                height: $add-to-wishlist-small-icon-size;
                width: $add-to-wishlist-small-icon-size;

                @include breakpoint(large) {
                    height: $add-to-wishlist-small-icon-size--large;
                    width: $add-to-wishlist-small-icon-size--large;
                }
            }
        }
    }
}
