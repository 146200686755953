$form-row-grouped-buttons-field-flex: 0 0 37%;
$form-row-title-font-weight: 400;
$form-row-field-margin: rem-calc(15 10 15 0);
$form-row-margin: 0 -0.625rem 0 0;
$form-row-title-font-size: rem-calc(14);
$form-row-title-margin: rem-calc(0);
$form-field-margin: rem-calc(0 0 20);
$form-row-title-separator-margin: rem-calc(30 10 30 0);
$form-row-title-color: color(text-secondary);

@import "@lora/05-components/form/form";