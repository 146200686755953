$footer-sticky-bar-font: #{rem-calc(12)} $font-primary !default;
$footer-sticky-bar-font--large: bold #{rem-calc(12)} $font-primary !default;
$footer-sticky-bar-padding: rem-calc(0 15) !default;
$footer-sticky-bar-padding--large: rem-calc(0 35) !default;
$footer-sticky-bar-text-align: center !default;
$footer-sticky-bar-text-align--large: null !default;
$footer-sticky-bar-letter-spacing: null !default;
$footer-sticky-bar-letter-spacing--large: null !default;
$footer-sticky-bar-item-color: color(border) !default;
$footer-sticky-bar-item-color--medium: color(border) !default;
$footer-sticky-bar-item-hover-color: color(light) !default;
$footer-sticky-bar-item-hover-color--medium: null !default;
$footer-sticky-bar-vertical-space: rem-calc(11) !default;
$footer-sticky-bar-line-height: rem-calc(18) !default;
$footer-sticky-bar-height: ($footer-sticky-bar-vertical-space * 2) + $footer-sticky-bar-line-height !default;
$footer-sticky-bar-height--large: null !default;
$footer-sticky-bar-align-items: center !default;
$footer-sticky-bar-justify-content: center !default;
$footer-sticky-bar-align-items--large: null !default;
$footer-sticky-bar-justify-content--large: null !default;
$footer-sticky-bar-text-transform: none !default;
$footer-sticky-bar-border: 0 !default;
$footer-sticky-bar-border--medium: 0 !default;

@mixin lora-components-footer-sticky-bar {
    .c-footer-sticky-bar {
        color: $footer-sticky-bar-item-color;
        height: 100%;
        border: $footer-sticky-bar-border;

        @include breakpoint(medium) {
            border: $footer-sticky-bar-border--medium;
        }
    }

    .c-footer-sticky-bar__row {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: space-around;
        width: 100%;
    }

    .c-footer-sticky-bar__list {
        display: flex;
        list-style: none;
        margin: 0;
        width: 100%;
    }

    .c-footer-sticky-bar__item {
        display: flex;
        align-items: $footer-sticky-bar-align-items;
        justify-content: $footer-sticky-bar-justify-content;
        font: $footer-sticky-bar-font;
        line-height: $footer-sticky-bar-height;
        padding: $footer-sticky-bar-padding;
        text-align: $footer-sticky-bar-text-align;
        letter-spacing: $footer-sticky-bar-letter-spacing;
        white-space: nowrap;

        @include breakpoint(large) {
            align-items: $footer-sticky-bar-align-items--large;
            justify-content: $footer-sticky-bar-justify-content--large;
            font: $footer-sticky-bar-font--large;
            line-height: $footer-sticky-bar-height--large;
            padding: $footer-sticky-bar-padding--large;
            text-align: $footer-sticky-bar-text-align--large;
            letter-spacing: $footer-sticky-bar-letter-spacing--large;

            &:first-child {
                padding-#{$global-left}: 0;
            }

            &:last-child {
                padding-#{$global-right}: 0;
            }
        }

        &.m-spacer {
            margin: auto;
            width: 100%;
        }

        // Remove double padding if elements are nested
        .c-footer-sticky-bar__item {
            padding: 0;
        }
    }

    a.c-footer-sticky-bar__item { // stylelint-disable-line
        color: $footer-sticky-bar-item-color;
        text-decoration: none;

        @include breakpoint(medium) {
            color: $footer-sticky-bar-item-color--medium;
        }

        &:hover,
        &:focus {
            color: $footer-sticky-bar-item-hover-color;

            @include breakpoint(medium) {
                color: $footer-sticky-bar-item-hover-color--medium;
            }
        }
    }
}
