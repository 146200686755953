$error-callout-text-align: left;
$error-callout-text-align--large: center;
$error-callout-padding: rem-calc(40 15 10);
$error-callout-padding--large: rem-calc(90 15);
$error-callout-color: color(primary);
$error-callout-color--large: color(secondary);
$error-callout-title-font: 500 #{rem-calc(24)} / rem-calc(26) $font-primary;
$error-callout-title-font--large: 500 #{rem-calc(30)} / 1 $font-primary;
$error-callout-title-margin: rem-calc(0 0 15);
$error-callout-title-margin--large: rem-calc(10) auto rem-calc(30);
$error-callout-title-max-width: 90%;
$error-callout-title-max-width--large: rem-calc(400);
$error-callout-subtitle-font: #{rem-calc(12)} / rem-calc(14) $font-primary;
$error-callout-subtitle-font--large: #{rem-calc(14)} / rem-calc(20) $font-primary;
$error-callout-subtitle-text-transform: none;
$error-callout-subtitle-margin--large: rem-calc(0 0 20);
$error-callout-title-text-align: inherit;
$error-callout-title-text-align--large: inherit;
$error-callout-info-text-align: $error-callout-title-text-align;
$error-callout-info-text-align--large: $error-callout-title-text-align--large;

@import "@lora/05-components/error-callout";