$form-select-border-radius: 0;
$form-select-icon-border-radius: 0;
$form-select-text-transform: uppercase;
$form-select-height: rem-calc(50);
$form-select-third-border-color: color(border);
$form-select-third-radius: 0;
$form-select-third-color: color(light);
$form-select-third-text-transform: uppercase;
$form-select-custom-disabled-icon-color: color(dark);
$form-select-font-size: rem-calc(12);
$form-select-size: (
    "small": rem-calc(25),
    "normal": $global-input-primary-height,
    "large": rem-calc(50)
);
$form-select-label-float-margin: rem-calc(0 0 0 10);

// Panel
$form-select-panel-item-background-hover: color(secondary);
$form-select-panel-item-background-selected: color(secondary);
$form-select-panel-item-color: color(primary);
$form-select-panel-item-color-hover: $form-select-panel-item-color;
$form-select-panel-item-color-selected: $form-select-panel-item-color;
$form-select-panel-item-font: normal #{rem-calc(12)} / 1.5 $font-primary;
$form-select-panel-item-font-hover: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$form-select-panel-item-font-selected: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$form-select-font-sizes: (
    "small": rem-calc(11),
    "normal": rem-calc(14),
    "large": rem-calc(12)
);

// Single value
$form-select-single-value-label-float-left: rem-calc(-10);

@import "@lora/05-components/form/select";