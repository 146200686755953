// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group xy-grid
////

/// Creates a max width container, designed to house your grid content.
///
/// @param {Number} $width [$grid-container] - a width to limit the container to.
@mixin xy-grid-container(
    $width: $grid-container,
    $padding: $grid-container-padding
) {
    @include xy-gutters($gutters: $padding, $gutter-type: padding);

    margin: 0 auto;
    max-width: $width;
    width: 100%;
}

/// Creates a container for your flex cells.
///
/// @param {Keyword} $direction [horizontal] - Either horizontal or vertical direction of cells within.
/// @param {Boolean} $wrap [true] - If the cells within should wrap or not.
@mixin xy-grid(
    $direction: horizontal,
    $wrap: true
) {
    $direction: if($direction == 'horizontal', row, column);
    $wrap: if($wrap, wrap, nowrap);

    display: flex;
    flex-flow: $direction $wrap;
}
