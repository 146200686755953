$header-suggestions-title-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-secondary;
$header-suggestions-title-text-transform: uppercase;
$header-suggestions-text-separator--large: 1px solid color(border);
$header-suggestions-title-font--large: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$header-suggestions-title-text-transform--large: uppercase;
$header-suggestions-products-price-font: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$header-suggestions-view-all-font: #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$header-suggestions-view-all-font--large: $header-suggestions-view-all-font;
$header-suggestions-view-all-color: color(text);
$header-suggestions-view-all-top: 20px;
$header-suggestions-view-all-top--large: 78%;
$header-suggestions-products-description-text-transform: uppercase;
$header-suggestions-products-name-font--large: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$header-suggestions-products-name-text-transform--large: uppercase;
$header-suggestions-sections-separator: 0;
$header-suggestions-products-padding--large: rem-calc(30 0 90);
$header-suggestions-content-only-padding--large: rem-calc(0 0 40);
$header-suggestions-products-description-font: normal #{rem-calc(14)} / 1.42 $font-primary;
$header-suggestions-aside-title-color: color(text);
$header-suggestions-aside-title-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-secondary;
$header-suggestions-aside-title-text-transform: uppercase;
$header-suggestions-aside-title-margin: rem-calc(0 0 10);
$header-suggestions-text-link-padding: rem-calc(0 0 20);
$header-suggestions-title-padding: rem-calc(20 0 18);

@import "@lora/05-components/header/header-suggestions";