$product-variations-carousel-link-background: color(light);
$product-variations-carousel-link-color: color(dark);
$product-variations-carousel-link-selected-background: $product-variations-carousel-link-background;
$product-variations-carousel-link-selected-color: $product-variations-carousel-link-color;
$product-variations-carousel-link-selected-border: rem-calc(2) solid color(dark);
$product-variations-carousel-link-height: rem-calc(68);
$product-variations-carousel-link-border: rem-calc(1) solid color(dark);
$product-variations-carousel-link-font: 300 #{rem-calc(14)} / rem-calc(16) $font-primary;
$product-variations-carousel-link-selected-font: 500 #{rem-calc(14)} / rem-calc(16) $font-tertiary;
$product-variations-carousel-link-min-width--large: auto;
$product-variations-carousel-link-hover-background: color(primary-active);
$product-variations-carousel-link-hover-color: color(light);
$product-variations-carousel-link-hover-border: none;
$product-variations-carousel-link-border-radius: rem-calc(0);

//Price
$product-variations-carousel-selected-price-value-color: color(element-background);
$product-variations-carousel-price-font-weight: 500;
$product-variations-carousel-selected-price-value-font-weight: 300;

// Disabled state
$product-variations-carousel-link-disabled-opacity: 0.8;
$product-variations-carousel-link-disabled-background: $product-variations-carousel-link-background;
$product-variations-carousel-link-disabled-selected-border: rem-calc(2) solid color(disabled);
$product-variations-carousel-disabled-price-value-color: color(disabled);

@import "@lora/05-components/product/product-variations-carousel";