$font-primary: "ITCAvantGardeStd-Bk", sans-serif;
$font-secondary: "ITCAvantGardeStd-Demi", sans-serif;
$font-tertiary: "ITCAvantGardeStd-Bold", sans-serif;

$font-list: (
    ('ITCAvantGardeStd-Bk', 'ITCAvantGardeGothicStd-Book', normal, normal),
    ('ITCAvantGardeStd-Demi', 'ITCAvantGardeGothicStd-Demi', 500, normal),
    ('ITCAvantGardeStd-Bold', 'ITCAvantGardeGothicStd-Bold', bold, normal)
);

@import "@lora/font-settings";