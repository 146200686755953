$header-user-menu-background: color(primary-background) !default;
$header-user-menu-color: color(primary) !default;
$header-user-menu-font: #{rem-calc(14)} / 1.4 $font-primary !default;
$header-user-menu-align-items: flex-start !default;
$header-user-menu-justify-content: flex-start !default;
$header-user-menu-padding: rem-calc(16) !default;
$header-user-menu-flex-gap: rem-calc(16) !default;
/// Sign in icon
$header-user-menu-show-icon: true !default;
$header-user-menu-icon: 'user' !default;
$header-user-menu-icon-color: color(primary) !default;
$header-user-menu-icon-usesvg: true !default;
$header-user-menu-icon-size: rem-calc(16) !default;
$header-user-menu-icon-flex: 0 0 auto !default;
// Sign in
$header-user-menu-sign-in-color: color(primary) !default;
$header-user-menu-sign-in-text-transform: null !default;
$header-user-menu-sign-in-text-decoration: underline !default;
// Menu link
$header-user-menu-link-color: color(primary) !default;
$header-user-menu-border: 1px solid color(border) !default;
$header-user-menu-border-width: rem-calc(0 0 1) !default;
// Content
$header-user-menu-content-background: color(light) !default;
// Title
$header-user-menu-title-flex-flow: wrap !default;
$header-user-menu-title-flex: 1 1 auto !default;
$header-user-menu-title-flex-gap: rem-calc(4) !default;
$header-user-menu-title-font: #{rem-calc(14)} / 1.4 $font-primary !default;
$header-user-menu-title-color: color(dark) !default;
$header-user-menu-title-text-transform: null !default;
$header-user-menu-title-letter-spacing: null !default;
/// Welcome
$header-user-menu-title-welcome-margin: null !default;
/// Title name
$header-user-menu-title-name-text-transform: null !default;
$header-user-menu-title-name-link-color: color(primary) !default;
// User menu text
$header-user-menu-text-transform: capitalize !default;
// Logout cta
$header-user-menu-actions-flex: 0 0 auto !default;
$header-user-menu-logout-display: null !default;
$header-user-menu-logout-link-display: block !default;
$header-user-menu-logout-link-color: color(text-secondary) !default;
$header-user-menu-logout-link-font: #{rem-calc(14)} / 1.4 $font-primary !default;

/// Logout Icon
$header-user-menu-logout-link-height: rem-calc(40) !default;
$header-user-menu-logout-link-width: rem-calc(40) !default;
$header-user-menu-logout-icon: power-button !default;
$header-user-menu-logout-icon-color: color(light) !default;
$header-user-menu-logout-icon-size: rem-calc(20 20) !default;
$header-user-menu-logout-icon-usesvg: true !default;
// Content title
$header-user-menu-content-title-background: null !default;
$header-user-menu-content-title-color: color(dark) !default;
$header-user-menu-content-title-font-size: rem-calc(18) !default;
$header-user-menu-content-title-height: null !default;
$header-user-menu-content-title-padding: rem-calc(0 20) !default;
$header-user-menu-content-title-margin: rem-calc(0 0 5) !default;
$header-user-menu-content-title-text-transform--medium-down: uppercase !default;
$header-user-menu-content-title-font-family--medium-down: $font-primary !default;
$header-user-menu-content-title-font-weight--medium-down: bold !default;
$header-user-menu-content-title-border-top--medium-down: null !default;
$header-user-menu-content-title-border-bottom--medium-down: null !default;
$header-user-menu-content-title-border--medium-down: null !default;
$header-user-menu-content-title-border-width--medium-down: null !default;
// Content inner
$header-user-menu-content-inner-padding: rem-calc(0 20) !default;
$header-user-menu-content-inner-margin: 0 !default;
// Content list
$header-user-menu-content-list-background: color(light) !default;
$header-user-menu-content-list-margin: 0 !default;
$header-user-menu-content-list-min-width: null !default;
$header-user-menu-content-list-padding: null !default;
// Content link
$header-user-menu-content-link-color: color(text) !default;
$header-user-menu-content-link-font-size: rem-calc(14) !default;
$header-user-menu-content-link-font-family: null !default;
$header-user-menu-content-link-font-weight: null !default;
$header-user-menu-content-link-text-transform: null !default;
$header-user-menu-content-link-letter-spacing: null !default;
$header-user-menu-content-link-logout-color: color(light) !default;
$header-user-menu-content-link-logout-background: color(secondary) !default;
$header-user-menu-content-link-logout-hover-background: color(secondary-active) !default;
$header-user-menu-content-link-logout-hover-color: $header-user-menu-content-link-logout-color !default;
$header-user-menu-content-item-border-top: 1px solid color(border) !default;
$header-user-menu-content-link-height: rem-calc(50) !default;
$header-user-menu-content-link-logout-justify-content: space-between !default;
$header-user-menu-content-link-logout-text-transform: none !default;
$header-user-menu-content-link-logout-margin--large: null !default;
$header-user-menu-content-link-logout-font-family: $font-primary !default;
$header-user-menu-content-link-logout-font-size: null !default;
$header-user-menu-content-link-logout-margin: 0 !default;
$header-user-menu-content-link-logout-border-radius: null !default;
$header-user-menu-content-link-logout-border: null !default;
$header-user-menu-content-link-logout-hover-border: null !default;
// Back button
$header-user-menu-back-button-padding: rem-calc(10 20) !default;
$header-user-menu-back-button-height: rem-calc(50) !default;
$header-user-menu-back-button-color: color(text) !default;
$header-user-menu-back-button-icon: chevron2-left !default;
$header-user-menu-back-button-icon-height: rem-calc(15) !default;
$header-user-menu-back-button-icon-margin: rem-calc(0 10 0 0) !default;
$header-user-menu-back-button-icon-size: auto rem-calc(12) !default;
$header-user-menu-back-button-icon-width: rem-calc(6) !default;
$header-user-menu-back-button-icon-color: $header-user-menu-back-button-color !default;
$header-user-menu-back-button-icon-usesvg: true !default;
$header-user-menu-back-button-width: auto !default;
$header-user-menu-back-button-background: none !default;
$header-user-menu-back-button-justify-content: null !default;
$header-user-menu-back-button-text-transform: null !default;
$header-user-menu-back-button-font: null !default;
$header-user-menu-back-button-letter-spacing: null !default;
// Menu secondary list
$header-user-menu-secondary-list-margin: rem-calc(-10) !default;
$header-user-menu-secondary-list-border-top: none !default;
// Menu secondary link
$header-user-menu-secondary-link-font-size: rem-calc(12) !default;
$header-user-menu-secondary-link-font-weight: null !default;
$header-user-menu-secondary-link-height: rem-calc(32) !default;
$header-user-menu-secondary-link-padding: rem-calc(8 16) !default;
// Menu secondary logout cta
$header-user-menu-secondary-logout-font-weight: null !default;
$header-user-menu-secondary-logout-letter-spacing: null !default;
$header-user-menu-secondary-logout-icon-size: rem-calc(13 13) !default;
$header-user-menu-secondary-logout-icon-height: rem-calc(26) !default;
$header-user-menu-secondary-logout-icon-width: rem-calc(26) !default;

@mixin lora-components-user-menu {
    .c-user-menu {
        align-items: $header-user-menu-align-items;
        background: $header-user-menu-background;
        display: flex;
        gap: $header-user-menu-flex-gap;
        font: $header-user-menu-font;
        justify-content: $header-user-menu-justify-content;
        padding: $header-user-menu-padding;
        width: 100%;
        color: $header-user-menu-color;
        border: $header-user-menu-border;
        border-width: $header-user-menu-border-width;

        @if $header-user-menu-show-icon {
            &::before {
                @include svg-icon($header-user-menu-icon, $header-user-menu-icon-color, $usesvg: $header-user-menu-icon-usesvg);

                content: '';
                display: inline-block;
                height: $header-user-menu-icon-size;
                width: $header-user-menu-icon-size;
                flex: $header-user-menu-icon-flex;
            }
        }
    }

    .c-user-menu__text.m-welcome-back-switch {
        text-transform: $header-user-menu-text-transform;
    }

    .c-user-menu__title {
        display: flex;
        flex: $header-user-menu-title-flex;
        gap: $header-user-menu-title-flex-gap;
        flex-flow: $header-user-menu-title-flex-flow;
        font: $header-user-menu-title-font;
        color: $header-user-menu-title-color;
        text-transform: $header-user-menu-title-text-transform;
        letter-spacing: $header-user-menu-title-letter-spacing;
    }

    .c-user-menu__title-welcome {
        margin: $header-user-menu-title-welcome-margin;
    }

    .c-user-menu__title-name {
        text-transform: $header-user-menu-title-name-text-transform;
    }

    .c-user-menu__title-name a {
        color: $header-user-menu-title-name-link-color;
    }

    .c-user-menu__content-item + .c-user-menu__content-item {
        border-top: $header-user-menu-content-item-border-top;
    }

    .c-user-menu__actions {
        flex: $header-user-menu-actions-flex;
    }

    .c-user-menu__logout-link {
        display: $header-user-menu-logout-link-display;
        color: $header-user-menu-logout-link-color;
        font: $header-user-menu-logout-link-font;
    }

    .c-user-menu__content {
        background: $header-user-menu-content-background;
        height: 100%;
        position: absolute;
        #{$global-right}: -100%;
        top: 0;
        width: 100%;
        z-index: z(header, userMenu);
        visibility: hidden;
        overflow-y: auto;
    }

    .c-user-menu__content.m-opened {
        #{$global-right}: 0;
        visibility: visible;
    }

    .c-user-menu__content.m-opening {
        visibility: visible;
    }

    .c-user-menu__back-button {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: $header-user-menu-back-button-height;
        padding: $header-user-menu-back-button-padding;
        color: $header-user-menu-back-button-color;
        width: $header-user-menu-back-button-width;
        background: $header-user-menu-back-button-background;
        justify-content: $header-user-menu-back-button-justify-content;
        text-transform: $header-user-menu-back-button-text-transform;
        font: $header-user-menu-back-button-font;
        letter-spacing: $header-user-menu-back-button-letter-spacing;

        &::before {
            @include svg-icon($header-user-menu-back-button-icon, $header-user-menu-back-button-icon-color, $header-user-menu-back-button-icon-size, left center, no-repeat, $usesvg: $header-user-menu-back-button-icon-usesvg);

            content: '';
            display: block;
            height: $header-user-menu-back-button-icon-height;
            margin: $header-user-menu-back-button-icon-margin;
            width: $header-user-menu-back-button-icon-width;
        }
    }

    .c-user-menu__content-title {
        align-items: center;
        background: $header-user-menu-content-title-background;
        color: $header-user-menu-content-title-color;
        display: flex;
        font-size: $header-user-menu-content-title-font-size;
        height: $header-user-menu-content-title-height;
        padding: $header-user-menu-content-title-padding;
        margin: $header-user-menu-content-title-margin;

        @include breakpoint(medium down) {
            text-transform: $header-user-menu-content-title-text-transform--medium-down;
            font-family: $header-user-menu-content-title-font-family--medium-down;
            font-weight: $header-user-menu-content-title-font-weight--medium-down;
            border-top: $header-user-menu-content-title-border-top--medium-down;
            border-bottom: $header-user-menu-content-title-border-bottom--medium-down;
            border: $header-user-menu-content-title-border--medium-down;
            border-width: $header-user-menu-content-title-border-width--medium-down;
        }
    }

    .c-user-menu__content-inner {
        padding: $header-user-menu-content-inner-padding;
        margin: $header-user-menu-content-inner-margin;
    }

    .c-user-menu__content-list {
        list-style: none;
        background: $header-user-menu-content-list-background;
        margin: $header-user-menu-content-list-margin;
        min-width: $header-user-menu-content-list-min-width;
        padding: $header-user-menu-content-list-padding;
    }

    .c-user-menu__content-link {
        align-items: center;
        display: flex;
        font-family: $header-user-menu-content-link-font-family;
        font-weight: $header-user-menu-content-link-font-weight;
        text-transform: $header-user-menu-content-link-text-transform;
        font-size: $header-user-menu-content-link-font-size;
        height: $header-user-menu-content-link-height;
        padding: $header-user-menu-padding;
        text-decoration: none;
        color: $header-user-menu-content-link-color;
        letter-spacing: $header-user-menu-content-link-letter-spacing;
    }

    .c-user-menu__content-link.m-logout {
        color: $header-user-menu-content-link-logout-color;
        background: $header-user-menu-content-link-logout-background;
        display: flex;
        justify-content: $header-user-menu-content-link-logout-justify-content;
        text-transform: $header-user-menu-content-link-logout-text-transform;
        font-family: $header-user-menu-content-link-logout-font-family;
        font-size: $header-user-menu-content-link-logout-font-size;
        margin: $header-user-menu-content-link-logout-margin;
        border-radius: $header-user-menu-content-link-logout-border-radius;
        border: $header-user-menu-content-link-logout-border;

        @include breakpoint(large) {
            margin: $header-user-menu-content-link-logout-margin--large;
        }

        &:hover {
            background: $header-user-menu-content-link-logout-hover-background;
            color: $header-user-menu-content-link-logout-hover-color;
            border: $header-user-menu-content-link-logout-hover-border;

            &::after {
                @include svg-icon($header-user-menu-logout-icon, $header-user-menu-content-link-logout-hover-color, $header-user-menu-logout-icon-size, right center, no-repeat, $usesvg: $header-user-menu-logout-icon-usesvg);
            }
        }

        &::after {
            @include svg-icon($header-user-menu-logout-icon, $header-user-menu-logout-icon-color, $header-user-menu-logout-icon-size, right center, no-repeat, $usesvg: $header-user-menu-logout-icon-usesvg);

            content: '';
            height: $header-user-menu-logout-link-height;
            width: $header-user-menu-logout-link-width;
            display: $header-user-menu-logout-display;
        }
    }

    .c-user-menu__sign-in {
        color: $header-user-menu-sign-in-color;
        text-transform: $header-user-menu-sign-in-text-transform;
        text-decoration: $header-user-menu-sign-in-text-decoration;
    }

    .c-user-menu__welcome-back-switch {
        color: $header-user-menu-link-color;
    }

    .c-user-menu__content-list.m-secondary {
        margin: $header-user-menu-secondary-list-margin;
        border-top: $header-user-menu-secondary-list-border-top;

        .c-user-menu__content-link {
            font-size: $header-user-menu-secondary-link-font-size;
            font-weight: $header-user-menu-secondary-link-font-weight;
            height: $header-user-menu-secondary-link-height;
            padding: $header-user-menu-secondary-link-padding;

            &.m-logout {
                font-weight: $header-user-menu-secondary-logout-font-weight;
                letter-spacing: $header-user-menu-secondary-logout-letter-spacing;

                &::after {
                    background-size: $header-user-menu-secondary-logout-icon-size;
                    height: $header-user-menu-secondary-logout-icon-height;
                    width: $header-user-menu-secondary-logout-icon-width;
                }
            }
        }
    }
}
