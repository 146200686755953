//stylelint-disable lora/variable-pattern
/* stylelint-disable selector-max-type */

/*------------------------------------*\
        #LINKS
\*------------------------------------*/

/// Default color for links.
/// @type Color
$anchor-color: color(text) !default;

/// Default color for links on hover.
/// @type Color
$anchor-color-hover: color(primary-active) !default;

/// Default text deocration for links.
/// @type String
$anchor-text-decoration: underline !default;

/// Default text decoration for links on hover.
/// @type String
$anchor-text-decoration-hover: underline !default;

@mixin lora-base-links {
    // Links
    a {
        color: $anchor-color;
        text-decoration: $anchor-text-decoration;
        line-height: inherit;
        cursor: pointer;

        @include hover {
            &:hover {
                color: $anchor-color-hover;
                @if $anchor-text-decoration-hover != $anchor-text-decoration {
                    text-decoration: $anchor-text-decoration-hover;
                }
            }
        }

        img {
            border: 0;
        }
    }
}
