$cookie-warning-background: color(light) !default;
$cookie-warning-color: color(text) !default;
$cookie-warning-padding: rem-calc(10 15) !default;
$cookie-warning-padding--large: rem-calc(0 20) !default;
$cookie-warning-transition: transform 0.1s ease-in-out !default;
$cookie-warning-box-shadow: rem-calc(0 -2 4 0) rgba(color(dark), 0.2) !default;
$cookie-warning-border-radius: rem-calc(8 8 0 0) !default;
$cookie-warning-border-radius--large: 0 !default;

@mixin lora-components-cookie-warning {
    .c-cookie-warning {
        position: relative;
        display: block;
        padding: $cookie-warning-padding;
        background: $cookie-warning-background;
        color: $cookie-warning-color;
        box-shadow: $cookie-warning-box-shadow;
        border-radius: $cookie-warning-border-radius;
        transition: $cookie-warning-transition;

        @include breakpoint(large) {
            padding: $cookie-warning-padding--large;
            border-radius: $cookie-warning-border-radius--large;
        }

        &.m-closed {
            transform: translate(0, 100%);
        }
    }
}