$layout-dividers-section-padding: rem-calc(0 20) !default;
$layout-dividers-section-first-padding-left: 0 !default;
$layout-dividers-section-last-padding-right: 0 !default;
$layout-dividers-section-border: $global-border !default;

@mixin lora-helper-layout-dividers-container {
    display: flex;
}

@mixin lora-helper-layout-dividers-section($divider: $layout-dividers-section-border, $padding: $layout-dividers-section-padding) {
    display: flex;
    flex-grow: 1;
    padding: $padding;

    &:first-child {
        padding-#{$global-left}: $layout-dividers-section-first-padding-left;
    }

    &:last-child {
        padding-#{$global-right}: $layout-dividers-section-last-padding-right;
    }

    &.m-x-center {
        justify-content: center;
    }

    &.m-x-end {
        justify-content: flex-end;
    }

    &.m-stretch {
        flex-basis: 100%;
    }

    &:not(:last-child) {
        border-#{$global-right}: $divider;
    }
}

@mixin lora-layout-layout-dividers {
    .l-layout-dividers {
        @include lora-helper-layout-dividers-container;
    }

    .l-layout-dividers__section {
        @include lora-helper-layout-dividers-section;
    }
}
