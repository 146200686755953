$storeslink-icon-height: rem-calc(18px) !default;
$storeslink-icon-width: rem-calc(13px) !default;
$storeslink-icon-height--large: rem-calc(18px) !default;
$storeslink-icon-width--large: rem-calc(12px) !default;
$storeslink-text-margin: rem-calc(0 0 0 10px) !default;
$storeslink-text-display: null !default;
$storeslink-text-display--large: null !default;
$storeslink-text-order: 2 !default;
$storeslink-icon: pin !default;
$storeslink-icon-color: color(primary) !default;
$storeslink-icon-color--large: color(text-secondary) !default;
$storeslink-icon-hover-color: color(primary) !default;
$storeslink-icon-size: 100% !default;
$storeslink-icon-position: left center !default;
$storeslink-icon-repeat: no-repeat !default;
$storeslink-icon-order: 1 !default;
$storeslink-icon-usesvg: true !default;

@mixin lora-components-storeslink {
    .c-storeslink {
        align-items: center;
        display: flex;

        &::before {
            @include svg-icon($storeslink-icon, $storeslink-icon-color, $storeslink-icon-size, $storeslink-icon-position, $storeslink-icon-repeat, $usesvg: $storeslink-icon-usesvg);

            content: '';
            display: block;
            height: $storeslink-icon-height;
            width: $storeslink-icon-width;
            order: $storeslink-icon-order;

            @include breakpoint(large) {
                @include svg-icon($storeslink-icon, $storeslink-icon-color--large, $storeslink-icon-size, $storeslink-icon-position, $storeslink-icon-repeat, $usesvg: $storeslink-icon-usesvg, $color-change: true);

                height: $storeslink-icon-height--large;
                width: $storeslink-icon-width--large;
            }
        }

        &:hover::before {
            @include svg-icon($storeslink-icon, $storeslink-icon-hover-color, $storeslink-icon-size, $storeslink-icon-position, $storeslink-icon-repeat, $usesvg: $storeslink-icon-usesvg, $color-change: true);
        }
    }

    .c-storeslink__text {
        display: $storeslink-text-display;
        margin: $storeslink-text-margin;
        order: $storeslink-text-order;

        @include breakpoint(xlarge) {
            display: $storeslink-text-display--large;
        }
    }
}
