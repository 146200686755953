@import "@lora/03-base/colors";

$palette-custom: (
    light:     #fff, // White
    dark:      #000,// Black
    info:      #000,
);

$semantic-colors-custom: (
    primary:             #000,
    primary-active:      #000,
    secondary:           #fff,
    secondary-active:    #000,
    text:                #000,
    text-secondary:      #737373,
    success:             #276027,
    alert:               #ab000d,
    disabled:            #ccc,
    element-background:  #8c8c8c,
    global-background:   #f6f7f6,
    organism-background: #525252,
    border:              #d8d8d8,
    organism-dark:       #1b1b1b,
    highlight:           #f6f7f6
);

$semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom);
$palette: map-extend($palette-default, $palette-custom);