$form-check-field-checkbox-checked-control-background: color(light);
$form-check-field-checkbox-checked-control-border: 1px solid color(organism-background);
$form-check-field-checkbox-checked-icon-color: color(dark);
$form-check-field-checkbox-checked-icon: check-alt;
$form-check-field-disabled-control-background: transparent;
$form-check-field-checkbox-checked-icon-width: rem-calc(23);
$form-check-field-checkbox-checked-control-font-weight: 600;
$form-check-field-label-font-size: rem-calc(12);
$form-check-field-radio-control-after-top: rem-calc(0);
$form-check-field-switch-after-position--right: rem-calc(25);
$form-check-field-switch-checked-after-position--right: rem-calc(5);
$form-check-field-switch-before-width: rem-calc(52);
$form-check-field-switch-before-height: rem-calc(30);
$form-check-field-switch-before-position--top: rem-calc(-4);
$form-check-field-switch-checked-before-background: color(primary);
$form-check-field-switch-after-width: rem-calc(22);
$form-check-field-switch-after-height: rem-calc(22);
$form-check-field-switch-after-position--top: rem-calc(0);
$form-check-field-switch-checked-after-background: color(secondary);
$form-check-field-image-checked-label-border: rem-calc(2) solid color(dark);
$form-check-field-radio-control-after-size: rem-calc(20);
$form-check-field-radio-checked-control-border-width: rem-calc(6);
$form-check-field-radio-checked-control-second-border-width: rem-calc(6);
$form-check-field-radio-control-after-border: rem-calc(1) solid color(border);
$form-check-field-label-height: rem-calc(22);

// Switch
$form-check-field-switch-padding: rem-calc(0 62 0 0);

// Switch before
$form-check-field-switch-before-background: color(border);
$form-check-field-switch-before-border-radius: rem-calc(15);

// Switch after
$form-check-field-switch-after-background: color(primary);

@import "@lora/05-components/form/check-field";