$header-hamburger-button-padding: rem-calc(14 16 10 8);
$header-hamburger-item-link-transform: none;
$header-hamburger-content-alignment--large: flex-start;
$header-hamburger-account-menu-icon-size: rem-calc(35);
$header-hamburger-storeslink-icon-size: rem-calc(35);
$header-hamburger-asset-link-may-we-help-you-icon-size: rem-calc(35);
$header-hamburger-asset-link-why-shop-online-icon-size: rem-calc(35);
$header-hamburger-button-icon-width: rem-calc(20);
$header-hamburger-button-icon-height: rem-calc(20);
$header-hamburger-localization-button-color: color(secondary);
$header-hamburger-localization-button-background: color(dark);
$header-hamburger-localization-button-icon-color: $header-hamburger-localization-button-color;
$header-hamburger-button-width: rem-calc(50);
$header-hamburger-item-padding: rem-calc(0 16);
$header-hamburger-localization-button-padding: rem-calc(15.5 40 15.5 16);
$header-hamburger-item-link-padding: rem-calc(11 0);
$header-hamburger-icon-margin: rem-calc(0 10 0 0);
$header-hamburger-icon-height: rem-calc(35);
$header-hamburger-icon-width: rem-calc(35);
$header-hamburger-storeslink-icon: find-store;
$header-hamburger-asset-link-why-shop-online-icon: gift-menu;

/// Quicklinks
$header-hamburger-item-quicklinks-border: 1px solid color(disabled);
$header-hamburger-item-quicklinks-border-width: rem-calc(1 0);

@import "@lora/05-components/header/header-hamburger";