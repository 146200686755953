@mixin lora-helper-image-overlay-float {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

@mixin lora-components-image-overlay {
    .c-image-overlay {
        position: relative;

        img {
            width: 100%;
        }
    }

    .c-image-overlay__inner {
        // stylelint-disable max-nesting-depth
        @each $size in $breakpoint-classes {
            $range: if($size == 'small' or $size == 'medium', only, null);

            @if ($range == 'only') {
                @include breakpoint($size $range) {
                    &.m-float-for-#{$size}-only {
                        @include lora-helper-image-overlay-float;
                    }
                }
            }
            @else {
                @include breakpoint($size) {
                    &.m-float-for-#{$size} {
                        @include lora-helper-image-overlay-float;
                    }
                }
            }
        }
        // stylelint-enable max-nesting-depth

        &.m-float-for-all {
            @include lora-helper-image-overlay-float;
        }
    }
}
