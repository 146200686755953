$footer-background: color(dark);
$footer-background--large: color(dark);
$footer-newsletter-signup-title-font: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$footer-newsletter-signup-title-text-transform: uppercase;
$footer-newsletter-color: color(light);
$footer-newsletter-captcha-text-color: color(light);
$footer-row-variants-flex-direction: column;
$footer-row-item-last-padding-left: 0;
$footer-row-item-margin: rem-calc(0 0 10);
$footer-top-border-bottom: 1px solid color(light);
$footer-bottom-border-top--large: 0;
$footer-middle-inner-padding--large: rem-calc(0);
$footer-middle-inner-border-bottom--large: rem-calc(1) solid color(light);
$footer-error-color: color(alert);
$footer-newsletter-signup-title-font--large: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$footer-newsletter-captcha-link-font: 500 #{rem-calc(12)} / 1.66 $font-secondary;
$footer-newsletter-captcha-link-hover-color: color(secondary);
$footer-newsletter-link-hover-color: color(secondary);
$footer-padding: rem-calc(0 0 24);

@import "@lora/04-layout/footer";
