/*------------------------------------*\
        #FOOTER NAVIGATION
\*------------------------------------*/

$footer-nav-columns: 4 of 4 !default;
$footer-nav-columns--large: 1 of 4 !default;
$footer-nav-list-level1-margin: rem-calc(20 0 30) !default;
$footer-nav-list-level1-margin--large: rem-calc(20 0) !default;
$footer-nav-list-level2-margin: rem-calc(18 0 0) !default;
$footer-nav-list-level2-margin--large: rem-calc(5 0 0) !default;
$footer-nav-link-level1-font: #{rem-calc(18)} / 1.25 $font-primary !default;
$footer-nav-link-level1-font--large: bold #{rem-calc(14)} / 1.25 $font-primary !default;
$footer-nav-link-wrapper-margin: 0 !default;
$footer-nav-link-level1-color: color(light) !default;
$footer-nav-link-level1-color--large: null !default;
$footer-nav-link-level1-text-decoration: none !default;
$footer-nav-link-level1-text-decoration--large: null !default;
$footer-nav-link-level1-text-transform: uppercase !default;
$footer-nav-link-level1-text-transform--large: none !default;
$footer-nav-link-level1-hover-color: $footer-nav-link-level1-color !default;
$footer-nav-link-level1-hover-text-decoration: underline !default;
$footer-nav-link-level1-letter-spacing: null !default;
$footer-nav-link-level1-letter-spacing--large: null !default;
$footer-nav-link-level2-font: #{rem-calc(14)} / 1.8 $font-primary !default;
$footer-nav-link-level2-font--large: #{rem-calc(12)} / 1.5 $font-primary !default;
$footer-nav-link-level2-color: color(light) !default;
$footer-nav-link-level2-color--large: null !default;
$footer-nav-link-level2-hover-color: $footer-nav-link-level2-color !default;
$footer-nav-link-level2-padding: rem-calc(5 0) !default;
$footer-nav-link-level2-padding--large: 0 !default;
$footer-nav-link-level2-text-decoration: none !default;
$footer-nav-link-level2-text-decoration--large: null !default;
$footer-nav-link-level2-text-transform: null !default;
$footer-nav-link-level2-text-transform--large: null !default;
$footer-nav-link-level2-letter-spacing: null !default;
$footer-nav-link-level2-hover-text-decoration: underline !default;

@mixin lora-components-footer-nav {
    .l-footer__nav {
        @include breakpoint(large) {
            @include cell(8 of 12);
        }
    }

    .l-footer__nav-inner {
        @include grid;
    }

    .l-footer__nav-list {
        list-style: none;

        &.m-level-1 {
            @include cell($footer-nav-columns);

            margin: $footer-nav-list-level1-margin;

            @include breakpoint(large) {
                @include cell($footer-nav-columns--large);

                margin: $footer-nav-list-level1-margin--large;
            }
        }

        &.m-level-2 {
            margin: $footer-nav-list-level2-margin;

            @include breakpoint(large) {
                margin: $footer-nav-list-level2-margin--large;
            }
        }
    }

    .l-footer__nav-link {
        vertical-align: top;

        &.m-level-1 {
            @include text-style(
                $font: (
                    small: $footer-nav-link-level1-font,
                    large: $footer-nav-link-level1-font--large
                ),
                $color: (
                    small: $footer-nav-link-level1-color,
                    large: $footer-nav-link-level1-color--large
                ),
                $text-decoration: (
                    small: $footer-nav-link-level1-text-decoration,
                    large: $footer-nav-link-level1-text-decoration--large
                ),
                $text-transform: (
                    small: $footer-nav-link-level1-text-transform,
                    large: $footer-nav-link-level1-text-transform--large
                ),
                $letter-spacing: (
                    small: $footer-nav-link-level1-letter-spacing,
                    large: $footer-nav-link-level1-letter-spacing--large
                )
            );

            &:is(a):hover {
                color: $footer-nav-link-level1-hover-color;
                text-decoration: $footer-nav-link-level1-hover-text-decoration;
            }
        }

        &.m-level-2 {
            display: inline-block;

            @include text-style(
                $font: (
                    small: $footer-nav-link-level2-font,
                    large: $footer-nav-link-level2-font--large
                ),
                $color: (
                    small: $footer-nav-link-level2-color,
                    large: $footer-nav-link-level2-color--large
                ),
                $padding: (
                    small: $footer-nav-link-level2-padding,
                    large: $footer-nav-link-level2-padding--large
                ),
                $text-decoration: (
                    small: $footer-nav-link-level2-text-decoration,
                    large: $footer-nav-link-level2-text-decoration--large
                ),
                $text-transform: (
                    small: $footer-nav-link-level2-text-transform,
                    large: $footer-nav-link-level2-text-transform--large
                ),
                $letter-spacing: (
                    small: $footer-nav-link-level2-letter-spacing
                )
            );

            &:hover {
                color: $footer-nav-link-level2-hover-color;
                text-decoration: $footer-nav-link-level2-hover-text-decoration;
            }
        }
    }

    .l-footer__nav-link-wrapper {
        font-size: inherit;
        margin: $footer-nav-link-wrapper-margin;
    }
}
