//stylelint-disable lora/variable-pattern
$z-indexes: () !default;

// more info: https://codepen.io/stowball/post/sassier-z-index-management-for-complex-layouts
@function z-index($key1, $key2: null) {
    $map: $z-indexes;
    $found-index: null;
    @if (map-has-key($map, $key1)) {
        $found-index: index(map-keys($map), $key1);
        @if ($key2) {
            $map: map-get($map, $key1);
            $found-index: index($map, $key2);
        }
    }
    @return $found-index;
}
@function z($key1, $key2: null) {
    @return z-index($key1, $key2);
}

@function map-deep-get($map, $keys...) {
    $value: $map;
    @each $key in $keys {
        $value: map-get($value, $key);
    }
    @return $value;
}

// Function to determine a list with comma separated values
@function is-list($parameter) {
    @if type-of ($parameter) == list {
        @if list-separator ($parameter) == comma {
            @return true;
        }
    }
    @return false;
}

// Function returns color from the Color Palette ($palette map)
@function get-color-from($map, $colorName) {
    @if map-has-key($map, $colorName) == false {
        @warn There is no such color '#{$colorName}';
        @return null;
    }
    @else {
        @return map-get($map, $colorName);
    }
}

// Function returns color value searching in both maps: $palette, $semantic_colors
@function color($colorName) {
    @if map-has-key($palette, $colorName) {
        @return get-color-from($palette, $colorName);
    }
    @else if map-has-key($semantic_colors, $colorName) {
        @return get-color-from($semantic_colors, $colorName);
    }
    @else {
        @warn There is no such color '#{$colorName}';
        @return null;
    }
}

// Function returns dark-mode-color value searching in map: $dark-mode-colors
@function dark-mode-color($colorName) {
    @if map-has-key($dark-mode-colors, $colorName) {
        @return get-color-from($dark-mode-colors, $colorName);
    }
    @else {
        @warn There is no such dark-mode-color '#{$colorName}';
        @return null;
    }
}

/// jQuery-style extend function
/// About `map-merge()`:
/// * only takes 2 arguments
/// * is not recursive
/// @param {Map} $map - first map
/// @param {ArgList} $maps - other maps
/// @param {Bool} $deep - recursive mode
/// @return {Map}

@function map-extend($map, $maps.../*, $deep */) {
    $last: nth($maps, -1);
    $deep: $last == true;
    $max: if($deep, length($maps) - 1, length($maps));

    // Loop through all maps in $maps...
    @for $i from 1 through $max {
        // Store current map
        $current: nth($maps, $i);

        // If not in deep mode, simply merge current map with map
        @if not $deep {
            $map: map-merge($map, $current);
        }
        @else {
            // If in deep mode, loop through all tuples in current map
            @each $key, $value in $current {
                // If value is a nested map and same key from map is a nested map as well
                @if type-of($value) == "map" and type-of(map-get($map, $key)) == "map" {
                    // Recursive extend
                    $value: map-extend(map-get($map, $key), $value, true);
                }
                // Merge current tuple with map
                $map: map-merge($map, ($key: $value));
            }
        }
    }
    @return $map;
}

/// function returns normalized viewport height across devices
/// Takes the --vh variable value managed via JS or 1vh as a fallback
/// Usage examples:
///     vh(100) -> 100vh
///     vh(100, 100vw) -> calc(100vh - 100vw)
/// @param {Number} $height - height value in vh units
/// @param {length} $offset - offset value including CSS length units (e.g. pixels, rem, viewport units)
/// @return {Value}

@function vh($height, $offset: null) {
    @if ($offset) {
        @return calc(var(--vh, 1vh) * #{$height} - #{$offset});
    }
    @else {
        @return calc(var(--vh, 1vh) * #{$height});
    }
}
