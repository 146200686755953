// Product name
$product-tile-name-font-weight: 700;
$product-tile-name-text-transform: uppercase;
$product-tile-name-max-lines: 2;
$product-tile-name-max-lines--large: 2;
$product-tile-name-line-height: 1.25;
$product-tile-name-line-height--large: $product-tile-name-line-height;
$product-tile-name-font-family: $font-secondary;
$product-tile-name-font-size--large: rem-calc(14);
$product-tile-default-text-align: center;

// Product description
$product-tile-description-color: color(text-secondary);
$product-tile-description-line-height: 1.125;
$product-tile-description-font-weight: 300;

// Product info
$product-tile-info-item-align--large: center;
$product-tile-info-multiple-items-variations-count-text-align--large: center;

// Display product tile info on multiple line
$product-tile-info-single-line: false;

// Product Grid
$product-tile-grid-name-text-align: left;
$product-tile-grid-description-text-align: left;
$product-tile-grid-info-item-align-self: left;
$product-tile-grid-list-align-items: flex-start;

// Product Price
$product-tile-price-padding: 0;
$product-tile-price-padding--large: rem-calc(10 0 0);
$product-tile-price-font: 700 #{rem-calc(14)} / 1.25 $font-secondary;
$product-tile-price-font--large: $product-tile-price-font;

// Product Variation
$product-tile-variation-group-height--large: rem-calc(55);

// Product add to bag
$product-tile-add-bag-button-min-width--large: rem-calc(180);

// Tile Secondary
$product-tile-secondary-border: 0;
$product-tile-secondary-border--large: $product-tile-secondary-border;

// Product actions
$product-tile-actions-margin--large: rem-calc(8 0 5);

// Product brand
$product-tile-brand-line-height: 1.3;
$product-tile-brand-line-height--large: $product-tile-brand-line-height;

// Product variations
$product-tile-variotions-single-height: rem-calc(50);
$product-tile-variations-margin: 0;
$product-tile-variations-padding: 0;
$product-tile-variation-group-margin: rem-calc(10 0 12);

$product-tile-swatch-selected-color: color(dark);
$product-tile-swatch-selected-font-size--large: rem-calc(12);

// Product overlay
$product-tile-overlay-is-enabled: true;

// Product rating
$product-tile-rating-align: center;
$product-tile-rating-align--large: center;
$product-tile-rating-margin: rem-calc(0 0 10);
$product-tile-add-bag-text-transform: uppercase;
$product-tile-badge-left: 0;
$product-tile-add-bag-text-font: #{rem-calc(14)} / #{rem-calc(20)} $font-secondary;
$product-tile-name-color-visited: color(text);

@import "@lora/05-components/product/product-tile";