@mixin lora-form-range-field {
    .c-range-field.m-hidden {
        appearance: none;

        &::-webkit-slider-thumb {
            appearance: none;
            background: transparent;
            opacity: 0;
            width: 30px;
            height: 100%;
        }

        &::-moz-range-thumb {
            appearance: none;
            background: transparent;
            width: 30px;
            height: 100%;
        }

        &::-webkit-slider-runnable-track {
            appearance: none;
            opacity: 0;
            height: 100%;
            background: transparent;
            border-color: transparent; //stylelint-disable-line
            color: transparent; //stylelint-disable-line
        }
    }
}