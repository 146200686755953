/*------------------------------------*\
        #ERROR PAGE
\*------------------------------------*/
$error-page-background: color(element-background) !default;
$error-page-background--large: null !default;
$error-page-offline-page-max-width: rem-calc(585) !default;
$error-page-border-bottom: null !default;
$error-page-callout-max-width: null !default;
$error-page-callout-margin: null !default;

@mixin lora-layout-error-page {
    .l-error-page {
        @include lora-helper-layout-main;
        @include clearfix;

        background: $error-page-background;
        border-bottom: $error-page-border-bottom;

        @include breakpoint(large) {
            background: $error-page-background--large;
        }
    }

    .l-offline-page {
        max-width: $error-page-offline-page-max-width;
        margin: 0 auto;
    }

    .l-error-page__callout {
        max-width: $error-page-callout-max-width;
        margin: $error-page-callout-margin;
    }
}
