/*------------------------------------*\
        #LISTS
\*------------------------------------*/

//stylelint-disable lora/variable-pattern
/// Line height for items in a list.
/// @type Number
$list-lineheight: $paragraph-lineheight !default;

/// Bottom margin for items in a list.
/// @type Number
$list-margin-bottom: $paragraph-margin-bottom !default;

/// Bullet type to use for unordered lists (e.g., `square`, `circle`, `disc`).
/// @type String
$list-style-type: disc !default;

/// Bullet type to use for nested unordered lists (e.g., `square`, `circle`, `disc`).
/// @type String
$list-nested-style-type: $list-style-type !default;

/// Positioning for bullets on unordered list items.
/// @type String
$list-style-position: outside !default;

/// Left (or right) margin for lists.
/// @type Number
$list-side-margin: 1.25rem !default;

/// Left (or right) margin for a list inside a list.
/// @type Number
$list-nested-side-margin: 1.25rem !default;

/// Top margin for a list inside a list.
/// @type Number
$list-nested-side-margin-top: 0 !default;

/// Margin for a list item inside a list item.
/// @type Number
$list-item-nested-margin: 0 !default;

/* stylelint-disable selector-max-type */
@mixin lora-base-lists {
    // Lists
    ul,
    ol,
    dl {
        line-height: $list-lineheight;
        margin-bottom: $list-margin-bottom;
        list-style-position: $list-style-position;
    }

    // List items
    li {
        /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
        font-size: inherit;

        // Nested unordered/ordered lists items
        & & {
            margin: $list-item-nested-margin;
        }
    }

    // Unordered lists
    ul {
        list-style-type: $list-style-type;
        margin-#{$global-left}: $list-side-margin;
    }

    // Ordered lists
    ol {
        margin-#{$global-left}: $list-side-margin;
    }

    // Nested unordered/ordered lists
    ul,
    ol {
        & & {
            list-style-type: $list-nested-style-type;
            margin-#{$global-left}: $list-nested-side-margin;
            margin-top: $list-nested-side-margin-top;
            margin-bottom: 0;
        }

        /* stylelint-disable-next-line selector-no-qualifying-type */
        &.m-list-inline {
            li {
                display: inline;
            }
        }

        /* stylelint-disable-next-line selector-no-qualifying-type */
        &.m-plain {
            margin: 0;
        }
    }
}
/* stylelint-enable */
