@import "../swatch";

$product-swatches-width: 100% !default;
// PDP Swatches carousel
$product-swatches-carousel-inner-with-badge-vertical-spacing: rem-calc(20) !default;
// PDP Swatches
$product-swatches-pdp-margin: rem-calc(10 0) !default;
$product-swatches-pdp-margin--large: rem-calc(0 0 20) !default;
$product-swatches-pdp-padding: 0 !default;
$product-swatches-pdp-padding--large: 0 !default;
$product-swatches-pdp-order: null !default;
$product-swatches-pdp-order--large: null !default;
// PDP Swatch
$product-swatches-pdp-swatch-margin: rem-calc(0 4 5) !default;
$product-swatches-pdp-swatch-margin--large: rem-calc(0 4 5) !default;
// PDPv2 Swatches
$product-swatches-pdpv2-margin: rem-calc(0 0 5) !default;
$product-swatches-pdpv2-margin--large: rem-calc(0 0 10) !default;
$product-swatches-pdpv2-order: null !default;
$product-swatches-pdpv2-order--large: null !default;
$product-swatches-pdpv2-actions-margin: rem-calc(10 0 0) !default;
$product-swatches-pdpv2-actions-margin--large: null !default;
$product-swatches-pdpv2-swatch-margin: null !default;
$product-swatches-pdpv2-swatch-margin--large: null !default;
// Disabled Swatches
$product-swatches-disabled-opacity: 0.7 !default;
// Swatches Actions
$product-swatches-actions-content-margin: rem-calc(15 0) !default;
$product-swatches-actions-content-margin--large: 0 !default;
// Swatches Toggle
$product-swatches-toggle-border-bottom: 1px solid color(dark) !default;
$product-swatches-toggle-font: 500 #{rem-calc(12)} / 1.25 $font-primary !default;
$product-swatches-toggle-font--large: #{rem-calc(14)} / 1.25 $font-primary !default;
$product-swatches-toggle-text-transform: uppercase !default;
$product-swatches-toggle-text-align: $global-left !default;
$product-swatches-toggle-text-decoration: none !default;
$product-swatches-toggle-icon-width: rem-calc(8) !default;
$product-swatches-toggle-icon-font-size: rem-calc(8) !default;
$product-swatches-toggle-icon-margin: rem-calc(0 2 0 5) !default;
$product-swatches-toggle-icon-transition: transform 0.3s !default;
$product-swatches-toggle-icon-color: color(primary) !default;
$product-swatches-toggle-icon-margin-top: -(rem-calc(7)) !default;
$product-swatches-toggle-less-color: color(primary-active) !default;
$product-swatches-toggle-more-color: color(text) !default;
$product-swatches-toggle-content-order: 2 !default;
$product-swatches-toggle-content-order--large: 1 !default;
$product-swatches-toggle-color: null !default;
$product-swatches-toggle-letter-spacing: null !default;
// Swatch Selected
$product-swatches-selected-margin: rem-calc(10 0 0) !default;
$product-swatches-selected-margin--large: rem-calc(10 0 0) !default;
$product-swatches-selected-order: 1 !default;
$product-swatches-selected-order--large: 1 !default;
$product-swatches-selected-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-swatches-selected-font--large: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-swatches-selected-text-transform: null !default;
$product-swatches-selected-text-transform--large: null !default;
$product-swatches-selected-color: null !default;
$product-swatches-selected-color--large: null !default;
// View All Swatches
$product-swatches-view-all-order: 2 !default;
$product-swatches-view-all-order--large: 2 !default;
$product-swatches-view-all-link-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$product-swatches-view-all-link-font--large: null !default;
$product-swatches-view-all-link-color: null !default;
$product-swatches-view-all-link-color--large: null !default;
$product-swatches-view-all-link-padding: 0 !default;
$product-swatches-view-all-link-letter-spacing: null !default;

@mixin lora-components-product-swatches {
    .c-swatches {
        width: $product-swatches-width;

        &.m-pdp {
            margin: $product-swatches-pdp-margin;
            padding: $product-swatches-pdp-padding;
            order: $product-swatches-pdp-order;

            @include breakpoint(large) {
                margin: $product-swatches-pdp-margin--large;
                padding: $product-swatches-pdp-padding--large;
                order: $product-swatches-pdp-order--large;
            }

            .c-swatch {
                margin: $product-swatches-pdp-swatch-margin;

                @include breakpoint(large) {
                    margin: $product-swatches-pdp-swatch-margin--large;
                }
            }
        }

        &.m-pdpv2 {
            margin: $product-swatches-pdpv2-margin;
            order: $product-swatches-pdpv2-order;

            @include breakpoint(large) {
                margin: $product-swatches-pdpv2-margin--large;
                order: $product-swatches-pdpv2-order--large;
            }

            .c-swatches__actions {
                margin: $product-swatches-pdpv2-actions-margin;

                @include breakpoint(large) {
                    margin: $product-swatches-pdpv2-actions-margin--large;
                }
            }

            .c-swatch {
                margin: $product-swatches-pdpv2-swatch-margin;

                @include breakpoint(large) {
                    margin: $product-swatches-pdpv2-swatch-margin--large;
                }
            }
        }

        &.m-disabled {
            opacity: $product-swatches-disabled-opacity;
            pointer-events: none;
        }

        &.m-show-badge {
            .c-carousel__inner {
                padding: $product-swatches-carousel-inner-with-badge-vertical-spacing 0;
                margin: -$product-swatches-carousel-inner-with-badge-vertical-spacing 0;
            }
        }
    }

    .c-swatches__actions {
        display: flex;
        flex-flow: row wrap;
        margin: $product-swatches-actions-content-margin;

        @include breakpoint(large) {
            margin: $product-swatches-actions-content-margin--large;
        }
    }

    .c-swatches__toggle {
        position: relative;
        border-bottom: $product-swatches-toggle-border-bottom;
        font: $product-swatches-toggle-font;
        text-transform: $product-swatches-toggle-text-transform;
        text-align: $product-swatches-toggle-text-align;
        color: $product-swatches-toggle-color;
        letter-spacing: $product-swatches-toggle-letter-spacing;

        @include breakpoint(large) {
            cursor: pointer;
            font: $product-swatches-toggle-font--large;
        }

        &.m-active {
            .c-swatches__toggle-icon::after {
                content: '―';
                transform: rotateZ(0);
            }

            .c-swatches__toggle-more {
                display: none;
            }

            .c-swatches__toggle-less {
                display: inline-block;
            }
        }
    }

    .c-swatches__toggle-icon {
        position: absolute;
        margin: $product-swatches-toggle-icon-margin;
        margin-top: $product-swatches-toggle-icon-margin-top;
        #{$global-right}: 0;
        top: 50%;

        &::after {
            display: block;
            font-size: $product-swatches-toggle-icon-font-size;
            width: $product-swatches-toggle-icon-width;
            content: '✕';
            text-align: center;
            transform: rotateZ(45deg);
            transition: $product-swatches-toggle-icon-transition;
            color: $product-swatches-toggle-icon-color;
        }
    }

    .c-swatches__toggle-less {
        display: none;
        color: $product-swatches-toggle-less-color;
        text-decoration: $product-swatches-toggle-text-decoration;
    }

    .c-swatches__toggle-more {
        text-decoration: $product-swatches-toggle-text-decoration;
        color: $product-swatches-toggle-more-color;
    }

    .c-swatches__toggle-content {
        display: none;
        order: $product-swatches-toggle-content-order;

        &.m-active {
            display: block;
        }

        @include breakpoint(large) {
            order: $product-swatches-toggle-content-order--large;
        }
    }

    .c-swatches__swatch-selected {
        order: $product-swatches-selected-order;

        @include breakpoint(large) {
            order: $product-swatches-selected-order--large;
        }
        @include text-style(
            $font: (
                small: $product-swatches-selected-font,
                large: $product-swatches-selected-font--large
            ),
            $margin: (
                small: $product-swatches-selected-margin,
                large: $product-swatches-selected-margin--large
            ),
            $text-transform: (
                small: $product-swatches-selected-text-transform,
                large: $product-swatches-selected-text-transform--large
            ),
            $color: (
                small: $product-swatches-selected-color,
                large: $product-swatches-selected-color--large
            )
        );
    }

    .c-swatches__view-all {
        order: $product-swatches-view-all-order;

        @include breakpoint(large) {
            order: $product-swatches-view-all-order--large;
        }
    }

    .c-swatches__view-all-link {
        @include lora-helper-button-link($padding: $product-swatches-view-all-link-padding);
        @include text-style(
            $font: (
                small: $product-swatches-view-all-link-font,
                large: $product-swatches-view-all-link-font--large
            ),
            $letter-spacing: (
                small: $product-swatches-view-all-link-letter-spacing
            ),
            $color: (
                small: $product-swatches-view-all-link-color,
                large: $product-swatches-view-all-link-color--large
            )
        );

        cursor: pointer;
    }
}
