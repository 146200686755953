$footer-social-title-font--large: 500 #{rem-calc(14)} / 1.5 $font-secondary;
$footer-social-title-text-transform--large: uppercase;
$footer-social-list-padding: rem-calc(0 10);
$footer-social-list-padding--large: 0;
$footer-social-link-padding: rem-calc(10 0);

$footer-social-list: (
    messenger: (
        enabled: true,
        icon: messenger,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    facebook: (
        enabled: true,
        icon: facebook,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    twitter: (
        enabled: true,
        icon: twitter,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    google: (
        enabled: true,
        icon: google,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    pinterest: (
        enabled: true,
        icon: pinterest,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    instagram: (
        enabled: true,
        icon: instagram,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    youtube: (
        enabled: true,
        icon: youtube,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
);

@import "@lora/05-components/footer/footer-social";